import { Parent } from "src/app/modules/financial-operations/modules/parents/models/father/parent";
import { FiscalYear } from "src/app/modules/financial-setting/models/fiscalYears/fiscalYear";

export interface TableColumns {
  name?: string;
  dataKey?: string;
  isSortable?: boolean;
}

export interface Nationality {
  id?: number;
  iso_code?: string;
  name?: string;
}

export interface GuideTree {
  viewLabel?: string;
  title_ar?: string;
  title_en?: string;
  id?: number;
  title?: string;
  code?: number;
  is_parents_receivables?: boolean;
  is_custodies?: number;
  account_guide_id?: number;
  all_children?: GuideTree[];
}

export interface ApiResponse {
  data?: any;
  length?: any;
  total?: any;
  message?: any;
  code?: any;
}

export interface ArrowButtons {
  first?: boolean,
  last?: boolean,
  next?: boolean,
  previous?: boolean,
}

export interface BaseApiResponse {
  message?: any;
  code?: any;
}
export interface GetSettingShowNumber extends BaseApiResponse {
  item?: {
    daily_number: number,
    date: string,
    fiscal_year_number: number,
    monthly_year_number: number,
    setting_show: number,
  },
}

export enum TableActions {
  edit = 'edit',
  changeStatus = 'CHANGE_STATUS',
  delete = 'delete',
  assign = 'assign',
  isDefault = 'isDefault',
  show = 'visibility',
  download = 'download',
  more = 'more',
  printContract = 'print',
  contractInvoices = 'wallpaper',
  uploadModel = 'cloud_upload',
  paymentMethodPermission = 'perm_contact_calendar',
  accountGuidePermission = 'accessibility',
  approve = 'approve',
  refuse = 'refuse',
  refuseReason = 'refuseReason',
  floors = 'floors',
  connectToDevice = 'connect-finder-device'
}

// 
export enum JournalModulesIds {
  parentCollection = 15,
  viewBill = 9,
  openingBalance = 1,
  receiptVouchers = 4,
  paymentVouchers = 3,
  custodyPay = 19,
  custodyClose = 20,
}

export interface JournalEntries {
  tax_id?: number;
  account_guide_id?: number | string;
  percentage?: number;
  statement?: string;
  type?: string;
  journal_parameter_id?: number;
  credit_parameter?: number;
  debit_parameter?: number;
  item_index?: number;
  journal_module_id?: number;
}

export interface Column {
  cell?: any;
  editor?: string;
  columnDef?: string;
  header?: string;
  width?: number;
  show?: boolean;
  required?: boolean;
  actions?: string[];
  editorParams?: {
    values?: any[];
    bindValue?: string;
    bindLabel?: string;
  };
}

export interface NavChange {
  text: string;
  costCenter: number;
  fiscalYear: number;
  companyId: number;
  companyNum: number;
  role: number;
  is_closed: number;
  selectedCompany?: any;
}


export interface Currencies {
  name?: string;
  id?: number;
  name_symbol?: string;
  is_default?: number;
  rate?: number;
}

export const cellActionStyle = {
  color: '#1cced8',
  backgroundColor: '#d2f5f7',
  'font-size': '0.925rem',
  'line-height': '1.35',
  'border-radius': '0.42rem',
  'height': '30px',
  'width': '30px',
};
export const assignActionStyle = {
  color: '#1cced8',
  backgroundColor: '#d2f5f7',
  'font-size': '0.925rem',
  'line-height': '1.35',
  'border-radius': '0.42rem',
  'height': '30px',
  'width': '30px',
};

export const MoreActionStyle = {
  'min-width': ' 30px',
  'height': '30px',
  'display': 'flex',
  'align-items': ' center',
  'justify-content': ' center',
  backgroundColor: '#2288d3'
}

export const DeleteActionStyle = {
  ...cellActionStyle,
  backgroundColor: '#f2cad2',
  color: '#ea657f'
};


export interface GlobalAgTableColumns {
  headerName?: string,
  valueGetter?: string,
  cellRenderer?: string,
  cellClass?: string,
  cellRendererParams?: any,
  filter?: boolean,
  width?: number,
}


export interface Country {
  id?: number;
  iso_code?: string;
  name?: string;
}

export interface Role {
  id?: number;
  title?: string;
}

export interface Language {
  id?: number;
  title?: string;
}

export interface DateFormate {
  key?: string;
  value?: string;
}

export interface MotherCompanies {
  accrual_basis?: string;
  address_street?: string;
  city?: string;
  country_id?: number;
  created_at?: string;
  date_format?: string;
  fax?: any;
  has_image?: boolean;
  id?: number;
  image?: any;
  image_path?: string;
  journal_method?: string;
  language_id?: number;
  main_account_data?: {
    email: string;
    id: number;
    mobile: string;
    name: string;
  };
  mobile?: any;
  name?: string;
  number?: number;
  phone?: string;
  sp_number?: string;
  status?: number;
  system_account_id?: number;
  tax_number?: string;
  updated_at?: string;
  website?: string;
  zone?: string;
}

/** Global lists */

export interface Areas {
  id: number;
  name: string;
}

export interface Zones {
  key: string;
}

export interface CostCenter {
  cost_center_branch_id?: number;
  cost_center_region_id?: number;
  cost_center_branch?: any;
  cost_center_region?: any;
  id?: number;
  name?: string;
}

export interface Tax {
  id?: number;
  name?: string;
  percentage?: number;
}

export interface Branches {
  id: number;
  name: string;
}
export interface CostCenterList {
  id: number;
  name: string;
}

export interface Father {
  id: number;
  name: string;
}
export interface Father {
  id: number;
  name: string;
}

export interface Currency { id?: number, name?: string, short_name?: string }

export enum SecurityTypesStatus {
  twoStep = 1,
  sms = 2,
  email = 3,
}

export interface MenuItems {
  id?: number;
  name?: string;
  css_class?: string;
  color?: string;
  slug?: string;
  image?: string;
  children?: MenuItems[];
}

export interface TaxesList {
  id?: number,
  name?: string,
  percentage?: number,
}

export interface classRooms {
  id?: number;
  name?: string;
}
export interface FeesClasses {
  id?: number;
  name?: string;
}
export interface FeesTypes {
  id?: number;
  name?: string;
  actual_premium_sum?: any;
  fees_class_id?: number;
  premium_sum?: number
}


export interface ledgerData {
  account_guide?: { id?: number, name?: string },
  conversion_factor?: number,
  cost_center?: CostCenterList,
  cost_center_branch?: Branches,
  cost_center_region?: Areas,
  credit?: string,
  currency?: Currency,
  daily_number?: number,
  date?: string,
  date_show?: string,
  day?: number,
  debit?: string,
  father?: Parent,
  fiscal_year_number?: number,
  id?: number,
  journal_module?: { id?: number, name?: string },
  module?: {
    id?: any,
    date?: string,
    fiscal_year_number?: string,
    monthly_year_number?: string,
    daily_number?: string,
    setting_show?: string,
  },
  month?: number,
  monthly_year_number?: number,
  ref_id?: string,
  setting_show?: number,
  statement?: any,
  student?: { id?: number, name?: string, fullname?: string },
  tag?: any,
  year?: number,
  moduleTitle?: string,
}

// Table row base item interface
export interface FindItem {
  account_guide_id?: number,
  conversion_factor?: number,
  cost_center?: CostCenterList,
  cost_center_branch?: Branches,
  cost_center_branch_id?: number,
  cost_center_id?: number,
  cost_center_region?: Areas,
  cost_center_region_id?: number,
  created_at?: string,
  currency?: Currency,
  currency_id?: number,
  daily_number?: number,
  date?: string,
  date_show?: string,
  day?: number,
  fiscal_year_id?: number,
  fiscal_year_number?: number,
  fiscal_years?: FiscalYear,
  id?: number,
  month?: number,
  monthly_year_number?: number,
  mother_company?: any,
  mother_company_id?: number,
  ref_id?: string,
  setting_show?: number,
  statement?: string,
  updated_at?: string,
  user_id?: number,
  year?: number,
}

// Base pagination response 
export interface BasePaginateRes {
  current_page?: number,
  first_page_url?: string,
  from?: number,
  last_page?: number,
  last_page_url?: string,
  links?: any[],
  next_page_url?: string,
  path?: string,
  per_page?: number,
  prev_page_url?: null,
  to?: number,
  total?: number,
}

export interface UserPermissions extends BaseApiResponse {
  role?: {
    [x: string]: any;
    permissions?: string[],
    title?:string,
    title_en?:string,
    slug?:string,
  }
}
export interface LedgerSearchFilters {
  fld?: any,
  relation?: any,
  value?: any,
  op?: "eq" | "gt" | "gte" | "lt" | "lte" | "neq" | "btw" | "value" | "contain" |
  "notcontain" | "start_with" | "end_with" | "not_start_with" | "not_end_with" | "",
}