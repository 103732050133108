import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isSuper = localStorage.getItem('role') ? localStorage.getItem('role') === 'super' : false;
    const token = this.authService.getToken();
    if (token) {
      // logged in so return true
      if(isSuper){
        this.router.navigate(["/admin/home"]);
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/auth/login"]);

    // this.authService.logout();
    return false;
  }
}
