import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize, tap } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService as faceService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthHTTPService } from './auth-http/auth-http.service';
import { SecurityTypesStatus } from "src/app/shared/model/global";

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;
  accessToken: string;
  isSuper = localStorage.getItem('role') ? localStorage.getItem('role') === 'super' : false;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: faceService,
    private router: Router,
    private _authHTTPService: AuthHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // Login user
  login(data: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this._authHTTPService.login(data).pipe(
      map((auth: any) => {
        console.log('auth',auth);
        const result = this.setAuthFromLocalStorage(auth);
        return auth;
      }),
      tap((data) => {
        console.log('data',data);

        const date = new Date().getTime() + data.expires_in * 1000;
        this.saveDateToLocalStorage(date);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // save date of login to local storage
  saveDateToLocalStorage(date: any): void {
    localStorage.setItem('expire_date', date.toString());
  }

  // get expiration date
  getExpireDate(): number {
    return Number(localStorage.getItem('expire_date'));
  }

  // Check if token expires => if ture then token expired
  checkIfTokenExpires(): boolean {
    const date = new Date().getTime();
    return this.getExpireDate() < date;
  }

  // Call dashbord to check if user active TWA
  dashbord(header): Observable<any> {
    const apiUrl = `${environment.accountant_apiUrl}users/dashboard`;
    return this._authHTTPService.dashbord(apiUrl, header);
  }

  // Get Twa data
  twaData(): Observable<any> {
    return this._authHTTPService.getTwyScanData();
  }

  // Get Twa data
  refreshToke(): Observable<any> {
    return this._authHTTPService.refreshToke();
  }

  // Click skip TWA check
  skipSettingQRCode(): Observable<any> {
    return this._authHTTPService.skipSettingQRCode();
  }

  // When verify code set code befoer verify
  setCodeBeforeVerify(data): Observable<any> {
    return this._authHTTPService.setTwoWayData(data);
  }

  // After setcode to verify
  verifyCode(data): Observable<any> {
    return this._authHTTPService.verifyTwaCode(data);
  }

  // After setcode to verify
  changeStatus(status, type): Observable<any> {
    switch (type) {
      case SecurityTypesStatus.twoStep:
        return this._authHTTPService.changeTwoStepStatus(status);
        break;
      case SecurityTypesStatus.sms:
        return this._authHTTPService.changeSmsStatus(status);
        break;
      case SecurityTypesStatus.email:
        return this._authHTTPService.changeEmailStatus(status);
        break;

      default:
        break;
    }
  }

  /**
   * Get url from google or facebook
   * @param name (google or facebook)
   * @param type (user or admin)
   * @returns observable of user data
   */
  getSocialUrl(name: string, type: string) {
    return this._authHTTPService.getSocialUrl(name, type);
  }

  /**
   * After user click account and redirect
   * @param name (google or facebook)
   * @param type (user or admin)
   * @param code (code generated by facebook or google)
   * @returns
   */
  loginWithSocial(name: string, type: string, code: string) {
    return this._authHTTPService.loginSocial(name, type, code);
  }

  logout() {
    return this._authHTTPService.logout();
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  // registration(user: UserModel): Observable<any> {
  //   this.isLoadingSubject.next(true);
  //   return this.authHttpService.createUser(user).pipe(
  //     map(() => {
  //       this.isLoadingSubject.next(false);
  //     }),
  //     switchMap(() => this.login(user.email, user.password)),
  //     catchError((err) => {
  //       console.error("err", err);
  //       return of(undefined);
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // Get userData
  getUserDataFromLocalStorage() {
    try {
      const authData = JSON.parse(localStorage.getItem('user'));
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // GetUser Token
  getToken() {
    try {
      const token = localStorage.getItem('token');
      return this.checkIfTokenExpires() ? undefined : token;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
