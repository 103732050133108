// AR
export const locale = {
  lang: "ar",
  data: {
    loading: "تحميل",
    MENU: {
      language: "اللغة العربية",
      ar_language: "اللغة العربية",
      en_language: "اللغة الانجليزية",
      noActiveFiscalYears: "لا توجد سنوات مالية مفعّلة",
      noActiveCompanies: "لا توجد شركات مفعّلة",
      noActiveCostCenters: "لا توجد مراكز تكلفة مفعّلة",
      noActiveRoles: "لا توجد أدوار مفعّلة",
      bank_type: 'نوع البنك',
      TYPE_BANKS: 'بنك عادي',
      TYPE_ONLINE: 'بنك اون لاين',
      TYPE_PAYLINK: 'PayLink',
      TYPE_KNET: 'Knet',
      TYPE_SADAD: 'Sadad',
    },
    mail: {
      show_more: "عرض المزيد",
      no_alert_more: "لا يوجد مزيد من التنبيهات لعرضها",
      set: 'تعيين',
      mark_as_read: 'تعيين كمقروء',
      mark_as_unread: 'تعيين كغير مقروء',
      confirm_set_all_alert_as_read: 'هل أنت متأكد من عملية تعيين كل التنبيهات كمقروءة؟',
      confirm_set_messages_send_draft: 'هل أنت متأكد من عملية تعيين  كمسودة؟',
    },
    hr: {
      dashboard: {
        documents: {
          expired: "منتهي",
          valid: "سار",
        },
        staffs: {
          activeStaffs: "مفعلين",
          inactiveStaffs: "موقوفين من المسير",
          endStaffs: "منتهي خدماتهم",
          maleStaffs: 'ذكر',
          femaleStaffs: 'انثي',
        },
        attendances: {
          "absence": "نسبة الغياب",
          "lates": "نسبة التأخير",
          "early": "نسبة الانصراف"
        },
        "advances": {
          "advanceActive": "معتمد",
          "advanceWait": "معلقة",
          "advanceReject": "مرفوضة",
          "advanceDone": "مصروف"
        },
        "vacations": {
          "vactionActive": "معتمد",
          "vacationWait": "معلقة",
          "vacationReject": "مرفوضة"
        },
        "escorts": {
          "escortActive": "معتمد",
          "escortWait": "معلقة",
          "escortReject": "مرفوضة"
        },
        items: {
          staffs: "عدد الموظفين",
          documents: "عدد المستندات",
          attendances: "نسبة الغياب والتأخير والانصراف ",
        }
      },
      uploadExcelfile: "رفع ملف Excel",
      fileExcel: 'الحقل',
      initializing_months: 'تهيئة الشهور',
      desc: "الوصف",
      stop: "ايقاف",
      is_for_insurance: "يحتسب ضمن راتب التأمينات",
      active: "تفعيل",
      success_save: "تم الحفظ بنجاح",
      title: "الموارد البشرية",
      page_size: "حجم الصفحة",
      excelExport: "تصدير Excel",
      save_state: "حفظ الحالة",
      restore_state: "استعادة الحالة",
      reset_state: "اعادة الافتراضي",
      PDFExport: "تصدير PDF",
      profile: {
        profile: "الملف التعريفي",
      },
      without_excused: "بدون عذر",
      excused: "معذور",
      title_ar: "الاسم بالعربي",
      title_en: "الاسم بالانكليزي",
      month: "الشهر",
      from_date: "من التاريخ",
      to_date: "الى التاريخ",
      field: "to_date",
      details: "التفاصيل",
      country: "الدولة",
      region: "المنطقة",
      company_staff: "شؤون الموظفين",
      default: "افتراضي",
      calc_in_end_services: "تحسب ضمن مكافأة نهاية الخدمة",
      all: "الكل",
      calculation_date: "تاريخ الاحتساب",
      hiring_date: "احتساب بتاريخ التعيين",
      work_date: "احتساب تاريخ مباشرة العمل  ",
      numric: "يجب ان يكون رقم",
      companyStaffForm: {
        "name_ar": "الاسم بالعربي (رباعي)",
        "name_en": "الاسم بالانكليزي (رباعي)",
        custodyAccount: "حساب العهد",
        excelFile: 'يرجى اختيار ملف excel',
        editStaff: "تعديل موظف",
        add: "اضافة موظف",
        branch_id: "الفرع",
        cost_center_id: " (المدرسة)مركز التكلفة",
        cv_file: "السيرة الذاتية",
        yearly_max: "مبلغ الاعتماد السنوي للفرع",
        identity_number: "رقم الهوية",
        full_name: "اسم الموظف الرباعي",
        full_name_en: "اسم الموظف بالانكليزي",
        first_name: "الاسم الاول",
        grand_father_name: "اسم الجد",
        father_name: "اسم الاب",
        last_name: "اسم الاخير",
        first_name_en: "الاسم الاول",
        last_name_en: "الاسم الاخير",
        father_name_en: "اسم الاب",
        grand_father_name_en: "اسم الجد",
        birth_date: "تاريخ الميلاد",
        birth_date_hijry: "تاريخ الميلاد بالهجري",
        birth_city_id: "مكان الميلاد (المدينة)",
        gender: "الجنس",
        male: "ذكر",
        female: "انثى",
        nationality_id: "الجنسية",
        nationality: "الجنسية",
        religion_id: "الديانة",
        religion: "الديانة",
        social_status_id: "الحالة الاجتماعية",
        family_person_count: "عدد افراد الأسرة",
        profile_picture: "صورة الملف الشخصي",
        upload_picture: "تحميل الصورة",
        general_data: "بيانات عامة",
        contact_information: "بيانات التواصل",
        phone_number: "التليفون",
        original_country_phone_number: "رقم التليفون في البلد الأصل",
        social_status: "الحالة الاجتماعية",
        mobile_number: "رقم الجوال",
        original_country_mobile_number: "رقم الجوال في البلد الأصل",
        email: "ايميل",
        current_address: "العنوان الحالي",
        district: "الحي",
        build_no: "رقم البناء",
        address_original_country: "العنوان في بلدك الاصل",
        postal_number: "الرقم البريدي",
        employment_data: "بيانات التوظيف",
        enter_kingdom_date: "تاريخ دخول الممكلة",
        appoint_date: "تاريخ التعيين",
        date_hijry: "الهجري",
        start_work_date: "تاريخ مباشرة العمل ",
        day_off_date: "تاريخ احتساب الاجازة",
        work_desk_number: "رقم مكتب العمل",
        job_number: "الرقم الوظيفي",
        finger_print_number: "الرقم في جهاز البصمة",
        current_job_id: "الوظيفة الحالية",
        work_schedule_id: "جدول الدوام",
        // region_id:"المنطقة",
        // branch_id: "الفرع",
        // cost_center_id: "مركز التكلفة",
        company_section_id: "القسم / المرحلة",
        employee_section_id: "الفئة الوظيفية",
        test_period_days: "فترة التجربة بالايام",
        job_title_id: "المسمى الوظيفي",
        financial_data: "بيانات مالية",
        incurance_class_id: "الفئة التأمينية",
        contract_category_id: "فئة العقد",
        sponsor_id: "اسم السجل", //not existed
        receiving_salary_method: "طريقة القبض",
        bank_id: "اسم البنك",
        cash: "كاش",
        bank_account: "رقم الحساب",
        bank: "بنكي",
        bank_type: "حالة الحساب البنكي",
        bank_account_no: "رقم صاحب الحساب",
        bank_account_name: "اسم صاحب الحساب",
        contract_data: "بيانات العقد",
        contract_end_date: "تاريخ نهاية العقد",
        contract_end_date_hijry: "هجري",
        yearly_vacation_id: "لائحة الإجازات السنوية",
        contract_type_id: "نوع العقد",
        work_type_id: "نوع العمل",
        work_area: "نظاق العمل",
        complete: "عمل كامل",
        part_time: "عمل جزئي",
        flexible: "عمل مرن",
        volunteer: "عمل تطوعي",
        remote: "عمل عن بعد",
        group: "المجموعة",
        standard_working_hours: "معيار ساعات العمل",
        daily: "يومي",
        weekly: "اسبوعي",
        work_hours: "عدد ساعات العمل",
        work_weekly_days: "عدد ايام العمل الاسبوعية",
        work_holidays: "عدد ايام الراحة الاسبوعية",
        contract_terms: "بنود العقد",
        contract_file: "المرفقات",
        inside: "داخل المملكة",
        outside: "خارج المملكة",
        direct_manager_data: "بيانات المدير المباشر",
        staff_data: "بيانات الموظف ",
        direct_manager_job_title_id: "المسمى الوظيفي للمدير المباشر",
        direct_manager_id: "اسم المدير المباشر",
        username: "اسم المستخدم",
        password: "كلمة المرور",
        role_id: "المجموعة",
        // direct_manager_job_number:"الرقم الوظيفي للمدير المباشر",
        notes: "ملاحظات",
        academic_qualification_information: "بيانات المؤهل الدراسي",
        // status:"",
        // work_experiences:"",
        // job_title_id:"",
        // experience_years:"",
        courses: "الدورات التدريبية / شهادات اخرى /المشاركة",
        name: "الاسم",
        issuer: "مكان الاصدار",
        days: "المدة بالايام",
        start_date: "تاريخ بداية الدورة",
        end_date: "تارخ نهاية الدورة",
        good: "جيد",
        verygood: "جيد جدا",
        excellent: "ممتاز",
        acceptable: "مقبول",
        work_experiences: "الخبرات العملية",
        certificate_id: "شهادة التخرج",
        university_id: "الجامعة",
        rating: "التقدير / النسبة",
        date: "التاريخ",

        company: "الشركة",
        experience_years: "عدد سنوات الخبرة",
        noSponsors: "بدون كفيل",
      },
      inqueries: {
        title: "المساءلات",
        inqueriesReport: " تقرير المساءلات",
        print: "طباعة",
        inqueries_status: "حالة المساءلة",
        view: "عرض مساءلة",
        add: "اضافة مساءلة",
        edit: "تعديل مساءلة",
        inquery_type: "موضوع المسألة",
        create_type: "نوع المسألة",
        description: "التفاصيل",
        created_by: "مرسلة بواسطة",
        receiver: "المستقبل",
        // inquery_datetime: "تاريخ المساءلة",
        staff_id: " المستهدفين بالمساءلة",
        staff_required: "يجب اختيار موظف",
        staff_reply_timestamp: "تاريخ رد الموظف",
        manager_decision: "قرار المدير",
        attend_type: "حالة الحضور",
        admin: "مدير النظام",
        status: "الحالة",
        inquery_datetime: " تاريخ انشاء المساءلة",
        attendTypes: {
          attend: "حاضر",
          late: "متأخر",
          absence: "غياب",
          excused: "معذور",
          other: "آخرى",
          '3': "متأخر",
          '2': "غياب",
          '4': "الانصراف المبكر"
        },
        create_types: {
          '1': 'الي',
          '2': 'يدوي'
        },
        waiting_reply: "بإنتظار الرد",
        waiting_decision: "بإنتظار القرار",
        closed: "مغلق",
        managerReply: "قرار المدير",
        addmanagerReply: "قرار المساءلة",
        staffReply: "رد الموظف",
        staff_reply: "رد المستلم",
        addstaffReply: "اضافة رد المساءلة",
        sender_identity_number: "رقم الهوية المرسل",
        receiver_job: "جهة عمل المستلم",
        receiver_identity_number: "رقم الهوية المستلم",
        receiver_by: "مستلم المساءلة",
        current_job: "وظيفة المستلم",
        accept: "قبول العذر",
        accept_with_sanctions: "عدم قبول وتطبيق لائحة الجزاءات",
        sanctions_id: "لائحة الجزائات",
        date_from: "التاريخ من",
        date_to: "التاريخ الى",
      },
      jobs: {
        add: "اضافة اعلان",
        edit: "تعديل اعلان",
        addVacationsToStuffs: "اضافة اجازة",
        title: "اعلان توظيف",
        job_description: "نص الاعلان",
        title_ar: "اسم الإعلان",
        current_job_id: "الوظيفة المتقدم لها",
        start_time: "تاريخ البدء من",
        start_time_from: "تاريخ البدء ",
        start_time_to: "تاريخ البدأ الى",
        end_time_from: "تاريخ الانتهاء من",
        end_time_to: "تاريخ الانتهاء الى",
        end_time: "تاريخ الانتهاء",
        status: "الحالة",
        jobFormIndex: "بيانات المتقدمين",
        copy_link: "نسخ الرابط",
        required_fields: "الحقول الاجبارية",
        hidden_fields: "الحقول المخفية",
        statusop: {
          0: "غير مفعل",
          1: "مفعل",
        },
        branchs: "الفرع",
        name: "الاسم",
        nationality: "الجنسية",
        gender: "النوع",
        mobile_number: "الجوال",
        identity_number: "رقم الهوية",
        current_jobs: "الوظيفة",
        job_forms_count: "عدد المتقدمين",
        state: "الحالة",
        course_name: "اسم الكورس",
        company_sections: "المرحلة",
        targeted_candidates: " محل الاقامة",


      },
      attendancesReport: {
        title: "تقرير الحضور والغياب",
        attend_source: "مصدر الاضافة",
        manual: "يدوي",
        fingerprint: "البصمة",
        month: "الشهر",
        attendance_type: "نوع الغياب",
        attend_list_id: "اللائحة",
        attendance_date_from: "التاريخ من ",
        attendance_date_to: "التاريخ الى",
        deduction_value_from: "تاريخ الخصم من ",
        deduction_value_to: "تاريخ الخصم الى",
        is_excused: "العذر",
        excused: {
          0: "غير معذور",
          1: "معذور",
        }

      },
      vacationsReport: {
        title: "تقرير الاجازات",
        current_stage: " المرحلة الحالية",
        start_date_from: "تاريخ البداية من",
        start_date_to: "تاريخ البداية الى",
        end_date_to: "تاريخ النهاية من",
        end_date_from: "تاريخ النهاية الى",
        1: "مرحلة الاعتماد",
        2: "مرحلة الموارد البشرية",
      },
      salarydvancesReport: {
        title: "تقرير السلف",
        //  staff_id :"",
        status: "الحالة",
        pay_status: "حالة الصرف",
        year_id: "السنة",
        payment_method: "طريقة السداد",
        recovery_method: "طريقة الخصم",

        confirmed_amount_from: "القيمة المعتمدة من",
        confirmed_amount_to: "القيمة المعتمدة الى",
        requested_amount_from: "القيمة المطلوبة من",
        requested_amount_to: "القيمة المطلوبة الى",
        month: "الشهر",

        current_stage: " المرحلة الحالية",

        1: "مرحلة الاعتماد",
        2: "مرحلة الموارد البشرية",
      },
      training:{
        title:"التنمية المهنية",
        add:"اضافة برنامج",
        assessment_rate:"تقييم جودة الإنجاز",
        edit:"تعديل برنامج",
        confirmed_hours:"إجمالي الساعات المعتمدة",
        assessment:"التقييم",
        assessment_type:"اسلوب التقييم",
        assessment_types:{
          1:"ملاحظة مباشرة",
          2:"انجاز مرتبط بموضوع التعليم",
        },
        assessment_options:{
          '1':{
            '1':"مدى قدرة المتدرب على تطبيق المحتوى التدريبي في بيئة عملة",
            '2':"مدى استفادة المعنيين من تطبيق المتدرب للمحتوى التدريبي",
          },
          '2':{
            '1':"نوع الانجاز",
            '2':"مدى إرتباط الإنجاز بالمحتوى التدريبي"
          }
        },
        assessment_name:"اسم المقيم",
        assessment_date:"تاريخ التقييم",
        professional_development_assessment:"تقييم التطوير المهني",
        evaluation_work_environment:"التقييم ومتابعة التطبيق في بيئة العمل",
        updatesStatusConfirm:"هل انت موافق على تعديل  تعميد التكلفة",
        form:{
          title:"اسم البرنامج",
          name:"اسم الموظف",
          identity_number:"رقم الهوية",
          learning_mode:"اسلوب التعليم",
          training_hours:"عدد ساعات التدريب",
          confirmed_hours:"عدد ساعات المعتمدة",
          start_date:"تاريخ البدأ",
          end_date:"تاريخ الانتهاء",
          training_needs:"مصدر الاحتياج المهني",
          competencies:"الجدارات",
          training_place:"جهة التدريب",
          training_costs:"التكلفة",
          status:'تعميد التكلفة',
          assessment_rate:"متوسسط التقييم",
        },
        LEARNING_MODE:{
          media_watch:'مشاهدة الوسائط',
          attend_training:'تدريب حضوري',
          remote_training:'تدريب عن بعد',
          related_reading:'القراءة في مجال التخصص',
          inner_training:'تدريب داخلي',
        },
        STATUS:{
          new:"انتظار التعميد",
          accredited:"معتمد",
          refused:"غير معتمد",
          suspended:"معلق",
        },
      },
      visasReport: {
        title: "تقرير التأشيرات",
        //  staff_id :"",
        status: "الحالة",

        fees_from: "الرسوم من",
        fees_to: "الرسوم الى",
        from_date_from: "تاريخ السفر من",
        from_date_to: "تاريخ السفر الى",
        to_date_from: "تاريخ العودة من",
        to_date_to: "تاريخ العودة الى",

        current_stage: " المرحلة الحالية",

        1: "مرحلة الاعتماد",
        2: "مرحلة الموارد البشرية",
      },
      salaryHistoryReport: {
        title: "تقرير أرشيف الرواتب",
        month: "الشهر",
        salary: "الراتب الاساسي",
        benefits: "الاضافات",
        deductions: "الخصومات",
        total: "الاجمالي",
      },
      escortsReport: {
        title: "تقرير المرافقين",
        father: "اب",
        mother: "ام",
        son: "طفل",
        wife: "زوجة",
        sister: "اخت",
        brother: "اخ",
        other: "اخر",
        assignDirectManagerConfirm: "هل انت متأكد من احالة الطلب للمدير الأعلى",
        acceptConfirm: "هل انت متأكد من الموافقة على طلب الاعتماد",
        accept: "موافقة",
        rejectConfirm: "هل انت متأكد من رفض الاعتمار",
        reject: "رفض",
        nationality_id: "الجنسية",
        job_title_id: "الوظيفة",
        gender: "النوع",
        relative_relation: "صلة القرابة",
        id_end_date: "تاريخ انتهاء الهوية",
        birth_date: "تاريخ الميلاد",
        add_date: "تاريخ الاضافة",
        filename: "اسم الملف(تحميل صورة جواز السفر)",

        mobile_number: "رقم الجوال",
        identity_number: "رقم الهوية",
        birth_date_from: "تاريخ الميلاد من",
        birth_date_to: "تاريخ الميلاد الى",
        id_end_date_from: "تاريخ انتهاء الهوية من",
        id_end_date_to: "تاريخ انتهاء الهوية الى",
        add_date_from: "تاريخ الاضافة من",
        add_date_to: "تاريخ الاضافة الى",
        last_update_from: "تاريخ اخر تعديل من",
        last_update_to: "تاريخ اخر تعديل الى",
        name: "الاسم",
        last_update: "تاريخ اخر تعديل",
        status: "الحالة",
        current_stage: " المرحلة الحالية",
        1: "مرحلة الاعتماد",
        2: "مرحلة الموارد البشرية",
      },
      staffsReport: {
        title: 'تقرير الموظفين',
        employement_status: 'حالة الموظف',
        employementStatusType: {
          0: 'جديد',
          1: 'مفعل',
          2: 'غير مفعل',
          3: 'منهي الخدمة',
        },

        reportColumns: {
          identity_number: "رقم الهوية",
          first_name: "الاسم الاول",
          last_name: "الكنية",
          father_name: "اسم الاب",
          grand_father_name: "اسم الجد",
          first_name_en: "اسم الاول انكليزي",
          last_name_en: "اسم الاخير انكليزي",
          father_name_en: "اسم الاب انكليزي",
          grand_father_name_en: "اسم الجد بالانكليزي",
          birth_date: "تاريخ الولادة",
          gender: "الجنس",
          family_person_count: "عدد افراد الاسرة",
          phone_number: "رقم الجوال",
          original_country_phone_number: "رقم التليفون في البلد الأصل",
          social_status: "الحالة الاجتماعية",
          mobile_number: "رقم الجوال",
          original_country_mobile_number: "رقم الجوال في البلد الأصل",
          email: "ايميل",
          current_address: "العنوان الحالي",
          district: "الحي",
          build_no: "رقم البناء",
          address_original_country: "العنوان في بلدك الاصل",
          postal_number: "الرقم البريدي",
          employment_data: "بيانات التوظيف",
          enter_kingdom_date: "تاريخ دخول الممكلة",
          appoint_date: "تاريخ التعيين",
          date_hijry: "الهجري",
          start_work_date: "تاريخ مباشرة العمل ",
          day_off_date: "تاريخ احتساب الاجازة",
          work_desk_number: "رقم مكتب العمل",
          job_number: "الرقم الوظيفي",
          finger_print_number: "الرقم في جهاز البصمة",

          test_period_days: "فترة التجربة بالايام",
          receiving_salary_method: "طريقة القبض",
          bank_account_no: "رقم صاحب الحساب",

          bank_account_name: "اسم صاحب الحساب",
          contract_end_date: "تاريخ نهاية العقد",
          work_area: "نظاق العمل",
          group: "المجموعة",

          standard_working_hours: "معيار ساعات العمل",

          work_hours: "عدد ساعات العمل",
          work_weekly_days: "عدد ايام العمل الاسبوعية",
          work_holidays: "عدد ايام الراحة الاسبوعية",
          work_type: "نوع العمل",

          employement_status: 'حالة الموظف',
          contract_terms: "بنود العقد",
          notes: "ملاحظة",
          created_at: "تاريخ الانشاء",
          updated_at: "تاريخ التعديل",
          contract_start_date: "تاريخ بداية العقد",
          status: "الحالة",
          stoped_description: "سبب الايقاف",
          end_type: "نوع الايقاف",
          name: "الاسم الكامل",
          manager: "المدير",
          birth_city: "مدينة الولادة",
          username: "اسم المستخدم",
          cost_center: "( المدرسة ) مركز التكلفة",
          branch: "الفرع",
          employee_section: "الفئة الوظيفية",
          company_section: "القسم / المرحلة",
          insurance_class: " فئة التأمينات الاجتماعية ",
          current_job: "الوظيفة الحالية",
          last_active: "اخر نشاط",
          nationality: "الجنسية",
          religion: "الديانة",
          job_title: "المسمى الوظيفي",
          contract_category: "فئة العقد",
          sponsor: "سجل الشركة (الكفيل)",
          bank: "البنك",
          contract_type: "نوع العقد",
          insurance_company: "شركة التأمين",
          insurance_number: "رقم التأمين",
          insurance_start_date: "تاريخ بدء التأمين",
          insurance_end_date: "تاريخ نهاية التأمين",
          yearlyVacation: "الاجازات السنوية",
        },
      },
      finincialReport: {
        title: 'التقرير المالي',
        name: 'اسم الموظف',
        staff_name: 'اسم الموظف',
        branch: "الفرع",
        insurance_salary: "الراتب التأميني",
        salary: "الراتب الأساسي",
        total_salary: "اجمالي الراتب",
        username: "اسم المستخدم",
        basic_data: "البيانات الأساسية",
        company_section: "القسم",
        identity_number: "رقم الهوية",
        job_number: "الرقم الوظيفي",
        start_work_date: " تاريخ المباشرة",
        appoint_date: "تاريخ التعيين",
        current_job: "الوظيفة الحالية",
        employee_section: "الفئة الوظيفية",
        // xxxx: "على راس العمل",
        nationality: "الجنسية",
        bank_account_no: "رقم IBAN",
        bank_account_name: "المصرف",
        receiving_salary_method: "طريقة الدفع",
        bank_value: "عمولة البنك",
        gender: "الجنس",
        // contractTypes: "نوع العقد",
        insurance_number: "الرقم التأميني",
        cost_center: "( المدرسة ) مركز التكلفة",
        mobile_number: "الجوال",
        birth_date: "الميلاد",
        job_title: "المستوى الوظيفي",
        email: "البريد الالكتروني",
        // total_salary:"اجمالي",
        benefits_from: "اجمالى الاستحقاقات من",
        benefits_to: "اجمالي الاستحقاقات الى",
        deductions_from: "اجمالي الاستقطاعات من",
        deductions_to: "اجمالي الاستقطاعات الى",
        appoint_date_from: "تاريخ التعيين من",
        appoint_date_to: "تاريخ التعيين الى",



      },
      monthes: {
        month_order: "الترتيب",
        month: "الشهر",
        start_date: "تاريخ بداية الشهر",
        end_date: "تاريخ نهاية الشهر",
      },

      payrollBank: {
        table: 'ملف البنك',
        add: "انشاء ملف بنك",
        excelExport: "تصدير ملف Excel",
        updateExcelFile: "تحديث ملف Excel",
        bank_status: 'حالة البنك',
      },

      companyStaff: {
        job_number: "الرقم الوظيفي",
        employee_sections: "الفئة الوظيفية",
        name: "الاسم",
        create_source: " مصدر الاضافة",
        form: "نموذج",
        excel: "استيراد اكسل",
        excel_import: "استيراد موظفين من اكسل",
        add: "اضافة موظف",

        username: "اسم المستخدم",
        status: "حالة الموظف",
        group: "مجموعة",
        id_number: "الرقم الوظني",
        current_job: "الوظيفة الحالية",
        salary: "الراتب",
        company_section: "القسم",

        school: "مركز التكلفة / الفرع",
        section: "القسم",
        staffdocuments: "مستندات الموظفين",
        insurances_type: "فئة التأمينات الاجتماعية",
        last_action: "اخر نشاط",
        action: "التحكم",
        contracts: "العقود",
        custodies: "العهد العينية",
        financial_operations: "العمليات المالية",
        updateData: "تحديث بيانات",
        chooseServiceUpdate: "اختر الخدمة المطلوب تحديثها",
        updateDataStaff: "تحديث بيانات الموظفين",
        identity_number: "رقم الهوية",
        jop_number: "الرقم الوظيفي",
        upload_resident_files: "رفع ملفات المقيمين",
        upload_relatives_files: "رفع ملفات الأقارب",
        update_employee_insurances: "تحديث تأمينات الموظفين",
        update_data_covenant_kind: "تحديث بيانات العهد العيني",
        staff_salary_update: "تحديث رواتب الموظفين",
        update_employee_documents_information: "تحديث معلومات مستندات الموظفين",
        import_employees: "جارى العمل على استيراد الموظفين من النظام التعليمى",
        Importing_employees_from_the_educational_system: "استيراد الموظفين",
        update_employee_information: "تحديث بيانات الموظفين",
        contract_category: "فئة العقود",
        start_work_date: "تاريخ مباشرة العمل",
      },
      BankFileExcel: {
        'identity_number': 'رقم الهوية',
        'job_number': 'الرقم الوظيفي',
        'name': 'اسم الموظف',
        'bank_account_number': 'رقم الحساب',
        'bank_code': 'رمز البنك',
        'total_salary': 'صافي المرتب',
        'payroll_type': 'نوع المسير',
        'salary': 'الراتب الأساسي',
        'total_deductions': 'الخصومات على العامل',
        'total_benefits': 'مدفوعات أخرى للعامل',
        'insurance_salary': 'مرتب التأمينات',
        'insurance_adds': 'بدل السكن الشهري',
        'work_office_number': 'رقم مكتب العمل',
        'sponsor': 'الكفالة',
        'payments_desc': 'وصف المدفوعات',
        'bank_op_callback': 'مرجع العملية من البنك',
        'bank_op_status': 'حالة العملية من البنك',
        bank_status: "حالة الصرف",
      },
      insurances: {
        insurance_company_id: "شركة التأمين",
        insurance_class_id: "فئة التأمين",
        insurance_number: "رقم التأمين",
        start_date: "تاريخ البدء",
        end_date: "تاريخ الانتهاء",
        insurance_year: "العام",
        cache_amount: "المبلغ",
      },
      deductions: {
        month_from: "تاريخ بداية الشهر",
        month_to: "تاريخ نهاية الشهر",
        percent_type: "نوع النسة",
        insurance_classes: "فئة التأمين",
        basic_salary: "الراتب الاساسي",
        fiscal_year_id: "العام المالي",
        insurance_salary: "راتب التأمينات الاجتماعية",
        created_at: "تاريخ الاضافة",
        value_type: "نوع القيمة",
        value: " القيمة",
        main_salary: "الراتب الأساسي",
        deduction_id: "الخصومات",
        months: " شهور الحسم",
        notes: "الملاحظة",
        filename: "المرفقات",
        status: "حالة الخصم",
        is_Default: "افتراضي",
        not_Default: "غير افتراضي",
        deduction_months: " شهور الحسم",
        beneficiaries: "المستهدفين بالخصم",
        branchs: " الفروع",
        cost_centers: "مراكز التكلفة",
        employee_sections: "الفئة الوظيفية",
        job_titles: " الوظيفة",
        deduction_benefits: "الإضافات",
        benefits_discount: "خصم الإضافات",
        nationalities: "الجنسية",
        default: "افتراضي",
        month: "الشهر",
        year: "السنة",
        specification_date: "القيمة",
      },

      benefits: {
        insurance_classes: "فئة التأمين",
        created_at: "تاريخ الاضافة",
        value_type: "نوع القيمة",
        value: " القيمة",
        benefit_months: " شهور",
        beneficiaries: "المستهدفين بالاضافة",
        branchs: " الفروع",
        cost_centers: "مراكز التكلفة",
        employee_sections: "الفئة الوظيفية",
        job_titles: " الوظيفة",
        nationalities: "الجنسية",
        benefit_id: "الاضافات",
        status: "حالة الاضافة",
        months: " شهور ",
        notes: "الملاحظة",
        year: "السنة",
        filename: "المرفقات",
        default: "حالة",
        is_Default: "افتراضي",
        not_Default: "غير افتراضي",
      },
      payrollReport: {
        report: 'تقرير مسير الرواتب',
        specialization_id: "التخصص",
        month_restrictions: "قيود الشهر",
        payroll_number: "مسير رقم",
        sponsor_id: "السجل (الكفالة)",
        receiving_salary_method: "طريقة الدفع",
        staff_name: "الاسم",
        branch: "الفرع",
        cost_center: "( المدرسة ) مركز التكلفة",
        job_title: "الوظيفة",
        payroll_year: "السنة",
        payroll_month: "الشهر",
        payroll_type: "نوع المسير",
        payroll_unique_id: "رقم المسير",
        benefits: "الاضافات",
        deduction: "الخصومات",
        sanctions: "الجزائات",
        bsences: "الغيابات",
        delay: "التأخير",
        government_expenses: "مصروفات حكومية",
        total_benefits: "المجموع اضافة",
        total_deductions: "المجموع خصم",
        salary: "الراتب الاساسي",
        total_government_expenses: "مجموع مصروفات حكومية",
        total_salary: "صافي الراتب",
        discount_percent: "نسبة الحسم",
        insurance_class: " فئة التأمينات الاجتماعية ",

      },
      bank: {
        code: "رمز البنك",
        bank_type: "حالة العمولة",
        value_type: "نوع العمولة",
        value: "العمولة",
        percentage: "نسبة مئوية",
        fixed: "مقطوع",
        no_deducted_commission: "يحسم عمولة بنكية",
        deducted_commission: "لا يحسم عمولة بنكية",
      },
      sponsors: {
        name: "اسم السجل",
        details: "مختصر",
        workـoffice_no: "رقم المكتب",
        client_no: "رقم العميل",
        account_no: "رقم الحساب",
        banck: "البنك",
      },
      sanctions: {
        title_ar: "الجزاء",
        title_en: "الجزاء بالانكليزي",
        sanction_type: "نوع الجزاء",
        default: "الخصم من الاضافات الافتراضية ومكافئة نهاية الخدمة",
        branchs: " الفروع",
        sanction_id: "الجزاء",
        value: "القيمة",
        Repetition: "التكرار",
        cost_centers: "مراكز التكلفة",
        employee_sections: "الفئة الوظيفية",
        job_titles: " الوظيفة",
        nationalities: "الجنسية",
        monthly: "شهري",
        yearly: "سنوي",
        details: "تفاصيل الجزاء",
        beneficiaries: "المستهدفين بالجزاء",
        months: " شهور ",
        notes: "الملاحظة",
        year: "السنة",
        filename: "المرفقات",
        is_Default: "افتراضي",
        not_Default: "غير افتراضي",
      },
      sanctionsDetails: {
        value_type: "نوع القيمة",
        value: "القيمة",
        sanction_period: "عدد مرات التكرار",
        from: "من",
        to: "الى",
        minute: "دقيقة",
        day: "يوم",
        fixed: "مقطوع",
        percentage: "نسبة من اليوم",
      },

      advancePayments: {
        discount_percent: "نسبة الخصم",
        title_ar: "اسم السلفة ",
        title_en: "اسم السلفة بالانكليزي",
        created_at: "تاريخ الاضافة",
        maximum_type: "نوع السلفة",
        value_type: "مبلغ الاعتماد السنوي للفرع",
        value: "القيمة",
        repeated: "مكرر",
        once: "مرة واحدة",
        transfer_to_upper_manager:
          "اعتماد تحويل تلقائي لطلب السلفة للمدير الأعلى اذا تجاوزت الحد الأعلى المسموح للمدير المباشر",
        maximum: "الحد الاعلى للسلفة",
        deduction_months: " شهور الحسم",
        beneficiaries: "المستهدفين بالسلف",
        calculation_type: "احتساب السلفة من",
        branchs: " الفروع",
        cost_centers: "مراكز التكلفة",
        employee_sections: "الفئة الوظيفية",
        job_titles: " الوظيفة",
        deduction_benefits: "الاضافة (بنود الراتب)",
        benefits_discount: "خصم الاضافات",
        nationalities: "الجنسية",
        default: "افتراضي",
        month: "الشهر",
        year: "السنة",
        salary: "نسبة من إجمالي رواتب منسوبي الفرع",
        indemnity: "نسبة من اجمالي مجموع مكافأة نهاية الخدمة لكل الموظفين",
        repeatable: "السماح بتكرار السلفة العام الحالي",
        repeat_tims: "عدد التكرارات",
        auto_approval: "تعتمد بشكل تلقائي (لا تخضع لمراحل اعتماد)",
        approval: "الاعتماد",
        repeat: "التكرار",
        applicable_years: "تطبق السلفة مرة واحدة خلال عام",
        applicable_tims: "عدد التكرارات",
        acc_stage_id: "مرحلة الاعتماد",
        exec_stage_id: "مرحلة التنفيذ",
        pay_stage_id: "مرحلة الصرف",
      },
      AgStaffStatusBtn: {
        "0": "غير مفعل",

        1: "مفعل",
        2: "موقوف",
        3: "انهاء الخدمة",
      },
      StatusBtn: {
        // "0": "موقوف",
        "0": "مفعل",
        2: "موقوف",
        3: "المنتهي خدماته",
        1: "مفعل",
        settings: "ضبط السلف",
        reject: "رفض السلفة",
        active: "تفعيل",
        activeConfirm: "هل انت متاكد من تفعيل الموظف",
        disactive: "ايقاف",
        disactiveConfirm: "هل انت متاكد من الغاء تفعيل الموظف",
        end_contract: "الغاء العقد",
        new_contract: "عقد جديد",
        termination_services: "انهاء الخدمة",
        termination_servicesConfirm: "هل انت متاكد من انهاء الخدمة ",
        end_contractConfirm: "هل انت متاكد من الغاء العقد",
      },
      functional_data: {
        nationality: "الجنسية",
        permissions_management: "إدارة الصلاحيات",
        addnationality: "اضافة الجنسية ",
        editnationality: "تعديل الجنسية ",
        addreligion: "اضافة ديانة ",
        editreligion: "تعديل ديانة ",
        nationalityTable: "الجنسيات",
        religionTable: "الديانات",

        groupsTable: "جدول الدوام",
        addgroups: "اضافة جدول الدوام ",
        editgroups: "تعديل جدول الدوام ",

        socialStatusTable: "الحالة الاجتماعة",
        addsocialStatus: "اضافة الحالة الاجتماعة ",
        editsocialStatus: "تعديل الحالة الاجتماعة ",

        employeeStatusTable: "حالة التوظيف",
        addemployeeStatus: "اضافة حالة التوظيف ",
        editemployeeStatus: "تعديل حالة التوظيف ",

        companySectionsTable: "المرحلة | القسم",
        addcompanySections: "اضافة المرحلة | القسم ",
        editcompanySections: "تعديل المرحلة | القسم ",

        employeeSectionsTable: "الفئة الوظيفية",
        addemployeeSections: "اضافة فئة الوظيفية ",
        editemployeeSections: "تعديل فئة الوظيفية ",

        documentTable: "المستندات",
        adddocument: "اضافة المستندات ",
        editdocument: "تعديل المستندات ",

        currentJobsTable: "الوظيفة الحالية",
        addcurrentJobs: "اضافة الوظيفة الحالية ",
        editcurrentJobs: "تعديل الوظيفة الحالية ",

        insuranceClassesTable: "فئة التأمينات الاجتماعية ",
        addinsuranceClasses: "اضافة فئة تأمين اجتماعية ",
        editinsuranceClasses: "تعديل فئة تأمين اجتماعية ",

        certificatesTable: "الشهادات",
        addcertificates: "اضافة الشهادة ",
        editcertificates: "تعديل الشهادة ",

        specializationsTable: "التخصص",
        addspecializations: "اضافة التخصص ",
        editspecializations: "تعديل التخصص ",

        jobTitlesTable: "المسمى الوظيفي",
        addjobTitles: "اضافة المسمى الوظيفي ",
        editjobTitles: "تعديل المسمى الوظيفي ",

        countriesTable: "الدول",
        addcountries: "اضافة دولة",
        editcountries: "تعديل دولة",

        regionsTable: "المناطق",
        addregions: "اضافة منطقة",
        editregions: "تعديل منطقة",

        universitiesTable: "الجامعات",
        adduniversities: "اضافة جامعة",
        edituniversities: "تعديل جامعة",

        insuranceCompaniesTable: "شركات التأمين",
        addinsuranceCompanies: "اضافة شركة تأمين",
        editinsuranceCompanies: "تعديل شركة تأمين",

        officialVacationsTable: " الأجازات الرسمية",
        addofficialVacations: "اضافة  اجازة رسمية",
        editofficialVacations: "تعديل  اجازة رسمية",

        contractTermsTable: "بنود اضافية في العقد",
        addcontractTerms: "اضافة  بنود اضافية في العقد",
        editcontractTerms: "تعديل  بنود اضافية في العقد",

        contractTypesTable: "نوع العقد",
        addcontractTypes: "اضافة  نوع العقد",
        editcontractTypes: "تعديل  نوع العقد",

        contractCategoriesTable: "فئة العقود",
        addcontractCategories: "اضافة  فئة العقد",
        editcontractCategories: "تعديل  فئة العقد",

        citiesTable: "المدن",
        addcities: "اضافة  مدينة",
        editcities: "تعديل  مدينة",

        banksTable: "البنوك",
        addbanks: "اضافة  بنك",
        editbanks: "تعديل  بنك",

        sponsorsTable: "سجلات الشركة",
        addsponsors: "اضافة  سجل",
        editsponsors: "تعديل  سجل",

        deductionsTable: "الخصومات",
        adddeductions: "اضافة  خصم",
        editdeductions: "تعديل  خصم",

        benefitsTable: "الاضافات",
        addbenefits: "اضافة",
        editbenefits: "تعديل اضافة",

        companyStaffTable: "قائمة الموظفين",
        addcompanyStaff: "اضافة  موظف",
        editcompanyStaff: "تعديل  موظف",

        suspensionReasonsTable: "اسباب الايقاف",
        addsuspensionReasons: "اضافة سبب الايقاف",
        editsuspensionReasons: "تعديل سبب الايقاف",

        sanctionsReasonsTable: "لائحة الجزاءات",
        addsanctionsReasons: "اضافة جزاء",
        editsanctionsReasons: "تعديل جزاء",

        absenceListTable: "لائحة الغياب",
        addabsenceList: "اضافة لائحة غياب",
        editabsenceList: "تعديل لائحة غياب",

        sanctionsDetailsTable: "تفاصيل الجزاءات",
        addsanctionsDetails: "اضافة تفاصيل الجزاءات",
        editsanctionsDetails: "تعديل تفاصيل الجزاءات",

        advancePaymentsTable: "لوائح السلف",
        addadvancePayments: "اضافة لوائح السلف",
        editadvancePayments: "تعديل لوائح السلف",

        companyVacationsTable: "إجازات براتب",
        addcompanyVacations: "اضافة إجازة براتب",
        editcompanyVacations: "تعديل إجازة براتب",

        companyUnpaidVacationsTable: "إجازات بدون براتب",
        addcompanyUnpaidVacations: "اضافة إجازة بدون راتب",
        editcompanyUnpaidVacations: "تعديل إجازة بدون راتب",

        companyYearlyVacationsTable: "إجازات سنوية",
        addcompanyYearlyVacations: "اضافة إجازة سنوية",
        editcompanyYearlyVacations: "تعديل إجازة سنوية",

        advancePaymentsAccreditationTable: "لوائح اعتماد   السلف",
        addadvancePaymentsAccreditation: "اضافة لائحة اعتماد   السلف",
        editadvancePaymentsAccreditation: "تعديل لائحة اعتماد   السلف",

        lateListTable: "لائحة التأخير",
        addlateList: "اضافة لائحة التأخير",
        editlateList: "تعديل لائحة التأخير",

        earlyLeaveListTable: "لائحة الانصراف المبكر",
        addearlyLeaveList: "اضافة لائحة الانصراف المبكر",
        editearlyLeaveList: "تعديل لائحة الانصراف المبكر",

        additionalSanctionsTable: "الجزاءات الاضافية",
        addadditionalSanctions: "اضافة جزاء اضافي",
        editadditionalSanctions: "تعديل جزاء اضافي",

        fingerprintTable: "لائحة البصمة",
        addfingerprint: "اضافة لائحة البصمة",
        editfingerprint: "تعديل لائحة البصمة",

        accreditationStagesTable: "مراحل الاعتماد",
        addaccreditationStages: "اضافة مرحلة اعتماد",
        editaccreditationStages: "تعديل مرحلة اعتماد",

        extraTimeListTable: "لائحة الوقت الاضافي",
        addextraTimeList: "اضافة لائحة الوقت الاضافي",
        editextraTimeList: "تعديل لائحة الوقت الاضافي",

        inkindInventoryTable: "العهد العينيىة",
        addinkindInventory: "اضافة عهدة العينيىة",
        editinkindInventory: "تعديل عهدة العينيىة",

        contractsTable: "العقود",
        addcontracts: "تعيين العقد",
        editcontracts: "تجديد العقد",

        contracts_staffTable: "عقود الموظفين",
        addcontracts_staff: "تعيين عقد موظف",
        editcontracts_staff: "تجديد عقد موظف",

        documentsTable: "مستندات الموظفين",
        adddocuments: "اضافة مستند",
        editdocuments: "تعديل مستند",

        employmentsOpIndexTable: "عمليات التوظيف",
        addemploymentsOpIndex: "تعيين عملية توظيف",
        editemploymentsOpIndex: "تعديل عملية توظيف",

        sanctionsTable: "الجزاءات",
        addsanctions: "اضافة جزاء",
        editsanctions: "تعديل جزاء",

        vacationsTable: "الاجازات",
        addvacations: "اضافة اجازة",
        editvacations: "تعديل اجازة",

        salaryAdvancesTable: "لائحة السلف",
        salaryAdvances: "السلف",
        addsalaryAdvances: "اضافة سلفة",
        editsalaryAdvances: "تعديل سلفة",

        insurancesTable: "تأمينات الموظف",
        addinsurances: "اضافة تأمين موظف",
        editinsurances: "تعديل تأمين موظف",

        attendancesTable: "الحضور والانصراف",
        addattendances: "اضافة",
        editattendances: "تعديل ",

        salaryTable: "تجهيز المسير",
        addsalary: "تجهيز الرواتب",
        editsalary: "تعديل ",

        escortsTable: "المرافقين",
        addescorts: "اضافة مرافق",
        editescorts: "تعديل مرافق ",

        visasTable: "التأشيرات",
        addvisas: "اضافة تأشيرة",
        editvisas: "تعديل تأشيرة ",

        requestStagesVacationsTable: "مراحل اعتماد الاجازات",
        addrequestStagesVacations: "تحديث مراحل اعتماد الاجازات",
        editrequestStagesVacations: "تحديث مراحل اعتماد الاجازات ",

        requestStagesVisasTable: "مراحل اعتماد التأشيرات",
        addrequestStagesVisas: "تحديث مراحل اعتماد التأشيرات",
        editrequestStagesVisas: "تحديث مراحل اعتماد التأشيرات ",

        requestStagesEscortsTable: "مراحل اعتماد المرافقين",
        addrequestStagesEscorts: "تحديث مراحل اعتماد المرافقين",
        editrequestStagesEscorts: "تحديث مراحل اعتماد المرافقين ",

        qualificationsTable: "المؤهلات الدراسية",
        addqualifications: "اضافة مؤهل دراسي",
        editqualifications: "تعديل مؤهل دراسي ",
        profil: "الملف التعريفي",
        monthesTable: "التهيئة الزمنية",
        // addmonthes: "اضافة مؤهل دراسي",
        // editmonthes: "تعديل مؤهل دراسي ",

        requestsIndexTable: " السلف",

        companyDocumentsTable: "مستندات الشركة",
        addcompanyDocuments: "اضافة مستند",
        editcompanyDocuments: "تعديل مستند",

        govPaymentsTable: "المصروفات الحكومية",
        addgovPayments: "اضافة مصروف",
        editgovPayments: "تعديل مصروف",
        // addrequestsIndex: "تجهيز الرواتب",
        // editrequestsIndex: "تعديل ",

        employeeAdvancePaymentInstallmentTable: "ضبط السلف",
        addemployeeAdvancePaymentInstallment: "ضبط السلفة  ",
        editemployeeAdvancePaymentInstallment: "تعديل  ",

        advanceManagement: "إدارة السلف",
        deleteAll: "حذف المحدد",
        conflict: "يوجد تعارض بالجدول",

      },
      payrollType: {
        payroll: "رواتب",
        adjustment: "تسوية",
        end_service: "مكافئة نهاية الخدمة",
        type: 'النوع',
        title: "الاسم",

      },
      customBenefit: {
        benefit_months: " شهور",
        value_type: "نوع القيمة",
        value: "القيمة",
        createCustomBenefit: "تخصيص اضافة",
        createCustomDeduction: "تخصيص خصم",
        deleteCustom: "حذف تخصيص",

      },
      govPayments: {
        title: "اسم اللائحة",
        deduction_type: "نوع القيمة",
        deduction_value: "القيمة",
        benefit_id: "الاضافات",
        branches: "الفروع",
        sponsors: "سجلات الشركة",
        empolyee_sections: "الفئة الوظيفية",
        nationalities: "الجنسية",

      },
      generalSettings: {
        generalSettings: "الأعدادات العامة",
        mail_title_note: "تكرما كتابة الاسم الذي سيظهر كمرسل لكافة اشعارات الموارد البشرية للمستخدمين",
        deduct: "الحسم",
        max_day_deduct: "الحد الأعلى للحسم باليوم",
        min_days_payroll: "الحد الأدنى من الأيام لنزول الراتب",
        notify_auto: "إعدادت المساءلات الالية",
        send_late_notify_auto: "إرسال مسالة التأخير أليا",
        send_early_notify_auto: "إرسال مسالة الانصراف المبكر أليا",
        send_absence_notify_auto: "إرسال مسالة الغياب أليا",
        mail_title: "تهيئة البريد للموارد البشرية",
        mail_title_ar: "اسم الحساب باللغة العربية",
        main_title_en: "اسم الحساب باللغة الانكليزية",
        add_direct_manager_to_staff: "اضافة المدير المباشر للموظف",
        accountant_index: "",
        liable_staff_reply: " فترة السماح باليوم لرد الموظف على المساءلة",
        liable_manager_reply: " الفترة المحددة باليوم للمدير لتقييم رد الموظف",
        liable_old_date: "السماح بإضافة المساءلة بتاريخ سابق",
        "send_finger_late_inquiry_auto": "إرسال مساءلة التأخير أليا (من جهاز البصمة)",
        "send_finger_early_inquiry_auto": "إرسال مساءلة الانصراف المبكر أليا (من جهاز البصمة)",
        "send_finger_absence_inquiry_auto": "إرسال مساءلة الغياب أليا (من جهاز البصمة)",
        "send_manual_late_inquiry_auto": "إرسال مساءلة التأخير أليا (عند التسجيل اليدوي)",
        "send_manual_absence_inquiry_auto": "إرسال مساءلة الانصراف المبكر أليا (عند التسجيل اليدوي)",
        "send_manual_early_inquiry_auto": "إرسال مساءلة الغياب أليا (عند التسجيل اليدوي)",
        template_body: "نص القالب",
        template: " القالب",
        send_templates: "قوالب المساءلات الألية وفق كل حالة",
        sendTemplates: {
          '1': "التأخير",
          '2': "الغياب",
          '3': "الانصراف المبكر",
          // '3': "متأخر",
          // '2': "غياب",
          // '4': "الانصراف المبكر"
        },
      },
      profil: {
        company: "الشركة",
        branches: "فروع الشركة",
        accounts_guide: "دليل الحسابات",
        name: "اسم الشركة",
      },
      suspensionReasons: {
        stop_type: "نوع الايقاف",
        stop_types: {
          1: "ايقاف الخدمة",
          2: "انهاء الخدمة",
        },
        end_service_types: {
          1: "استقالة",
          2: "اقالة",
          3: "انهاء عقد",
        },
        end_service_type: "نوع انهاء الخدمة",
        stop_reason: "سبب الايقاف",
      },
      relations: {
        1: "اب",
        2: "ام",
        3: "ابن",
        4: "زوجة",
        5: "اخت",
        6: "اخ",
        7: "اخر",
      },
      visas: {
        from_date: "تاريخ السفر",
        to_date: "تاريخ العودة",
        fees: "الرسوم",
      },
      requestStages: {
        title_ar: "اسم المرحلة بالعربي",
        title_en: "اسم المرحلة بالانكليزي",
        title: "المرحلة",
        staff_id: "الموظف",
        staff: "الموظف",
        branch: "الفرع",
        company_sections: "القسم",
        cost_center: "( المدرسة ) مركز التكلفة",
        stagesOne: "مرحلة الاعتماد المبدئي (المدير)",
        stagesTow: "مرحلة الاعتماد النهائي (الموارد البشرية)",
        requestType: {
          1: "visas",
          2: "escorts",
          3: "vacations",
        },
      },
      qualifications: {
        certificate_id: "الشهادة",
        university_id: "الجامعة",
        rating: "المعدل",
        city_id: "المدينة",
        date: "التاريخ",
        good: "جيد",
        verygood: "جيد جدا",
        excellent: "ممتاز",
        acceptable: "مقبول",
      },
      escorts: {
        assignDirectManagerConfirm: "هل انت متأكد من احالة الطلب للمدير الأعلى",
        acceptConfirm: "هل انت متأكد من الموافقة على طلب الاعتماد",
        accept: "موافقة",
        rejectConfirm: "هل انت متأكد من رفض الاعتمار",
        reject: "رفض",
        staff_id: "اسم الموظف",
        name_ar: "اسم المرافق",
        name_en: "اسم المرافق بالانكليزي",
        nationality_id: "الجنسية",
        job_title_id: "الوظيفة",
        other_job_title: " الوظيفة كتابه",
        other: "أخرى",
        gender: "النوع",
        relative_relation: "صلة القرابة",
        mobile_number: "رقم الجوال",
        identity_number: "رقم الهوية",
        id_end_date: "تاريخ انتهاء الهوية",
        birth_date: "تاريخ الميلاد",
        add_date: "تاريخ الاضافة",
        last_update: "تاريخ اخر تعديل",
        filename: "اسم الملف(تحميل صورة جواز السفر)",
      },
      requestsIndex: {
        id: "رقم الطلب",
        data: "تاريخ الطلب",
        staff: "الموظف",
        notes: "الملاحظة",
        accepted_at: "تاريخ اعتماد السلفه",
        advance_list_id: "اسم السلفه",
        cost_center: "( المدرسة ) مركز التكلفة",
        cost_center_branch: "الفرع",
        main_salary: "الراتب الاساسي",
        total_benefits: "الاضافات",
        total_salary: "الراتب الكلي",
        paid_amount: "مبلغ السلفة",
        confirmed_amount: "المبلغ المعتمد",
      },
      payrolls_bank_details: {
        bank_code: "رمز البنك",
        total_salary: "الصافي",
        job_number: "الرقم الوظيفي",
        name: "اسم الموظف",
        payroll_type: "وصف المدفوعات",
        identity_number: "رقم الهوية",
        salary: "الراتب الاساسي",
        total_benefits: "مدفوعات اخرى للعامل",
        total_deductions: "الخصومات على العامل",
        work_office_number: "رقم مكتب العمل",
        insurance_salary: "راتب التأمينات",
        employee_status: "حالة الموظف",
        infraction_status: "حالة المخالفة",
        infraction_reason: "سبب المخالفة",
        sponsor: "(السجلات)الكفالة",
        protect: "نسبة حماية الأجور",
        process: "معالجة",
        created_at: "تاريخ التصدير",
        bank_replied: "الحالة",
        creator: "تم بواسطة",
        status: {
          1: "مخالف",
          2: "غير مخالف",
        },
        bank_status: {
          0: "البنك لم يرد",
          1: "البنك رد",
        },
        add: "انشاء ملف",
      },
      payroll: {
        percent_type: {
          1: "اكبر او يساوي",
          2: "اصغر",
          3: "اكبر",
          4: "يساوي",
          5: "اصغر او يساوي",
        },
        addAdjustment: "اضافة تسوية",
        benefit: "اضافة ",
        deduct: "خصم",
        payroll_year: "السنة",
        payroll_month: "الشهر",
        payroll_type: "نوع المسير",
        calc_to_date: "احتساب حتى تاريخ",
        item: "البند",
        value: "القيمة",
        date: "التاريخ",
        staff_ids: "الموظفين",
        unique_id: "رقم المسير",
        payroll_id: "رقم المسير",
        cost_centers: "مراكز التكلفة",
        branches_ids: "الفرع",
        status: "الحالة",
        name: "اسم الموظف",
        nationality: "الجنسية",
        cost_center: "( المدرسة ) مركز التكلفة",
        branch: "الفرع",
        company_section: "القسم",
        employee_sections: "الفئة الوظيفية",
        job_number: "الرقم الوظيفي",
        job_titles: "الوظيفة",
        total_benefits: "الاضافات",
        total_deductions: "الخصومات",
        identity_number: "رقم الهوية",

        total_salaries: "صافي الرواتب",
        total_salary_col: "الراتب الاساسي",
        // payroll_year:"",
        payrollType: {
          1: "رواتب",
          2: "تسوية",
          3: "تصفية",
        },
        payrollStatus: {
          0: "قيد التجهيز",
          1: "تم التجهيز",
        },
      },
      employeeAdvancePaymentInstallment: {
        confirmed_amount: "البلغ المعتمد",
        amount: "قيمة القسط",
        month: "شهر الخصم ",
        year: "سنة الخصم",
        paid: "مدفوع",
        deduct_type: "طريقة الخصم",
        deduct_status: "حالة الخصم",
        notes: "ملاحظة",
        error: "يجب ان يكون المجموع يساوي المبلغ المعتمد",
      },
     
      salaryAdvances: {
        requested_amount: "القيمة المطلوبة",
        payment_method: "طريقة السداد",
        recovery_method: "طريقة الخصم",
        advance_list_id: "اسم السلفه",
        discount: "خصم",
        discount_done:"تم الخصم",
        discount_chenge:"تعديل حالة الخصم",
        discount_not_done:" لم يتم الخصم",
        discountConfirm: "هل انت متاكد من تغير الحالة الى تم الخصم",
        discount_notConfirm: "هل انت متاكد من تغير الحالة الى لم يتم الخصم",
        cash: "كاش",
        bank: "بنكي",
        paid_amount: "المسدد",
        state: "حالة الطلب",
        confirmed_amount: "القيمة المعتمدة",
        pay_state: "حالة الصرف",
        pay_status: {
          1: "تم الصرف",
          0: "لم يتم الصرف",
        },
        status: {
          0: "جديد(منتظر اعتماد)",
          4: "مسدد",
          5: "معلق",
          1: "معتمد",
          2: "مرفوض",
        },
        changeStatusConfirm: "هل انت متأكد من تغير حالة الطلب",
        changepayStateConfirm: "هل انت متأكد من تغير حالة الصرف",
        recovery_methodType: {
          'cash': "تخصم نقديا",
          'cach': "تخصم نقديا",
          'monthly': "تخصم شهرياً",
          'end_of_service': "تخصم عند نهاية الخدمة",
        },
        paids: {
          '1': "تم الخصم",
          '0': "لم يتم الخصم",
        },
        payment_methodType: {
          1: "كاش",
          2: "بنكي",
        },
        // recovery_methodType:{
        //   1:"تخصم نقد",
        //   2:"تخصم شهرياً",
        //   3:" الخصم عند نهاية الخدمة",
        // }
      },
      attendances: {
        date: "التاريخ",
        attend_datetime_hijry: "التاريخ الهجري",
        custom_delay_employee: "تطبيق قيمة تأخير مخصصة للموظف",
        deduction_value: "قيمة الاحتساب",
        no_list: "اللائحة غير موجودة",
        late_details: "تفاصيل احتساب قيمة التاخير",
        apply_type: "نوع الاحتساب",
        deduction_total: "اجمالي الخصم",
        days_count: "عدد ايام الغياب",
        apply_types: {
          '1': "احتساب وفق اللائحة",
          '2': "احتساب مخصص",
        },
        details: {
          '2': "تفاصيل احتساب قيمة الغياب",
          '3': "تفاصيل احتساب قيمة التاخير",
          '4': "تفاصيل احتساب قيمة الانصراف المبكر",
        },
        deduction_types: {
          1: " نسبة من الراتب ",
          4: "نسبة من اليوم ",
          3: "يوم",
          2: "مقطوع",
          5: "احتساب تلقائي",
        },
        listDetails: {
          list_title: "اللائحة المطبقة",
          salary: "الراتب الاساسي",
          total_salary: "المجموع الكلي",
          // days_count: "",
          // deduction_value: "",
          deduction_type: "نوع الخصم",
          deduction_total: "القيمة",
          deduction_total_calc: "قيمة الاحتساب",
          day_price: "قيمة اليوم من مجموع المرتب",
          // deduction_minute: "قيمة الدقيقة من الراتب",
          deduction_minute: {
            '2': 'تكلفة يوم الغياب',
            '3': 'تكلفة دقيقة التأخير',
            '4': " تكلفة دقيقة الانصراف المبكر"
          },
          // list_id: "",
          note: "ملاحظات"
        },
        time: {
          '3': 'توقيت الحضور الفعلي',
          '4': " وقت الانصراف الفعلي"
        },
        // minutes_count:"عدد دقائق التأخير",
        minutes_count: {
          '3': 'عدد دقائق التأخير',
          '4': " عدد الدقائق"
        },
        is_excused: "معذور",
        list_calc: "احتساب لائحة",
        custom_imp: "تطبيق مخصص",
        year: "السنة",
        months: "الشهور",
        ref_time: {
          '3': 'وقت احتساب التأخير',
          '4': " وقت الانصراف"
        },
        not_excused: "غير معذور",
        attendance_type: "نوع الغياب",
        attendType: {
          // 0:"حضور",
          1: "حضور",
          2: "غياب",
          3: "تأخير",
          4: "انصراف مبكر",
        },
      },
      documents: {
        date_from: "التاريخ من",
        date_to: "التاريخ الى",
        status: {
          0: "منتهي",
          1: "سار"
        },
        staff_id: "اسم الموظف",
        document_type_id: "نوع المستند",
        document_title: "اسم المستند",
        document_number: "رقم المستند",
        start_date: "تاريخ البدء ",
        end_date: "تاريخ الانتهاء ",
        start_dateHijri: "تاريخ البدء هجري",
        end_dateHijri: "تاريخ الانتهاء هجري",
      },
      contracts: {
        staff_id: "",
        view: "استعراض العقد",
        yearly_vacation_id: "لائحة الاجازات السنوية",
        contract_type_id: "نوع العقد",
        start_work_date: "تاريخ مباشرة العمل",
        contract_end_date: "تاريخ نهاية العقد",
        work_type: "نوع العمل",
        work_area: "نطاق العمل",
        standard_working_hours: "معيار ساعات العمل",
        work_hours: "عدد ساعات العمل",
        work_days: "عدد ايام العمل الاسبوعية",
        work_weekly_days: "عدد ايام العمل الاسبوعية",
        days_off: "عدد ايام الراحة الاسبوعية",
        work_holidays: "عدد ايام الراحة الاسبوعية",
        remaining_day: "عدد الايام المتبقية",
        contract_terms: "بنود العقد",
        contract_category_id: "فئة العقود",
        attachement: "المرفقات",
        full_time: "دوام كلي",
        part_time: "دوام جزئي",
        remotely: "دوام عن بعد",
        in_ksa: "داخل المملكة",
        out_ksa: "خارج المملكة",
        daily: "يومي",
        return: "مرتجع",
        weekly: "اسبوعي",
        employement_stop_reason: "سبب الايقاف",
        name: "اسم الموظف",
        username: "اسم المستخدمين",
        identity_number: "رقم الهوية",
        job_number: "رقم الموظف",
        cost_center: "( المدرسة )مركز التكلفة",
        branch: "الفرع",
        section: "الفئة الوظيفية",
        company_section: "القسم",
        current_job: "الوظيفة الحالية",
        job_title: "المسمى الوظيفي",

        contract_type: "نوع العقد",
        yearly_vacation: "لائحة الاجازات السنوية",
        contract_created_at: "تاريخ التعيين",
        appoint_date: "تاريخ التعيين",
        contract_end_at: "تاريخ نهاية العقد",
        nationality: "الجنسية",
        contractAssign: "تعيين عقد",
        contractRenewal: "تجديد العقد",
        endcontract: "إنهاء عقد",
        employement_status: "الحالة",
      },
      endContract: {
        table: "تهيئة نهاية الخدمة",
        add: "تهيئة",
        period: "الفترة بالعام",
        periodbyyear: "الفترة بالاعوام من الى",
        period_form_to: "الفترة من الى",
        from: "من",
        createEndContract: "اعتماد",

        staffAdvancesTotal: "السلف المتبقية",
        staffBonuses: "المكافئة",
        totalEndContract: "صافي نهاية الخدمة",

        bonus_type: "المكافئة النوع والقيمة",
        end_service_types: {
          1: "استقالة",
          2: "اقالة",
          3: "انهاء خدمة",
        },
        to: "الى",
        type: "النوع",
        contract_type: "نوع العقد",
        value: "القيمة",
        resignation: "استقالة",
        endContactType: "نوع انهاء الخدمة",
        terminal_bonus: "احتساب مكافئة",
        types: {
          1: " نسبة",
          2: "مقطوع",
        },
        bonus_payment_2_type: "انهاء خدمة",
        bonus_payment_3_type: "اقالة",
        benefit_control: "ضبط المكافئة",
        bonus_payment: "الية صرف المكافئة"
      },
      inkindInventory: {
        assign: "تعيين",
        removeAssign: "ازالة التعيين",
        title: "اسم العهدة",
        cost_center_id: "الفرع",
        cost_center: "( المدرسة ) مركز التكلفة",
        section_id: "اختر القسم/المرحلة",
        section: "القسم",
        branch: "الفرع",
        serial_code: "كود العهد",
        quantity: " الكمية",
        description: "المواصفات",
        attachement: "مرفقات",
        assign_date: "تاريخ الاستلام",
        job_number: "الرقم الوظيفي",
        staff_name: "المسلم",
        assigned_user_name: "المستلم",
        staff_id: "اسم المستلم",
        removeAssignAll: "ازلة تعيين المحدد",
      },
      extra_time_type: {
        extra_day: "يوم",
        extra_minutes: "دقيقة",
      },
      extraTime: {
        extra_time_type: "في حال العمل الاضافي بعد وقت العمل الرسمي",
        extra_time: "العمل الاضافي",
        day: "يوم",
        minute: "دقيقة",
        extra_minutes: "يضاف",
        benefit_type: "النوع",
        is_in_fingerprint: "يستخدم في البصمة",
      },
      fingerprint: {
        group_id: "المجموعة",
      },
      acc_managers: {
        staff_id: "مدير الاعتماد",
        employee_sections_id: "الفئة الوظيفية",
        maximum: "حد اعتماد السلفة الواحدة",
        from_date: "تاريخ بداية الاعتماد",
        to_date: "تاريخ نهاية الاعتماد",
        yearly_max: "حد اعتماد الكلي",
      },
      exec_managers: {
        staff_id: "مسؤول التنفيذ",
        employee_sections_id: "الفئة الوظيفية",
      },
      pay_managers: {
        staff_id: "مسؤول الصرف",
        employee_sections_id: "الفئة الوظيفية",
      },
      lateList: {
        title_ar: "اسم اللائحة",
        title_en: "اسم اللائحة بالانكليزي",
        job_title_ids: "الوظيفة",
        fom_minutes: "من",
        to_minutes: "الى",
        deduction_type: "نوع القيمة",
        deduction: "القيمة",
        benefits: "الاضافات الافتراضية",
        additional_sanction: "جزاء اضافي",
        additional_sanction_id: "الجزاء",
        percentage_salary: " نسبة من الراتب ",
        ok: "نعم",
        percentage_day: "نسبة من اليوم ",
        percentage: "نسبة ",
        day: "يوم",
        fixed: "مقطوع",
        auto_calc: "احتساب تلقائي",
        get_out_early: "في حال الخروج قبل بوقت",
        // additional_sanction_id:"",
      },
      absenceList: {
        title_ar: "اسم اللائحة",
        job_title_ids: "الوظيفة",
        times:"عدد مرات التكرار",
        repetition:"تكرار",
        norepetitionTimes:"لا يمكن تكرار قيمة عدد مرات التكرار",
        benefit: 'البدلات الاضافية',
        benefit_default: 'يعتمد بشكل افتراضي',
        title_en: "اسم اللائحة بالانكليزي",
        days_count: "في حال غياب يوم",
        sanction_day: "ايام الغياب",
        deduction: "يتم خصم",
        deduction_type: "نوع الخصم",
        branch_id: "الفرع",
        absence_type: "نوع الغياب",
        benefits: "الاضافات الافتراضية",
        additional_sanction: "جزاء اضافي",
        additional_sanction_id: "الجزاء",
        percentage_salary: " نسبة من الراتب ",
        ok: "نعم",
        percentage_day: "نسبة من اليوم ",
        day: "يوم",
        fixed: "مقطوع",
        get_out_early: "في حال الخروج قبل بوقت",
      },
      vacations: {
        title_ar: "مسمى الإجازة",
        title_en: "مسمى الاجازة بالانكليزي",
        annual_vacation_days: "عدد أيام الاجازة السنوية",
        annual_vacation_requests: "عدد مرات طلب الاجازة سنويا",
        allowed_vacation_days: "عدد الايام المسموح بها لكل اجازة",
        max_annual_vacation_days: "الحد الادنى من ايام العمل للحصول على اجازة",
        min_work_days: "الحد الادنى من ايام العمل للحصول على اجازة",
        gender: "الجنس",
        religions: "الديانة",
        insurance_classes: "فئة التأمين",
        employee_sections: "الفئات الوظيفية",
        calculation_date: "احتساب الاجازة ابتداء من تاريخ",
        year_type: "بناء على حسب السنة",
        yearly_work_days: "عدد ايام العمل السنوية",
        deduction_percentage: "نسبة الخصم",

        // prevent_exceeding_type:'',
        allow_vacations_overlap: "اعتماد في حال تداخل اجازات اخرى",
        deduction_from_annual_vacation_balance: "خصم من رصيد الإجازة السنوية",
        add_previous_balance: "اضافة الرصيد السابق للرصيد الحالي",
        deduct_official_holiday: "خصم الاجازات الرسمية ",
        deduct_working_holidays: "خصم عطلات الدوام",
        start_from_request_date: "اعتماد رصيد الاجازة حسب تاريخ طلبها",
        allow_previous_date: "السماح بطلب الأجازة بتاريخ سابق",
        require_vacation_reason: "ضرورة ذكر سبب الاجازة",
        require_attach_document: "ضرورة ارفاق وثيقة",
        financial_year: "سنة مالية",
        normal_year: "سنة عادية",
        leap_year: "سنة عقدية",
        benefits: "الاضافات",
        // fffff:'',
        vacation_type: "نوع الاجازة",
        vacation_id: "الاجازة",
        vacation_name: "اسم الإجازة",
        start_date: "تاريخ بداية الإجازة ",
        end_date: "تاريخ نهاية الإجازة ",
        notes: "الملاحظة",
        files: "المرفقات",
        name: "اسم الموظف",
        created_at: "تاريخ الطلب",
        nationality: "الجنسية",
        cost_center: "الفرع",
        employee_section: "القسم",
        job_number: "الرقم الوظيفي",
        vacation_days: "مدة الإجازة",
        state: "الحالة",
        username: "اسم المستخدم",
        identity_number: "رقم الهوية",
        status: {
          0: "جديد",
          1: "مقبول",
          2: "مرفوض",
        },
      },
      visibilityStaff: {
        form_data: "بيانات النموذج",
      },
    },
    printSitting: {
      printReceiptVouchers: "طباعة سند قبض",
      printPaymentVouchers: "طباعة سند دفع",
      printInvoice: "طباعة الفاتورة",
    },
    finger: {
      work_schedule: {
        title: 'جداول الدوام',
      },
      serial_number: "الرقم التسلسلي",
      send_prints_notify_time: "توقيت ارسال التنبيه",
      hour: "الساعة",
      dependable: "معتمد",
      notdependable: "غير معتمد",
      adopting_flexible_working_hours: "اعتماد الدوام المرن",
      roles: "الدور",
      minute: "الدقيقة",
      staffUpdate: "تحديث بيانات الموظفين",
      student_downtime_settings: "إعدادات التوقف الدراسي للطالب",
      show_custom_days_added: "عرض الايام المخصصة المضافة",
      virtual_vacation_days: "ايام الاجازة الافتراضية",
      student_downtime_ministry_calendar:
        "التوقف الدراسي للطالب حسب تقويم الوزارة",
      fingerprint_text_message_settings: "إعدادات رسائل الجوال | البصمة",
      fingerprint_settings: "إعدادات  البصمة",
      second_fingerprint_settings: " إعدادات  البصمة الثانية",
      text_message_settings: "إعدادات رسائل الجوال",
      Absence_delay_and_permission_timing_settings:
        "إعدادات توقيت الغياب والتأخير والإستئذان",
      dynamic_work_hours:"عدد ساعات العمل بالدوام المرن",
      start_time: "وقت بدء احتساب التوقعات",
      attend_time: "بداية الدوام",
      attend_time_flexible: "بداية حضور الدوام المرن",
      absence_time_flexible: "نهاية حضور الدوام المرن",
      calculation_time_must_before_start_work:
        "يجب ان يكون وقت الاحتساب قبل بداية الدوام",
      // attend_time_allow: "مدة السماح بالتجاوز",

      late_time_after: "بداية احتساب التأخير",

      absence_time: "بداية احتساب الغياب",
      departure_period: "فترة الانصراف المبكر",
      departure_time: "وقت الانصراف",
      check_in: " تسجيل وصول",
      to_ask_permission: "استئذان",
      exit: "خروج",
      other: "أخرى",
      status: "الحالة",
      name: "الاسم",
      id_number: "رقم الهوية",
      from_date_year: "السنة",
      from_date_month: "الشهر",
      from_date_day: "من اليوم",
      branch_id: "الفرع",
      employee_section_id: "الفئة الوظيفية",
      to_date_year: "السنة",
      to_date_month: "الشهر",
      to_date_day: "إلى اليوم",
      classroom_id: "الصف",
      all_signatures_users: "جميع توقيعات اجهزة المستخدمين",
      identity_number: "رقم الهوية",
      timestamp: "تاريخ توقيت البصم",
      device_serial: "رقم الجهاز",
      device_name: "اسم الجهاز",
      finger_type: "نوع التوقيع",
      landing: "وصول",
      excused: "معذور",
      from_time: "التوقيت من",
      to_time: "إلى",
      point_search_Hijri_date: "اشر للبحث بالتاريخ الهجري",
      second_fingerprint:
        "اعتماد البصمة الثانية خلال وقت الدوام بشكل تلقائي بعد فترة احتساب الغياب",
      attend_type: "الحالة",
      second_fingerprint_not:
        "عدم احتساب  البصمة الثانية كبصمة خروج بشكل تلقائي قبل",
      attendTypes: {
        attend: "حاضر",
        late: "متأخر",
        absence: "غياب",
        excused: "معذور",
        other: "آخرى",
      },
      class_grade: "الصف والفصل",
      attend_date: "وقت الحضور",
      leave_date: "وقت الانصراف",
      late_date: "دقائق/ساعات التأخير",
      mobile: "الجوال",

      absence_acount: "عدد الغائبين",
      late_acount: "عدد المتأخرين",
      attend_acount: "عدد الحاضرين",
      excused_acount: "عدد المستئذنين",
      other_acount: "آخرى",
      landing_acount: "تسجيل وصول",
      exit_acount: "خروج",
      excuse_acount: "استئذان",
      attendance_and_absence_from_fingerprint_devices:
        "الحضور والغياب من اجهزة البصمة",
      days: {
        1: "السبت",
        2: "الاحد",
        3: "الاثنسن",
        4: "السبت",
        5: "السبت",
        6: "السبت",
        7: "السبت",
      },
      attendance_documents: "الكشف اليومي (الحضور الانصراف التأخير الغياب)",
      school_class_id: "الفصل",
      fingerprint_number_already_exists: "رقم البصمة :number موجود مسبقا",
      fingerprint_id: "رقم البصمة",
      fingerprint_off: "فصل عن البصمة",
      studentsUpdate: "تحديث بيانات الطلاب",
      changeFingerOff: "هل انت موافق على فصل عن البصمة ",
      rec_type: {
        1: "سجلات الحضور",
        2: "سجلات التأخير",
        3: "سجلات الغياب",
        4: "سجلات المعذورين",
      },
    },
    tincme_var: {
      number: "رقم الفاتورة",
      name: "البيان",
      due_date: "تاريخ الفاتورة",
      header: "الرأس",
      footer: "التذييل",
      compName_ar: "اسم الشركة بالعربي",
      compName_en: " اسم الشركة بالانجليزي",
      cr_no: "رقم السجل التجاري",
      tax_number: "الرقم الضريبي",
      logo: "الشعار",
      date: "التاريخ",
    },
    whatsappSettings: {
      title: "إعدادات الوتساب",
      host: "الهوست",
      port: "البورت",
      token: "التوكن",
      send_file: "ارسال ملف",
      send_text: "ارسال رسالة",
      phone: "رقم الجوال",
      sendSuccess: "تم ارسال رسال الواتساب بنجاح",
      home_phone: "هاتف المتزل",
      work_phone: "هاتف العمل",
      mobile: "جوال",
      caption: "رسالة المرفق",
      text: "نص الرسالة"
    },
    smsSettings: {
      title: "إعدادات الرسائل",
      app_id: "APP ID",
      app_secret: "APP SECRET",
      sender: "SENDER",

      balance: "الباقات",
      name: "الاسم",
      mobile: "رقم الجوال",
      email: "الايميل",
      packageTitle: "الباقة",

      networks: "الشبكات",
      price: "السعر",
      tax: "الضريبة",
      package_points: "نقاط الباقة",
      current_points: "النقاط الحالية",
      total: "المجموع الكلي",
      used_balance: "الرصيد المستخدم",
      network_tags:"علامات الشبكة",
      network_tag:{
        title:"العنوان",
        image:"الصورة",
      },  
      network:{
        image:"الصورة",
      },
      
    },
    super:{
      companies:{
        status:'الحالة',
        statuses:{
          0:'غير مفعل',
          1:"مفعل",
        },
        columnDefs:{
          compname:"اسم الشركة",
          name:"الاسم",
          loginAs:"تسجيل الدخول ك",
          users:"المستخدمين",
          country:"الدولة",
          email:"البريد الالكتروني",
          tax_number:"الرقم الضريبي",
        },
      },
    },
    ledgerViewParent:{
      full_name:"اسم الطالب",
      case_study_name:"حالة الطالب",
      mobile:"جوال ولي الامر",
      classroom_name:"الصف",
      total_init_value:"اجمالي التعاقد",
      amount_init_value:"مبلغ التعاقد",
      tax:"اجمالي الضريبة ",
      paid:"المدفوع",
      total_paid:"اجمالي المدفوع",
      paid_invoice:"فواتير مدفوعة",
      due_paid:"فواتير مستحقة",
      start_date:"العام الدراسي",
      total_general_discount:"الخصم",
      remainder:"الباقي",
      remainder_contract:"باقي التعاقد",
      credit:"الارصدة الافتتاحية (الدائن)",
      debit:"الارصدة الافتتاحية (المدين)",
      credit_late:"الرصيد السابق (الدائن)",
      debit_late:"الرصيد السابق (المدين)",
      settlements:"التسويات",
      total_tax:"اجمالي الضريبة",
      parent_balance:"رصيد ولي الامر",
      // full_name:"الطالب",
    },
    incomeStatement:{
      title:'قائمة الدخل',
      total:'الاجمالي',
      name:'الاسم',
    },
    governmentBudget:{
      title:'الميزانية العمومية ',
      total:'الاجمالي',
      name:'الاسم',
    },
    general: {
      amount_received:'المبلغ المستلم',
      remaining_amount_bill:'المبلغ المتبقي من الفاتورة',
      total_remaining:'اجمالي المتبقي',
      preview_record_bond:'معاينة القيد والسند',
      print_record:'طباعة القيد ',
      pay:'دفع',
      manual_discounts:'خصومات يدوية',
      receipt_voucher_print:'طباعة سند قبض',
      receipt_receipts_print:'طباعة سند صرف',
      donet_show_zero_accounts:"عدم اظهار الحسابات الصفرية",
      parent_collectionsDelele: "حذف القيد",
      parent_collectionsRestore: "اسستعادة القيد",
      bank_type: "نوع البنك",
      father_identification_number:"رقم هوية ولي الامر",
      student_id_number:"رقم هوية الطالب",
      total_discount: "الخصم العام",
      addOnlineBank: "اضافة بنك اون لاين",
      bank_online_type: "بوابة بنك الاون لاين",
      filtering: "تصفية",
      job_number: "الرقم الوظيفي",
      back_to_the_main_account: "عودة الى الحساب الرئيسي",
      companies: "الشركات",
      users: "المستخدمين",
      restore: "استعادة",
      journal_module: "الاصل",
      journal_entries_deleted: "القيود المحذوفة",

      semster: " السنة الدراسية",
      dynamicTitle: "البند",
      value: "القيمة",
      total_debit: "الحساب المدين بالمبلغ",
      total_credit: "الحساب الدائن بالمبلغ",
      date_show: "تاريخ القيد",
      deleted_at: "تاريخ الحذف",
      deleted_by: "حذف بواسطة من",
      journal_entry_deleted_count: " عدد القيود المحذوفة",

      due_date_show: "تاريخ اخر قسط",
      ApiKey: "API key",
      api_secret: "API Secret",
      WebHook: "Web Hook",
      onlinePaymentMethods: "اعدادات السداد الالكتروني",
      realMode: "وضع حقيقي",
      fakeMode: "وضع وهمي",
      ranking: "التصنيف",
      staffGovPayments: "المصروفات الحكومية",

      staff_info: "بيانات الموظف",
      reports: "التقارير",
      invoices: "الفواتير",
      has_contract: "حالة تعاقد",
      delete: "حذف",
      visibility: "عرض",
      money: "حالة الصرف",
      assignDirectManager: "احالة الطلب للمدير الاعلى",
      accept: "قبول",
      reject: "رفض",
      all: "الكل",
      info: "المعلومات الشخصية",
      addParents: "اضافه ولي امر",
      editParents: "تعديل ولي امر",
      unique: "يجب ان يكون وحيد غير مكرر",
      show: "عرض",
      validatePhoneNumber: "رقم الجوال غيرصحيح",
      copySetting: "نسخ الاعدادات",
      setting: "نسخ من",
      print: "طباعه",
      accessDenied: "ليس لديك صلاحيه لعرض هذه الصفحه",
      companyName: "الشركه",
      title: "العنوان",
      guardian: "ولي الامر",
      error: "خطأ",
      uploadFile: "رفع ملف",
      insertFatherData: "اختر الطالب",
      firstState: "أرصدة أول المدة",
      restAmount: "المتبقي",
      group_domain: "دومين المجموعة",
      identity_number: "رقم الهوية",
      entery_number: "رقم القيد",
      name_ar: "المسمى بالعربي",
      name_en: "المسمى بالإنجليزي",
      nick_name_en: "الاسم المستعار بالانجليزى",
      nick_name_ar: "الاسم المستعار بالعربى",
      event: "الحدث",
      channel_name: "اسم القناة",
      bank_name: "اسم البنك",
      bank: "البنك",
      link_status: "حالة الإتصال",
      connected: "متصل",
      not_connected: "غير متصل",
      status: "الحاله",
      classRoom: "الصف الدراسى",
      updated_at: "اخر تعديل",
      bank_fees_percentage: "نسبه الرسوم",
      bank_fees_percentage_account: "حساب نسبه الرسوم",
      bank_fees_tax_percentage: "نسبة ضريبة الرسوم المصرفية",
      bank_fees_tax_percentage_account: "نوع الضريبة",
      bank_account: "حساب البنك",
      bank_account_guide: "حساب الرسوم المصرفية",
      parents: "أولياء الأمور",
      btn_export: "الاستيراد من أولياء الأمور",
      action: "الإجراءات",
      message: "تم تغير الحاله بنجاح",
      active: "مفعله",
      inactive: "غير مفعله",
      file_name: "اسم الملف",
      download: "تحميل",
      addNewStudent: "إضافة طالب",
      addNewParent: "إضافة ولي امر",
      moreOption: "خيارات أخرى",
      processing: "عمليات",
      numAcademy: "الرقم الأكاديمي",
      idNumber: "رقم الهوية",
      fingerPrint: "البصمة",
      passport: "جواز السفر",
      dateOfBirth: "تاريخ الميلاد",
      placeOfBirth: "مكان الميلاد",
      joining_date: "تاريخ الالتحاق",
      year_of_acceptance: "سنة القبول",
      fatherIDnumber: "رقم هوية الأب",
      motherIDnumber: "رقم هوية الأم",
      former_teacher: "مركز التكلفة السابقة",
      motherFullName: "اسم الأم بالكامل",
      name_student: "أسماء الطلاب",
      numOfParents: "عدد الآباء",
      enter_code: "أدخل الرمز الظاهر في الصورة",
      studentCount: "عدد الطلاب",
      students: "الطلاب",
      affiliates: "منسوبين",
      student_update: "تحديث بيانات الطلاب",
      import_excel: "الاستيراد من ملف اكسل",
      upload_image: "استيراد صور الطلاب",
      add_student: "نموذج إضافة طالب",
      addStudent: " إضافة طالب",
      editStudent: "تعديل طالب",
      import_users: "الاستيراد من ملف نور",
      import_user: "استيراد مباشر من  نور",
      educational_level: "المرحله الدراسيه",
      view_student: "عرض الطلاب الخريجين",
      payment_mothed: "طريقه السداد",
      discount_percentage: "نسبة الخصم",
      list_of_student: "قائمة الطلاب المتقدمين",
      setting_students: "أعداد الطلاب",
      parent_of_student: "الطلاب غير المرتبطين بأولياء الأمور",
      search_of_school: "البحث في جميع مراكز التكلفة",
      discount_value: "قيمة الخصم",
      class_room: "الصفوف الدراسيه",
      daily_restrictions: "القيود اليومية",
      installments: "الأقساط",
      taxes_included: "شامل الضريبة",
      specialdiscounts: "الخصومات الخاصة",
      restrictionsType: "نوع القيد",
      discount: "الخصم",
      amount: "المبلغ",
      fees: "رسوم",
      installment_amount: "مبلغ القسط",
      due_date: "تاريخ القسط",
      installment_name: "اسم القسط",
      can_be_distributed: "قابل للتوزيع",
      addUser: "إضافة مستخدم",
      updateUser: "تعديل مستخدم",
      Areas: "المناطق",
      Add_Area: "إضافة منطقة",
      edit_Area: "تعديل منطقة",
      Area_Name: "اسم المنطقة",
      Branches: "الفروع",
      Add_Branch: "إضافة فرع",
      Branch_Name: "اسم الفرع",
      Cost_Center: "مراكز التكلفة",
      Add_Cost_Center: "إضافة مركز تكلفة",
      edit_Cost_Center: "تعديل مركز تكلفة",
      Cost_Center_Name: "اسم مركز التكلفة",
      taxes: "الضريبة",
      taxes_title: "الضرائب",
      Edit_Branch: "تعديل فرع",
      add: "إضافة",
      deleteAll: "حذف الكل",
      deletePayroll: "حذف المسير",
      edit: "تعديل",
      image: "الصورة",
      phone: "رقم الهاتف",
      Welcome_back: "مرحبا بعودتك ",
      Login_to_your_account: "تسجيل الدخول إلى حسابك",
      OR: "أو",
      language: "اللغة",
      fiscal_year: "السنة المالية",
      Account_settings: "الحسابات العامة",
      costcenterbranchescount: "عدد الفروع",
      costcenterscount: "عدد مراكز لتكلفة",
      costCenter: "مركز التكلفة",
      PLEASE_WAIT: "برجاء الانتظار",
      Users: "المستخدمين",
      View_All: "عرض الكل",
      Set_All_As_Read: "تعيين الكل كمقروء",
      Unread_Message: "رسالة غير مقروءة",
      Select_Language: "الرجاء اختيار اللغة",
      logout: "تسجيل الخروج",
      search: "بحث",
      required: "مطلوب",
      area: "المنطقة",
      tax: "الضرائب",
      journal_entries: "القيود اليومية",
      percentage_sign: "%",
      select_tax: "من فضلك اختر ضريبة",
      error_massage: "الرجاء إدخال المبلغ ونسبة الضريبة",
      personal_details: "البيانات الشخصية",
      name: "الاسم",
      email: "البريد الإلكتروني",
      company: "الشركة",
      role: "الدور",
      roles_permissions: "الأدوار والصلاحيات",
      alerts: "التنبيهات",
      confirm: "تحقق",
      telegram: "التليجرام",
      account_activated: "تم تفعيل الحساب",
      cancel_edit: "الغاء التعديل",
      enable_edit: "تعديل",
      telegram_message:
        "قم بالضغط علي الرابط التالي للتحقق من حسابك وقم بادخال رقم التحقق",
      confirmation_link: "رابط التحقق",
      enable_alerts: "تنبيه تسجيل الدخول للحساب",
      enable_alerts_login: "تنبيه تسجيل الدخول للحساب",
      addRole: "إضافة مجموعة",
      editRole: "تعديل مجموعة",
      credit: "دائن",
      debit: "مدين",
      account: "الحساب",
      statement: "البيان",
      not_found: "لا توجد بيانات",
      date: "التاريخ",
      currency: "العملة",
      entry_source: "مصدر القيد",
      transfer_price: "سعر التحويل",
      entry_type: "نوع القيد",
      original: "الأصل",
      attachments: "المرفقات",
      total: "المجموع",
      difference: "الفرق",
      save: "حفظ",
      new: "جديد",
      preview: "معاينة",
      sendWhatsapp: "ارسال واتساب",
      cost_center: "( المدرسة ) مركز التكلفة",
      branch: "الفرع",
      no_attachments: "لا يوجد مرفقات لهذا القيد.",
      attachment: "مرفق",
      personalImage: "الصورة الشخصية",
      dashboard: "النظام المالي",
      recent_updates: "التحديثات الأخيرة",
      salaries_and_wages: "الرواتب والأجور",
      rentals: "الإيجارات",
      government_expenses: "المصاريف الحكومية",
      other_expenses: "نفقات أخرى",
      technical_support: "الدعم الفني",
      update_tax: "تحديث الضريبة",
      total_unpaid_contracts: "إجمالي العقود غير المسددة",
      overdue: "متأخر عن السداد",
      total_debts: "إجمالي ذمم أولياء الأمور",
      specialize_columns: "تخصيص الأعمدة",
      sort: "ترتيب",
      father: "الأب",
      mother: "الأم",
      relatives: "أحد الأقارب",
      children: "الأبناء",
      living: "محل الإقامة",
      archive_file: "أرشيف الملفات",
      tag: "تحليلي",
      no_original: "لا يوجد قيد",
      mother_name: "اسم الأم",
      father_name: "اسم الأب",
      relative_name: "اسم القريب",
      childern: "الأبناء",
      upload: "إضافة مرفق",
      receipt_vouchers: "سندات القبض",
      payment_vouchers: "سندات الصرف",
      receipt_new_vouchers: "سندات الصرف",
      payment_new_vouchers: "سندات القبض",
      previous_balance: "الرصيد السابق",
      tax_included: "شامل الضريبة",
      no_taxes: "غير خاضع للضريبة",
      tax_not_included: "غير شامل الضريبة",
      entry: "قيد",
      manual_entry: "سند يدوي",
      entry_number: " قيد رقم #",
      entryNumber: "قيد رقم",
      from: "من",
      to: "الي",
      soon: "قريبا",
      update_semester: "تعديل الترم",
      add_semester: "إضافة ترم",
      opening_balance: "الأرصدة الافتتاحية",
      add_account: "إضافة حساب",
      update_account: "تعديل حساب",
      account_name: "اسم الحساب",
      account_code: "رمز الحساب",
      main_account: "الحساب الرئيسي",
      warning: "تحذير",
      none: "بدون",
      budget: "الموازنة التقديرية",
      notes: "ملاحظات",
      showLedger: "معاينه",
      showLedgerDetails: "معاينه دفتر الاستاذ",
      startData: "تاريخ من",
      endData: "تاريخ الي",
      Balanceforeachmove: "الرصيد لكل حركه",
      movementType: " نوع الحركه",
      balance: "الرصيد",
      branch_or_facility: "الفرع أو المنشأة:",
      contract_start_char_branch: "حرف بداية الفرع أو المنشأة",
      contracts_start_char_contract: "حرف بداية العقود",
      contracts_start_char_parent_receipt: "حرف بداية صرف اولياء الامور",
      contracts_contract_number_count: "عدد ارقام العقد",
      serial_number_contracts: "الارقام التسلسلية للعقود",
      serial_number_receipt: "الارقام التسلسلية لسندات القبض",
      serial_number_parent_payment: "الارقام التسلسلية لصرف اولياء الامور ",
      serial_number_parent_collection:
        "الارقام التسلسلية لتحصيل من اولياء الامور",
      serial_number_payment: "الارقام التسلسلية لسندات الصرف",
      serial_number_invoices: "الارقام التسلسلية للفواتير",
      sales_returns: "مردودات المبيعات",
      sales_returns_number: "رقم مردودات المبيعات",
      receipt_start_char_contract: "حرف بداية سند القبض",
      receipt_number_count: "عدد ارقام لسند القبض",
      parent_collection_number_count: "عدد ارقام  التحصيل",
      payment_start_char_branch: "حرف بداية الفرع أو المنشأة",
      payment_start_char_contract: "حرف بداية سند الصرف",
      invoice_start_char_contract: "حرف بداية الفاتوره",
      invoice_start_char_parent_collection: "حرف بداية التحصيل",
      payment_number_count: "عدد ارقام لسند الصرف",
      invoice_number_count: "عدد ارقام الفاتوره",
      invoice_number_count_parent_payment: "عدد ارقام صرف اولياء الامور",
      invoice_number_count_parent_receipt: "عدد ارقام صرف اولياء الامور",
      serial_number_parent_receipt: "الارقام التسلسلية للصرف من اولياء الامور",
      startWithNumber: "بدايه من رقم",
      receipt_start_char_branch: "حرف بداية الفرع أو المنشأة",
      home: "الرئيسية",
      student: "الطالب",
      date_start: "بدايه السنة",
      date_end: "نهاية السنة",
      addContract: "اضافه عقد",
      next: "التالي",
      google_authentication: "تحقق جوجل",
      google_message: "لزيادة الحماية ، يوصى باستخدام هذه الخدمة",
      text_authenticator: "التحقق بالرسائل النصية",
      email_verification: "التحقق بالبريد الالكتروني",
      devices_management: "إدارة الاجهزة",
      devices_message: "يمكنك تسجيل الخروج من الاجهزة الاخري المتصلة",
      management: "الادارة",
      password: "كلمة المرور",
      password_message: "يمكنك تغيير كلمة مرور تسجيل الدخول للنظام",
      change: "تغيير",
      activity_message: "آخر تسجيل دخول",
      activity_record: "سجل الانشطة",
      update: "تحديث",
      connectWithTree: "ربط",
      collections_parents: "تحصيل من أولياء الأمور",
      semasterStart: "بداية الفصل",
      semasterEnd: "نهاية الفصل ",
      semaster: "الفصل",
      reference: "رقم مرجعي",
      parent_receivable: "تعيين كذمم أولياء أمور",
      parent_student: "هل تريد عرض فواتير الاخوة ايضا؟",
      only_student: "لا، هذا الطالب فقط",
      parents_ledger: "كشف حساب مختصر",
      // parents_report: "كشف حساب ولي الامر",
      parents_report: "تقرير التعاقدات",
      DetailedFinancialReport: "تقرير مالي مفصل",
      custodies_report: "كشف العهد",
      no_balance_message: "برجاء إدخال ارصدة أول المدة أولا",
      add_balance: "إدخال",
      addFile: "اضافه ملف",
      editFile: "تعديل ملف",
      files: "الملفات",
      system_settings: "اعداد النظام",
      male: "ذكر",
      female: "أنثي",
      current: "الحالي",
      notCurrent: "تحديد الحالي",
      pagination: {
        message: "عرض {{from}} الي {{to}} من اصل {{total}} مدخله",
      },
      restStudents: "باقي الابناء",
      nobiilsForStudent: "لا يوجد فواتير لهذا الطالب",
      noPaidBillsForStudent: "لا يوجد فواتير مدفوعه لهذا الطالب",
      close: "اغلاق",
      previousBalance: "رصيد سابق",
      totalRest: "مجموع الباقي",
      reset_from_premium: "الاقساط المتبقية",
      totapPaid: "مجموع المسدد",
      nickName: "الاسم المستعار",
      gender: "الجنس",
      custodyAccount: "حساب العهده",
      receipts_parents: "الصرف إلى أولياء الأمور",
      actions: "الإجراءات",
      addHouseImg: "رفع صوره كروكى للمنزل",
      location: "الاحداثيات",
      uploadd: "رفع",
      clear: "مسح البحث",
    },
    parent_pages: {
      identification_number: "رقم الهويه",
      its_source: "مصدرها",
      student: "الطالب",
      other_university: "جامعة اخرى",
      other_city: "مدينة اخرى",
      its_history: "تاريخها",
      appointment_of_guardian: "تعيين كولى الامر ",
      deceased: "متوفى",
      firstname_ar: "الاسم الاول بالعربى",
      firstname_en: "الاسم الاول بالانجليزى",
      the_father_ar: "اسم الاب بالعربى",
      the_father_en: "اسم الاب بالانجليزى",
      Grandpa_ar: "اسم الجد بالعربى",
      Grandpa_en: "اسم الجد بالانجليزى",
      family_ar: "اسم العائله بالعربى",
      family_en: "اسم العائله بالانجليزى",
      of_the_affiliates: "من المنسوبين ",
      Nationality: "الجنسيه",
      Country: "الدوله",
      countryPlace: "محل الاقامة",
      City: "المدينه",
      employer_ar: "جهة العمل بالعربي",
      employer_en: "جهة العمل بالإنجليزي",
      Job_title_ar: "المسمى الوظيفي باللغة العربية",
      Job_title_en: "المسمى الوظيفي باللغة الإنجليزية",
      Title_ar: "العنوان بالعربى",
      Title_en: "العنوان بالانجليزى",
      Postal_code: "الرمز البريدى",
      Home_phone: "هاتف المنزل",
      work_phone: "هاتف عمل",
      cell_phone: "الجوال",
      stop_dealing: "ايقاف التعامل",
      Black_list: "القائمة السوداء",
      general_note_ar: "ملاحظه عامه بالعربى",
      general_note_en: "ملاحظه عامه بالانجليزى",
      relative_relation: "صله القرابه",
      contract_number: "رقم العقد",
      date_of_registration: "تاريخ التسجيل",
      Student_status: "حاله الطالب من التسجيل",
      type: "النوع",
      section: "القسم",
      Enrollment_class: "فصل الالتحاق",
      class: "الصف",
      Semester: "الترم",
      school_situation: "الوضع الدراسى",
      Specialization: "التخصص",
      Student_condition: "حاله الطالب",
      contracts: "العقود",
      Tuition_expenses: "المصروفات الدراسيه",
      Subscribe: "الاشتراك فى الباص",
      coordinates: "الاحداثيات",
      genter: "النوع",
      AddNewChild: "اضافه ابن جديد",
      editExel: "رفع ملف اكسل",
      successCopy: "تم النسخ بنجاح",
      identityNum: "رقم الهوية",
      firstName: "الاسم الأول",
      lastName: "الاسم الأخير",
      fatherName: "اسم الأب",
      userName: "اسم المستخدم",
      password: "كلمة المرور	",
      phone: "رقم الجوال	",
      email: "البريد الالكترونى",
      birthdate: "تاريخ الميلاد",
      birthCountry: "مكان الولادة",
      nationality: "الجنسية",
      exelFile: "ملف اكسيل",
      back: "عوده",
      reset: "إعادة تعيين",
      parentFile: "اسم ولي الامر",
      fingerPrint: "بصمه الاصبع",
    },
    invoices: {
      costcenter: "مركز التكلفة",
      init_value: "اجمالي التعاقدات بدون ضريبة",
      init_value_tax: "قيمة ضريبة الفاتورة",
      init_value_w_tax: "اجمالي التعاقد شامل الضريبة",
      premium_value: "قيمة القسط",
      special_discount_value: "الخصم الخاص",
      general_discount_value: "الخصم العام",
      all_taxes_value: "قيمة الضريبة الكاملة + الجنسية",
      paid: "المدفوع",
      taxes_value: "قيمة الضرائب",
      total_discount: "مجموع الخصومات",
      total_general_discount: "مجموع الخصومات العامة",
      total_special_discount: "مجموع الخصومات الخاصة",
      value: "القيمة",
      class_room_title: "الصف الدراسي",
      invoices: "قيمة الفواتير",
      contacts_1: "التعاقدات المفعلة",
      contacts_2: "التعاقدات الغير مفعلة",
      active: {
        init_value: "اجمالي التعاقدات بدون ضريبة",
        init_value_tax: "قيمة ضريبة الفاتورة",
        init_value_w_tax: "اجمالي التعاقد شامل الضريبة",
        premium_value: "قيمة القسط",
        special_discount_value: "الخصم الخاص",
        general_discount_value: "الخصم العام",
        all_taxes_value: "قيمة الضريبة الكاملة + الجنسية",
        taxes_value: "قيمة الضرائب",

      },
      invoice: {
        init_value: "اجمالي الفواتير بدون ضريبة",
        init_value_tax: "قيمة ضريبة الفاتورة",
        init_value_w_tax: "اجمالي الفواتير شامل الضريبة",
        premium_value: "قيمة القسط",
        special_discount_value: "الخصم الخاص",
        general_discount_value: "الخصم العام",
        all_taxes_value: "قيمة الضريبة الكاملة + الجنسية",
        paid: "المدفوع",
        value: "القيمة",
        total_general_discount: "مجموع الخصومات العامة",
        total_special_discount: "مجموع الخصومات الخاصة",
        taxes_value: "قيمة الضرائب",
        total_discount: "مجموع الخصومات",

      },


      tax: "الضريبة",

    },
    movements: {
      date: "تاريخ السند",
      type: "نوع السند",
      fiscal_year_number: "رقم السند",
      total_received: "مبلغ السند",
      total_paid: "نصيب الفاتورة",
      payment_method_name: "طريقة السداد",
      action: "الحدث",
      parent_collection: "سند قبص",
      parent_receipt: "سند صرف",
      rest_invoices: {
        premium_value: "المبلغ",
        due_date_show: "التاريخ",
        name: "البيان",
      }
    },
    reportByClassroom: {
      contracts: {
        init_value: "اجمالي التعاقدات بدون ضريبة",
        tax: "الضريبة",
        general_discount_value: "الخصم العام",
        special_discount_value: "الخصم الخاص",
        init_value_w_tax: "اجمالي التعاقد شامل الضريبة",

      },
      invoice: {
        init_value: "اجمالي الفواتير بدون ضريبة",
        taxes_value: "الضريبة",
        value: "اجمالي الفواتير شامل الضريبة",
        sales_returns_sum: "مردودات المبيعات",

      },
      collection: {
        'not-paid': 'الفواتير الغير مسددة',
        'paid': 'الفواتير المسددة',
      },
      active: "تعاقدات مفعلة",
      inActive: "تعاقدات غير مفعلة",
      all: "جميع التعاقدات",
      costcenter: "مركز التكلفة",

      init_value: "اجمالي التعاقدات بدون ضريبة",
      tax: "الضريبة",
      general_discount_value: "الخصم العام",
      special_discount_value: "الخصم الخاص",
      init_value_w_tax: "اجمالي العاقدات شامل الضريبة",

      init_value_tax: "قيمة ضريبة الفاتورة",
      premium_value: "قيمة القسط",
      all_taxes_value: "قيمة الضريبة الكاملة + الجنسية",
      paid: "المدفوع",
      taxes_value: "قيمة الضرائب",
      total_discount: "مجموع الخصومات",
      total_general_discount: "مجموع الخصومات العامة",
      total_special_discount: "مجموع الخصومات الخاصة",
      value: "القيمة",
      class_room_title: "الصف الدراسي",
      invoices: "الفواتير",
      collections: "التحصيل",
      contacts_1: "التعاقدات المفعلة",
      contacts_3: "التعاقدات",
      contacts_2: "التعاقدات الغير مفعلة (الملغاة)",
    },

    contracts: {
      active: "تعاقدات مفعلة",
      onlinepaid: "دفع اونلاين",
      previewContract: "معاينة التعاقد",
      deletedContracts: "التعاقدات المحذوفة",
      sales_returns_enteries:"قيود مردودات المبيعات",
      sales_returns_update_enteries:"تحديث قيود مردودات المبيعات",
      inActive: "تعاقدات غير مفعلة",
      all: "جميع التعاقدات",
      nationality: "الجنسية",
      student_id: "الطالب",
      invoice: "فاتورة",
      date: "التاريخ",
      'invoice-payment': "دفع فاتورة",
      paymentMethods: "طريقة الدفع",
      updateContractTemplateSuccess: "تم تحديث العقد بنجاح",
      updateContractTemplate: " تحديث العقد ",
      hasContractStudent: "الطلاب الذين لديهم تعاقد",
      nohasContractStudent: "الطلاب الذين ليس لديهم تعاقد",
      allstudents: "جميع الطلاب",
      reportByClassroomReport: "تقرير الايرادات",
      editContract: "تعديل تعاقد",
      showContract: "عرض تعاقد",
      salesReturns: "مردودات المبيعات",
      addContract: "اضافه تعاقد",
      contractEndDate: "تاريخ نهايه العقد",
      contractStartDate: "تاريخ بدايه العقد",
      last_activity: "اخر نشاط",
      new_contract: "اضافه نموذج عقد",
      view_contract: "نماذج العقود",
      contracts: "التعاقدات",
      bills_enteries: "قيود الفواتير",
      bills_update_enteries: "تحديث قيود الفواتير ",
      bills_update_enteries_confirm: "هل انت متأكد من تحديث قيود الفواتير",
      update_contracts_confirm: "هل انت متأكد من تحديث  العقد",
      accept: "القبول والتسجيل",
      company_name: "اسم الشركه",
      standardized: "نموذج الاظهار الموحد",
      contract_party: "طرف العقد",
      classroom_title: "الرسوم الدراسيه المعتمده للمدرسه من وزاره التعليم",
      show: "اظهار",
      hidden: "اخفاء",
      contract_date: "أبرم هذا العقد بتاريخ",
      day: "الموافق",
      name_contract: "عنوان العقد",
      desc_contract: "وصف العقد",
      preamble: "التمهيد",
      sort: "الترتيب",
      item: "البند",
      new_item: "اضافه بند جديد",
      tax_number: "الرقم الضريبى",
      second_party: "الطرف الثانى ",
      its_meanings: "المعاينه",
      paragraph: "توليد عقد",
      both: "بين كلا منهم",
      represented: "ويمثلها بهذا العقد الاستاذ",
      adjective: "الصفه",
      departement: "القسم",
      shortcuts: "كلمات مختصره",
      bills: "فواتير",
      full_name: "اسم ولي الامر",
      billsName: "رقم الفاتوره",
      headerTitle: "نموذج الاظهار الموحد",
      dynamicTitle: "البند",
      conclusion: "الخاتمه",
      signature_two: "التوقيع الثانى",
      signature_one: "التوقيع الاول",
      contractNumber: "رقم العقد",
      studentName: "اسم الطالب",
      fatherName: "اسم الاب",
      semaster: "الصف",
      class: "الفصل",
      identification_number: "رقم الهوية",
      contractStatus: "حاله العقد",
      service: "الخدمه",
      contractPrice: "مبلغ العقد",
      item_of_number: "عدد البنود",
      tax_invoce: "فاتوره ضريبيه",
      "simple-tax_invoce": "فاتورة ضريبية مبسطة",
      bill_to: "فاتوره الى",
      bill_from: "فاتوره من",
      name: "اسم",
      address: "عنوان",
      vat_no: "رقم الضريبه",
      cr_no: "رقم السجل التجارى",
      invoice_no: "رقم قاتوره",
      invoice_date: "تاريخ فاتوره",
      invoice_create: "تم الانشاء بواسطه",
      service_pro: "تفاصيل السلعه او الخدمه",
      unit_price: " سعر الوحده",
      unit_price_en: "Unit price",
      quantity: "الكميه",
      amount: "مبلغ الخصم",
      taxable_amount: " المبلغ الخاضع للضريبه",
      tax_rate: "نسبه الضريبه",
      tax_sar: "( ضريبه ( الريال السعودى",
      total: "المجموع",
      sub_total: "اجمالى",
      discount: "الخصم",
      taotal_vat: "مبلغ الضريبه",
      addContractDate: "تاريخ العقد",
      studentFullName: "اسم الطالب كامل",
      contractName: "اسم العقد",
      cateogriesoffees: "فئات الرسوم",
      amountPaid: "مبلغ الفاتوره",
      amountRest: "المبلغ الباقي",
      totalContracts: "اجمالي التعاقدات",
      contarctDate: "تاريخ العقد",
      student: "طرف اول الطالب",
      company: "طرف ثاني الشركه",
      chooseContarct: "اختيار العقد",
      invoice_number: "رقم الفاتوره",
      invoice_status: "حاله الفاتوره",
      invoice_amount: "مبلغ الفاتوره",
      payment_status: "حاله السداد",
      payment_status_amont: "المبلغ المسدد",
      payment_status_rest: "المبلغ المتبقي",
      invoices: "فواتير",
      add_invoice: "اضافه فاتوره",
      update_all_invoices: "تحديث الفواتير",
      update_invoices: "تحديث الفاتورة",
      update_all_invoices_confirm: "هل انت متأكد من تحديث الفواتير ؟",
      update_invoices_confirm: "هل انت متأكد من تحديث الفاتورة ؟",
      bill_invoices: " الفواتير الخاصة بالعقد",
      download_invoice: "تحميل الفاتورة",
      download_contract: "تحميل العقد",
      due_date: "تاريخ الاضافة",
      sales_returns: "المردود",
      class_room: "الصف الدراسي",
      specialOffer: " الخصم الخاص",
      generalOffer: " الخصم العام",
      paid: "مسدد",
      Unpaid: "غير مسدد",
      pendingPaid: "ملغاة",
      return: "مرتجع",
      ParialPaid: " مسدد جزئي",
      noGeneralDiscount: "لا يوجد خصومات عامه",
      noSpecialDiscount: "لا يوجد خصومات خاصه",
      companyName: "الشركه",
      ContractstudentName: "الطالب",
      guardian: "ولي الامر",
    },
    prossing: {
      userSheets: "ملفات المستخدم",
      sheetName: "اسم الملف ",
      sheetType: "نوع الملف",
      sheetData: "بيانات الملف",
      declaration: "التصريح",
      login_check: "فحص تسجيل الدخول",
      other_services: "خدمات اخرى",
    },
    AUTH: {
      GENERAL: {
        LOGIN_BY: "تسجيل بواسطة",
        FACEBOOK: "فيسبوك",
        GOOGLE: "جوجل",
        logo: "فيوكلاس",
        welcomtointelegentLearning: "مرحبا بكم في التعلم الذكي",
        ourVesion: "رؤيتنا",
        ourVesiondetail:
          "أن نقدم نموذج يحتذى به في التعليم الإلكتروني يتسم بالبساطة والتفاعلية والتكامل يحبب الطلاب في التعليم",
        sure: "هل أنت متأكد؟",
      },
      LOGIN: {
        BUTTON: "تسجيل الدخول",
        USER: "مستخدم",
        ADMIN: "مدير نظام",
      },
      TWA: {
        HEADER: "تأمين حسابك",
        SUBTITLE: "مع التحقق من خلال خطوتي",
        "STEP-ONE":
          "قم بتحميل تطبيق اداه مصداقة جوجل google authtincator من الرابط ",
        "STEP-ONE-TITLE": "الخطوة الاولى:",
        "STEP-TWO-TITLE": "الخطوة الثانية:",
        "STEP-THREE-TITLE": "الخطوة الثالثة:",
        "CLICK-HERE": "(اضغط هنا)",
        "STEP-TWO": " افتح التطبيق",
        "STEP-THREE": "قم بتصوير الكود التالى من خلال تطبيق اداه مصادقة جوجل ",
        SKIP: "تخطى",
        enterCode: "الرجاء ادخال رمز النحقق",
      },
      INPUT: {
        PASSWORD: "كلمة المرور",
        name: "الاسم",
        USERNAME: "اسم المستخدم",
      },
    },
    common: {
      confirmDelete: "تاكيد الحذف",
      confirmUpdate: "تاكيد التحديث",
      confirmCahngeStatus: "تاكيد تغيير الحاله",
      deleteMessage: "هل انت موافق على حذف العنصر ؟",
      parent_collectionsdeleteMessage: "هل انت موافق على حذف القيد ؟",
      parent_collectionsrestoreMessage: "هل انت موافق على استعادة القيد ؟",
      deleteMessageParent: "لا يمكن حذف ولي الامر بسبب وجود ارتباطه بفواتير",
      deleteItemsMessage: "هل انت موافق على الحذف ؟",
      changeStatusMessage: "هل انت موافق على تغيير الحاله ؟",
      closeFiscalYearMessage: "هل انت موافق على اغلاق السنة المالية المختارة ؟",
      closeFiscalYear: " اغلاق السنة المالية ",
      closeFiscalYearSuccess: "تم اغلاق السنة المالية بنجاح",
      removeAssign: "هل انت موافق على ازلة التعيين ؟",
      yes: "نعم",
      no: "لا",
      fiscal_year_id: " السنة المالية  (لا بد من اختيار سنة مالية لاغلاق السنة المالية الحالية)",
      VERIFY: "تحقق",
      declarationMessage:
        "هل انت متاكد من إلغاء الارتباط ؟ ملحوظه : سيتم إلغاء الارتباط بين البريد الالكتروني الخاص بك وبين google drive",
      noSemesterFound: "لا يوجد فصول دراسية",
    },
    setting: {
      selected_rows_only: "تصدير السطور المختارة فقط",
      profile: "الملف التعريفي",
      users: "المستخدمين",
      stocks: "الارصدة الافتتاحية",
      banks: "الإعدادات البنوك",
      powers: "ادارة الصلاحيات",
      "fiscal-year": "السنة المالية",
      selectCountry: "الدولة",
      declaration: "التصريح",
      selectZone: "المنطقة",
      selectDates: "اختر الصيغة",
      selectLang: "اختر اللغة",
      selectRole: "اختر الوظيفة",
      motherCompanies: "اختر المؤسسة",
      openingbalances: "الأرصدة الافتتاحية",
      Permissionmanagement: "إدارة الصلاحيات",
      chartofaccounts: "دليل الحسابات",
      AppointmentGuardians: " تحليل ذمم اولياء الامور",
      DesignationGuardians: " تعين ذمم اولياء الامور",
      AppointmentGuardiansSet: "ذمم اولياء الامور",
      setCovenant: "تعيين العهد",
      setrevenues: "تحديد كايرادات",
      setexpenses: "تحديد كمصروفات",
      setassets: "تحديد الاصول",
      setliabilities: "تحديد الخصوم",
      setprofit_loss: "تحديد ارباح وخسائر",
      returns_account: "حساب المردودات",
      serialnumbers: "الأرقام التسلسليه",
      manualbonds: "السندات اليدوية",
      paymentmethods: "طرق السداد",
      typesofpremiums: "انواع الاقساط",
      typesoffees: "انواع الرسوم",
      cateogriesoffees: "فئات الرسوم",
      studymajors: "التخصصات الدراسية",
      relativerelation: "صلة القرابة",
      generaldiscounts: "الخصومات العامة",
      specialdiscounts: "الخصومات الخاصة",
      casestudies: "الحالات الدراسية",
      paymentterminal: "اجهزة السحب",
      Semester: "التحكم فى الترم الدراسي",
      save: "حفظ",
      saveLocathin: "حفظ الموقع",
      saveAll: "حفظ الكل",
      cancel: "إلغاء",
      basic_info: "البيانات الاساسية",
      show_account_card: "إظهار بطاقة الحساب",
      add_sub_account: "إضافة حساب فرعي",
      ledger: "دفتر الاستاذ",
      ledgerRead: "قراءه القيود غير المرحله",
      previousBalance: "الرصيد السابق",
      finalBalance: "الرصيد النهائي",
      total: "المجموع ",
      currency: "العمله",
      account_card: "بطاقة الحساب",
      sub_account: "إضافة حساب فرعي",
      level_two: "المستوي الثاني",
      level_three: "المستوي الثالث",
      settings: "الإعدادات",
      journal_entry: "سند قيد",
      delete: "حذف",
      security: "الحماية",
      entereducationManagerInfo: "رجاء ادخال بيانات مدير النظام التعليمي",
      usersPage: {
        active: "فعال",
        disactive: "غير فعال ",
        username: "اسم المستخدم",
        email: "البريد الالكترونى",
        actions: "الاجراءات",
        role: "الوظيفة",
        userDetails: "بيانات المستخدم",
        confirmationPassword: "تاكيد كلمة المرور",
        password: "كلمة المرور",
        name: "الاسم",
        nameAr: "الاسم عربي",
        nameEn: "الاسم انجليزي",
        chooseDate: "اختار تاريخ",
        changePassword: "تغيير كلمة المرور",
        errorPhone: "الرقم غير متوافق مع الدولة",
        passwordMinLength: "يجب ان يحتوي على الاقل على ثمانية حروف",
        passwordOneUpper: "يجب ان يحتوي على حرف واحد كبير على الاقل",
        passwordOneLower: "يجب ان يحتوي على حرف واحد ضغير على الاقل  ",
        passwordoneNumber: "يجب ان يحتوي على رقم واحد على الاقل ",
        passwordoneSymbol: "يجب ان يحتوي على رمز واحد على الاقل ",
        mustBeTheSame: "كلمه السر يجب ان تكون متشابهه ",
        userImage: "الصوره الشخصيه",
        currentPassword: "كلمه السر الحاليه",
        paymentMthodsPermissions: "صلاحيات طرق السداد",
        paymentMthods: "طرق السداد",
        accountGuidePermissions: "صلاحيات العهد",
        accountGuide: "العهد",
      },
      fiscalYearPage: {
        updateFiscalYear: "تعديل سنة مالية",
        addFiscalYear: "اضافة سنة مالية",
        name: "الاسم",
        start_at: "بداية السنة",
        start_m_at: "م",
        start_hijry_at: "هـ",
        end_at: "نهاية السنة ",
        details: "تفاصيل الحساب",
        active: "مفعل",
        disactive: "غير مفعل",
        isDefault: "التعيين كافتراضي",
        Default: "افتراضي",
        journalSequence: "التسلسل",
        monthly: "شهري",
        yearly: "سنوي",
        fiscalYearStatu:'حالة السنة المالية',
        fiscalYearStatus:{
          '0':'سنة مالية تعمل',
          '1':'سنة مالية مغلقة',
        }
      },
      currencies: {
        currencies: "العملات",
        addCurrency: "اضافه عمله",
        editCurrency: "تعديل عمله",
        sequence: "التسلسل",
        currencyname: "اسم العمله",
        currencysymbol: "رمز العمله",
        currencysymbolAr: "رمز العمله عربي",
        currencysymbolEn: "رمز العمله انجليزي",
        isDefault: "التعيين كافتراضي",
        Default: "افتراضي",
        active: "مفعل",
        disactive: "غير مفعل",
        Exchangerate: "سعر التحويل",
      },
      relativeRelation: {
        relative_relation: "صلة القرابة",
        updateRelation: "تعديل صلة القرابة",
        addRelation: "إضافة صلة قرابة",
      },
      taxes: {
        add_tax: "إضافة الضرائب",
        update_tax: "تعديل الضرائب",
        tax_name_ar: "اسم الضريبة (عربي)",
        tax_name_en: "اسم الضريبة (انجليزي)",
        tax_percentage: "نسبة الضريبة",
        linked_account: "الحساب المرتبط",
        debit: "دائن",
        credit: "مدين",
        percentage: "النسبة",
        tax_free_nationalities: "الجنسيات المعفاة من الضريبة",
        free_nationalities: "جنسيات معفاة",
        nationality: "الجنسية",
        account_details: "تفاصيل الحساب",
        tax_number: "الرقم الضريبي",
        restrictions: "القيود اليومية",
        statement: "البيان",
        account: "الحساب",
      },
      typesOfFees: {
        account_guid: "الحساب المربوط",
        allow_global_discount: "يخضع للخصم العام",
        allow_tax_exemption: "مستثني من الإعفاء الضريبي",
        cost_center: "( المدرسة ) مركز التكلفة",
        addFees: "إضافة نوع رسوم",
        updateFees: "تعديل نوع رسوم",
        start_at: "يبدا من",
        to: "الي",
      },
      organization: {
        Number: "رقم المؤسسة",
        Logo: "شعار المؤسسة",
        Name: "اسم المؤسسة",
        remove_logo: "ازالة الشعار",
        play: "تشغيل",
        stop: "ايقاف",
        details: {
          details: "يبانات المؤسسة",
          street: "شارع",
          city: "مدينة",
          mobile: "الجوال",
          fax: "الفاكس",
          email: "البريد الالكتروني",
          website: "الموقع الالكتروني",
        },
        Tax_Number: "الرقم الضريبي",
        special_number: "الرقم المميز",
        System_administrator: "مدير النظام",
        basis_of_the_report: "أساس التقدير",
        Monetary_basis: "الاساس النقدي",
        accrual_basis: "الاساس الاضافة",
        Time_zone: "المنطقة الزمنية",
        Date_Format: "صيغة التاريخ",
        journal_method: "ترحيل القيود علي اساس",
        combinedentryamount: "مبلغ مجمع",
        linelevelrestriction: "تقييد مستوى الخط",
      },
    
      permissions: {
        // تقارير الحضور
        prints:"طباعة",
        'device-prints':"طباعة اجهزة البصمة",
        staffsReport: "تقرير الموظفين",
        'online-payment-methods': "السداد الالكتروني",
        finincialReport: "التقرير المالي",
        salaryHistoryReport: "تقرير أرشيف الرواتب",
        staffReply: "رد الموظف",
        getTime: "وقت الحضور و الانصراف",
        getAbsenceListDetails: "تفاضيل احتساب قيمة الغياب",
        getListDetails: "تفاضيل احتساب قيمة الحضور والانصراف",
        connect_settings: "اعدادات البصمة",
        managerReply: "قرار المدير",
        reports_students: "جميع توقيعات اجهزة البصمة طلاب",
        reports_staffs: "جميع توقيعات اجهزة البصمة موظفين",
        addAll: "اضافة جميع الصلاحيات",
        copy: "نسخ",
        restore: "استعادة",
        invoice: "الفواتير",
        importExcel: "رفع ملف Excel",
        'whatsapp-settings': "اعدادات الواتس",
        'sms-settings': "اعدادات الرسائل",
        "general-ledgerlms": "ربط النظام التعليمي",
        'report_by_classroom': "تقرير الايرادات",
        'report': "التقارير",
        removeAll: "ازالة جميع الصلاحيات",
        attendsIndex: "الحضور والغياب من اجهزة البصمة",
        documentsIndex: "الكشف اليومي ( الحضور والانصراف والتأخيرر والغياب)",
        setUsersPrints: "ربط مع اجهزة البصمة",
        updateUsersPrintsExcel: "ربط مع اجهزة البصمة عن طريق ملف اكسل",
        usersPrintsIndex: "ربط مع اجهزة البصمة",
        groups: "الدوام",
        settings_students: "إعدادات الحضور والغياب الطالب",
        settings_staffs: "إعدادات الحضور والغياب الموظف",
        weekDays: "أيام الأسبوع",
        officialVacations: "الاجازات الرسمية",
        buildings: "الأبنية",
        floors: "الطوابق",
        copy_setting: "نسخ الاعدادات",
        devices: "الأجهزة",
        fiscal_years: "السنة المالية",
        database: "قاعده البيانات",
        actions: "التحكم",
        funcationalData_documents: "المستندات",
        nationality: "الجنسية",
        religion: "الديانة",
        socialStatus: "الحالة الاجتماعية",
        contractTerms: "بنود اضافية في العقد",
        certificates: "الشهادات",
        contractTypes: "نوع العقد",
        insuranceClasses: "فئة التأمينات الاجتماعية",
        jobTitles: "المسمى الوظيفي",
        specializations: "التخصص",
        contractCategories: "فئة العقود",
        sponsors: "سجلات الشركة ( الكفيل)",
        customReports: "التقارير المخصصة",
        showReport: "عرض التقرير",
        searchReport: "البحث",
        employeeStatuses: "حالة التوظيف",
        employeeSections: "الفئة الوظيفية",
        sections: "المرحلة/القسم",
        currentJobs: "الوظيفة الحالية",
        countries: "الدول",
        cities: "المدن",
        banks: "البنوك",
        universities: "الجامعات",
        "insurance-companies": "شركات التأمين",
        courses: "الدورات التدريبية / شهادات اخرى /المشاركة",
        contracts: "العقود",
        documents: "مستندات الشركة",
        inkindInventory: "العهد العينية",
        monthes: "التهيئة الزمنية",
        company_settings: "الاعدادات العامة",
        bank: "البنوك",
        "journal-print-settings": "اعدادات الطباعة",
        salary: "مسير الرواتب",
        'tax-report': "تقارير الضرائب",
        'show_report': "عرض التقرير",
        'print-settings': "اعددات الطباعة",
        // list
        'absence-list': "لائحة الغياب",
        'accreditation-stages': "مراحل الاعتماد",
        'additional-sanctions': "الجزاءات الاضافية",
        'advance-payments': "لوائح السلف",
        'advance-payments-accreditation': "لوائح اعتماد السلف",
        'benefits': "الاضافات",
        'company-unpaid-vacations': "الاجازات الغير مدفوعة",
        'company-vacations': "لائحة الاجازات",
        'company-yearly-vacations': "الاجازات السنوية",
        deductions: "الخصومات",
        'early-leave-list': "لائحة الانصراف المبكر",
        'employee-advance-payment': "السلف",
        'extra-time-list': "لائحة الوقت الاضافي",
        fingerprint: "لائحة البصمة",
        govPayments: "المصروفات الحكومية",
        'late-list': "لائحة التأخير",
        requestStages: "التأشيرات",
        sanctions: "لائحة الجزاءات",
        suspensionReasons: "اسباب الايقاف",
        // staff
        attendances: "حضور وانصراف موظف",
        'company-staff': "قائمة الموظفين",
        getExcelForUpdate: "تحميل ملف الموظفين بتنسيق اكسل",
        'employee-advance-payment-installments': "ضبط السلف ",
        'escorts': "المرافقين",
        'inqueries': "المساءلات",
        insurances: "التأمينات",
        qualifications: "المؤهلات الدراسية",
        salaryAdvances: "لائحة سلف الموظفين",
        staffs_benefits: "اضافات الموظفين",
        staffs_deductions: "خصومات الموظفين",
        staffs_documents: "مستندات الموظفين",
        staffs_sanctions: "جزائات الموظفين",
        vacations: "اجازات الموظفين",
        visas: "التأشيرات",
        'work-experiences': "الخبرات",
        permissions: "الصلاحيات",
        dashboard: "الداشبورد",
        updatePayStatus: "تعديل حالة الصرف",
        requestsIndex: "اعتماد السلف",
        show_one_profile: "بيانات النموذج",
        show_all: 'عرض الكل',
        updateStatus: 'تعديل الحالة',
        assignDirectManager: "احالة الطلب للمدير الاعلى",
        'visas-stages': "ادارة التأشيرات",
        'vacations-stages': "ادارة الاجازات",
        'escorts-stages': "ادارة المرافقين",
        updateSortOrder: "تغيير الترتيب",
        updateByExcel: "تحديث البيانات عن طريق ملف excel",
        stop: "ايقاف",
        uploadFiles: "رفع ملفات",
        requestFetchLmsUsers: "استيراد الموظفين",

        send_templates: "نماذج الارسال",
        employmentsOpIndex: "عمليات التوظيف",
        endContract: "إنهاء عقد",
        updateOpStatus: "تعديل عملية التوظيف ",
        assignStaff: "تعيين موظف",
        removeAssign: "ازالة التعيين",
        detailsIndex: "ملف البنك",
        payrollDetailsIndex: "تفاصيل المسير",
        getBankFileExcel: "تحميل ملف excel  للموظف",
        updateBankStatus: " تحديث ملف Excel ",
        payrollReport: "تقرير المسير",
        // create-beneficiary: "",
        // update-beneficiary: "",
        // delete-beneficiary: "",
        approve: "موافقة",
        deleteContract: "حذف عقد",
        rejecte: "رفض",
        'additional-index': "عرض",
        'additional-create': "اضافة",
        'additional-update': "تحديث",
        'additional-delete': "حذف",
        createCustomDeduction: "تخصيص خصم",
        createStaffContract: " اضافة عقد",
        createCustomBenefit: "تخصيص اضافة",
        deleteCustom: "حذف تخصيص خصم",
        deleteCustomContract: "حذف تخصيص اضافة",
        'sanctions-details': "تفاصيل الجزاء",
        // staffReply: "رد الموظف",
        // managerReply: "رد المدير",
        // ddd: "",
        // ddd: "",



        "parents-fathers": "اولياء الامور",
        "parents-students": "اولياء الامور-الابناء",
        attendance: "اعدادات البصمه",
        reports: "التقارير",
        processing: "المعالجة",
        hr: "الموارد البشرية",
        "trial-balance": "ميزان المراجعه",
        "guardians-edit": " تعيين ذمم اولياء الاموار",
        "custodies-edit": " تعيين العهد ",
        "guardians-index": "عرض ذمم اولياء الامور",
        "custodies-index": "عرض العهد",
        "parent-anylsis-edit": "تعديل تحليل الذمم",
        "parent-anylsis-view": "عرض تحليل الذمم",
        "jornal-index": "عرض القيود",
        "jornal-edit": "تعديل القيود",
        "jornal-entry-index": "عرض القيود",
        "jornal-entry-edit": "تعديل القيود",
        "ledger-sons": "عرض كشف حساب باقي الابناء",
        "add-receipt": "اضافه صرف عهده",
        "get-receipt": "عرض صرف عهده",
        "get-qualifier": "عرض التصفيات",
        "index-list": "عرض كل العهد",
        "receipt-entries": " تعديل قيود الصرف",
        "update-all-receipt-entries": "تحديث قيود الصرف",
        "index-qualifier": "عرض كل التصفيات",
        "qualifier-entries": " تعديل قيود التصفيه",
        "updste-qualifier-entries": "تحديث قيود التصفيه",
        "updste-all-qualifier-entries": "تحديث قيود جميع التصفيات",
        "qualifier-index": "فتح تاب اعتماد التصفيه",
        "qualifier- actions": "قبول او رفض التصفيه",
        "add-qualifier": "اضافه تصفيه",
        "edit-qualifier": "تعديل تصفيه",
        "invoices-show": "عرض الفواتير الخاصه بالعقد",
        show: "عرض التعاقد",
        download: "تحميل العقد",
        upload: "الرفع لارشيف الملفات",
        jobs: "قائمة الوظائف",
        "create-invoices": "اصدار فاتوره",
        invoiceentries: "تحديث قيود الفواتير",
        updateinvoice: "تحديث الفواتير",
        updateentries: "تحديث جميع قيود الفواتير",
        "financial-operations": "العمليات المالية",
        settings: "الإعدادات",
        "bank-reconcilations": "التسويات المصرفية",
        "collection-from-parents": "جمع من الوالدين",
        "contracts-management": "إدارة العقود",
        parents_files: "اولياء الامور",
        google: "جوجل",
        custodies: "تعيين العهد",
        "jornal-entry": "إفتتاحية المجلة",
        parents: "أولياء الأمور",
        "payment-vouchers": "قسائم الدفع",
        "receipt-vouchers": "قسائم الاستلام",
        "general-ledger": "دفتر الأستاذ العام",
        areas: "المناطق",
        branches: "الفروع",
        "case-studies": "الحالات الدراسيه",
        "cateogries-of-fees": "فئات الرسوم",
        "chart-of-accounts": "جدول الحسابات",
        "cost-center": "مركز التكلفة",
        "fiscal-year": "السنة المالية",
        "general-discounts": "خصومات عامة",
        "manual-bonds": "السندات اليدوية",
        "opening-balances": "أرصدة افتتاحية",
        "payment-methods": "طرق الدفع",
        "payment-terminal-(pos)": "محطة الدفع- (نقاط البيع)",
        profile: "الملف التعريفي",
        "relative-relation": "علاقة نسبية",
        "serial-numbers": "الأرقام التسلسلية",
        "special-discounts": "خصومات خاصة",
        "study-majors": "التخصصات الدراسية",
        taxes: "الضرائب",
        users: "المستخدمين",
        "validities-management": "صحة الإدارة",
        lms: "النظام التعليمي",
        account_guide: "دليل الحساب",
        "admin-management": "إدارة المستخدمين",
        "mother-companies": "الشركات",
        currencies: "العملات",
        "cost-centers": "مراكز التكلفة",
        "relative-relations": "صله القرابه",
        journal_modules: "القيود",
        opening_balances: "الأرصدة_الافتتاحية",
        "cost-center-regions": "المناطق",
        "cost-center-branches": "الفروع",
        premium_types: "أنواع_متميزة",
        payment_methods: "طرق_الدفع",
        fees_classes: "أنواع_الرسوم",
        fees_types: "فئات_الرسوم",
        classrooms: "الصفوف الدراسيه",
        semsters: "الفصول",
        "admin-roles": "أدوار المسؤول",
        "contract-templates": "نماذج العقود",
        invoices: "الفواتير",
        channels: "قنوات",
        operations: "عمليات",
        create: "اضافة",
        "create-card": "إنشاء بطاقة",
        "create-sub": "إنشاء حساب فرعي",
        guides: "خطوط إرشاد",
        index: "عرض",
        "parents-receivables": " تعين ذمم اولياء الامور",
        related: "ذات صلة",
        update: "تعديل",
        "update-item": "تحديث العنصر",
        find: "بحث",
        list: "قائمة",
        roles: "الأدوار",
        status: " تغير الحاله",
        store: "حفظ",
        default: "تعيين كافتراضي",
        "delete-image": "حذف صوره",
        "attendance-setting-get": "اعدادات الحضور والانصراف",
        "attendance-setting-set": "ضبط اعدادات الحضور",
        connect: "الاتصال",
        "import-parents": "استيراد أولياء الأمور",
        name: "الاسم",
        "get-number": "انشاء رقم جديد",
        journals: "القيود",
        calc: "حساب قيمه الضرائب",
        payments: "الدفع",
        "list-roles": "عرض المجموعات",
        "to-invoice": "تعيين لفاتوره",
        pdf: "تحمل المرفق",
        "run-invoices": "تحديث الفواتير",
        "find-by-id-number": "بحث بالرقم",
        "journal-entry-get": "عرض القيود",
        "journal-entry": "القيود",
        "invoice-journals": "قيود الفواتير",
        fathers: "أولياء الأمور",
        students: "الطلاب",
        files: "ملف العائله",
        image: "رفع صورة بمحل الاقامه",
        attachments: "المرفقات",
        "store-all": "حفظ الكل",
        "request-link": "طلب رابط",
        "remove-link": "حذف رابط",
        active: "تفعيل",
        "get-groups-tree": "الحصول على شجرة المجموعات",
        "get-sheets-names": "الحصول على أسماء الأوراق",
        "check-login": "تحقق من تسجيل الدخول",
        "re-check-login": "إعادة التحقق من تسجيل الدخول",
        "fix-error-600": "إصلاح الخطأ 600",
        "get-excel-sheets": "الحصول على أوراق إكسل",
        "get-excel-sheets-data": "الحصول على بيانات أوراق Excel",
        journal_entries: " القيود اليومية",
        payment_vouchers: "سندات القبض",
        receipt_vouchers: "سندات الصرف",
        parent_collections: "التحصيل من اولياء الامور",
        parent_receipts: "الصرف الي اولياء الامور",
        "store-receipt": "حفظ صرف عهده",
        "journal-receipt": "تحديث السندات",
        "re-generate-journals": "تحديث القيود",
        qualifier: "التصفيات",
        "update-excel": "تحديث ملفات الاكسل",
        "get-payment-balance": "الحصول على رصيد الدفع",
        ledger: "دفتر الاستاذ",
        "ledger-paginate": "ترقيم دفتر الأستاذ",
        "parents-analyses": "كشف حساب ولي الامر",
        "get-credit": "عرض الرصيد الدائن",
        "get-receipt-balance": "رصيد الصرف",
        "check-beginning-balance": "تحقق من رصيد البداية",
        filesattachments: "مرفقات ملف العائله",
        custodiesqualifier: "تصفيات العهد",
        financial_operations: "العمليات المالية",
        collections: "المجموعات",
        location: "تعديل محل الاقامه",
        attachments_add: "اضافه ارشيف ملفات",
        attachments_update: "تعديل ارشيف الملفات",
        attachments_index: "عرض ارشيف الملفات",
        attachments_delete: "حذف ارشيف الملفات",
        financial_reports: "التقارير الماليه",
        ledgers: "كشف حساب",
        "custody-index": "عرض كشف العهد",
        "parent-index": "عرض كشف ولي الامر",
        set: "تعيين",
        get: "عرض",
        add: "اضافه",
        edit: "تعديل",
        delete: "حذف",
        view: "عرض",
      },
      studentsYears: {
        addStudeyYear: "إضافة عام دراسي",
        address: "العنوان",
        addressEnglish: "العنوان بالانجليزي",
        semester: "الفصل الدراسي",
        semester1: "الفصل الدراسي الاول",
        semester2: "الفصل الدراسي الثاني",
        semester3: "الفصل الدراسي الثالث",
        startDateM: "تاريخ البدء بالميلادي",
        endDateM: " تاريخ الانتهاء بالميلادي",
        startDateHijri: "تاريخ البدء بالهجري",
        endDateHijri: "تاريخ الانتهاء بالهجري",
        transfareStudents: "ترحيل الطلاب لعام دراسي جديد",
        school: "مركز التكلفة",
        year: "السنة",
        studeySemester: "الفصل الدراسي",
        studeyClass: "الصف الدراسي",
        class: "الفصل",
        addNewLine: "إضافة سطر",
        deliver: "تسليم",
        lessonsSchdule: "جدول توزيع الدروس",
        lesson: "المادة",
        studyWeeks: "الاسابيع الدراسية",
        addStudeyWeeks: "إضافة أسبوع دراسي",
        editStudyWeeks: "تعديل أسبوع دراسي",
        deleteStudyWeeks: "حذف أسبوع دراسي",
        studetWeeksLessons: "جدول توزيع الدروس",
        studeyYears: "الاعوام الدراسيه",
        startAt: "يبدأ من",
        endAt: "ينتهي في",
        title: "العنوان",
        season: "الفصل الدراسي",
        delete: "حذف",
        addRow: "إضافة سطر",
        addLesson: "إضافة درس",
        Classseason: "الصف الدراسي",
      },
      attendanceSetting: {
        host: "الهوست",
        port: "البورت",
        database: "قاعده البيانات",
        attend_from: "الحضور من",
        attend_to: "الحضور الي",
        late: "وقت التأخير",
        header: "إعدادات البصمة ",
      },
    },
    students: {
      Withholding_grades: "حجب الدرجات",
      Withholding_view: "عرض الدرجات",
      license: "ترخيص",
      cancel: "الغاء الترخيص",
      back: "العوده",
      not_attached_parents: "الطلاب غير المرتبطين بأولياء الأمور",
      download: "تحميل Execl",
      filter: "تصفيه",
      spe_row: "تخصيص الأعمدة",
      import: "تصدير الى",
      copy: "اضغط على الصف لتقوم بنسخه",
      nor_system: "استيراد المستخدمين مباشرة من نظام نور",
      choose_excel_file: "يرجي اختيار ملف اكسل الذي تم تصديره من نظام نور ",
      nor_excel_file: "يرجى اختيار ملف نور بصيغة Excel",
      send: "ارسال",
      method_import: "شرح طريقة الاستيراد",
      profile_picture: "استيراد ملف الصور الشخصية",
      note: "ملاحظات",
      upload_rar: "رفع ملف مضغوط",
      intro: "المقدمه",
      acceptYear: "سنه القبول",
      grandFatherName: "اسم الجد",
      birthPlace: "مكان الميلاد",
      passbortNumber: "رقم جواز السفر",
      acadimicNum: "الرقم الأكاديمي",
      previousSchool: "مركز التكلفة السابقة",
      fingerPrint: "البصمة",
      firstNameEnglish: "الاسم الأول بالانجليزي",
      fatherNameEn: "اسم الأب بالانجليزي",
      grandFatherEn: "اسم الجد بالانجليزي",
      lastNameEn: "الاسم الأخير بالانجليزي",
    },
    bills: {
      whatsapp_send_paernt: "ارسال الفاتور لولي الامر ",
      statement_account_send_paernt: "ارسال كشف حساب لولي الامر ",
      paid_total: "اجمالي المدفوع",
      no: "الفاتورة رقم",
      remaining_installments: "الاقساط المتبقية",
      remaining_total: "اجمالي المتبقي",
      remaining_amount_bill: " المبلغ المتبقي من الفاتورة ",
      date: "تاريخ الفاتورة",
      desc: "البيان",
      father_name: "اسم ولى الامر",
      maturity_value: "قيمه الاضافة ",
      tax_category: "الفئه الضريبيه",
      tax: "الضريبيه",
      status: "الحالة",
      total: "الاجمالى",
      return: "مسترجعة",
      action: "التحكم",
      item_return: "استرجاع عنصر",
      item_return_confirm: "هل انت متاكد من ارجاع العنصر",
      bill_return_confirm: "هل انت متاكد من ارجاع الفاتورة",
      bill_return: "استرجاع الفاتورة",
      bill_return_selected: "استرجاع العناصر المحددة",
      rest: "الصافي",
      tax_before: "إجمالي المستحقات قبل الضريبة",
      total_tax: "إجمالي الضريبة",
      total_de: "الاجمالى المستحق",
      fees: "الرسوم",
      change_bill_time: "إصدار الفاتورة",
      contractISdeactive: "العقد غير مفعل",
      return_value: "ادخل القيمة المسترجعة",
      confirm_return: "استرجاع",
      cancel_return: "الغاء العملية",
      number: "يجب ان يكون رقم",
      return_number: "رقم الارجاع",
      maxvalu: "يجب ان تكون القيمة اصغر او تساوي",
      positive: "يجب ان تكون القيمة موجبة",
      return_value_item: "القيمة المسترجعة",
    },
    custodies: {
      custodies: "العهد",
      custodiesPay: "صــرف العهــدة",
      custodiesClose: "تصــفية عهــدة",
      journalReceipt: "قيود التصفيه",
      custodiesApprove: "اعتمـاد التصــفية ",
      custodiesRequest: " طــلب عهــدة",
      custodiesSatate: " حــالة العهــدة",
      custodiesSatateNew: "لم يتم الصرف",
      custodiesSatatePaid: "تم الصرف",
      custodyReqNumber: "رقم الطلب",
      custodyNumber: "رقم العهدة",
      custPayFrom: "الصــرف من",
      custPayTo: "الصــرف الى",
      custAmount: "مبلغ العهدة",
      custDescription: "البيـــان",
      custNew: "لم يتم الصرف",
      custClose: "تصــفية",
      custPending: "لم يتم التصــفية",
      custNumber: "رقــم الطـلب",
      custCloseNumber: "رقــم التصـفية",
      custDetail: "تفاصيل",
      custPay: "صــرف",
      custApproveBtn: "اعتماد",
      custRefuseBtn: "رفض",
      custApproved: "معتمدة",
      custRefused: "تم الرفض",
      custNumberArr: "رقم العهدة",
      filteringNNumber: "رقم التصفية",
      filtringCompleate: "تم التصفية",
      theRest: "المتبقي",
      restAmount: "المبلغ المتبقي",
      requestAmount: " المبلغ المطلوب",
      paidAmount: " المبلغ المصروف",
      qualifierAmount: " المبلغ المصفي",
      custodyClose: "قيود تصفيه العهد",
      custodyCloseRefresh: "تحديث قيود التصفيه",
      custodyPaidRefresh: "تحديث قيود الصرف",
      custodyCloseBtn: "قيود التصفيه",
      refuseReason: "سبب الرفض",
      custodyCloseRefreshAll: "تحديث جميع قيود التصفيات",
      custodyPayRefreshAll: "تحديث جميع قيود الصروفات",
    },
    collectionForms: {
      fees_types: "نوع التعاقد",
      "collections-recived": "المستلم",
      "receipts-recived": "المسترد",
      "collections-amount": "المبلغ المستلم",
      "receipts-amount": "المبلغ المسترد",
      amount: "المبلغ",
      billamount: "مبلغ الفاتورة",
      paid: "المسدد",
      reset: "الباقي",
      parent: "ولي الأمر",
      actions: "الإجراءات",
    },
    trialBalance: {
      trialbalance: "ميزان مراجعه",
      trialBalanceDetails: "ميزان المراجعه التفصيلي",
      balance: "ميزان",
      trialDetails: "مستويات ميزان المراجعه",
      devices: "أجهزه البصمه",
      deviceName: "اسم الجهاز",
      connect: "الاتصال",
      serialNumber: "الرقم التسلسلي",
      Ip: "Ip",
      serial_number: "الرقم التسلسلي",
      Port: "Port",
      modelName: "اسم الموديل",
      deviceTime: "توقيت الجهاز",
      deviceBuilding: "المبني",
      deviceFloor: "الدور",
      deviceUserCount: "اجمالي المستخدمين",
      controles: "التحكم",
      connectToWebsite: "الربط بالموقع",
      addDevice: "اضافه جهاز",
      editDevice: "تعديل جهاز",
      connectDevice: "اتصال",
      removeDevice: "حذف جهاز",
      testConnectionStatus: "فحص حاله الاتصال",
      disConnect: "قصع الاتصال",
      timeSetting: "ضبط الوقت",
      builddings: "المباني",
      addBuilding: "اضافه مبني",
      editBuilding: "تعديل مبني",
      showBuilding: "عرض مبني",
      buidingName: "اسم المبني",
      floorCount: "عدد الادوار",
      addFloorToBuildnig: "تخصيص الادوار",
      connectToFingerDevice: "ربط مع جهاز بصمه",
      buildigsFloors: "الادوار",
      addFloor: "اضافه دور",
      editFloor: "تعديل دور",
      showFloor: "عرض دور",
      floorName: "اسم الدور",
      group: "مجمع",
      Thebalancefirstduration: "رصيد اول المدة",
      aggregatebalance: "ميزان المجاميع",
      balancebalance: "ميزان الارصدة",
      movement: "الحركة",
      exportAs: "تصدير كملف",
    },
    taxesReports: {
      taxesReports: "تقرير الضريبه",
      taxesReturn: "اقرار ضريبي",
      showStudents: "عرض الطلاب المعفين",
      invoiceNum: " رقم الفاتوره",
      value: "المبلغ",
      totalTax: "اجمالي الضريبه",
      total: "المجموع",
    },
    messages: {
      fillRequired: "الرجاء إدخال كافه البيانات",
      pleaseEnterValue: "الرجاء إدخال المبلغ",
      pleaseEnterPercentage: "الرجاء إدخال النسبة المئوية",
      donnotrebeateStudent: "لا يمكن تكرار الطالب اكثر من مره",
      enterParentsGuardians: "برجاء إدخال ذمم أولياء الأمور أولا",
      Balancedoesnotmatch: " الرصيد غير مطابق يجب مطابقه الرصيد حيث تم تعديله",
      cannotSelectMoreThanClassRoom:
        "لا يمكن اختيار نفس الصف الدراسي اكثر من مره ",
      theValueIsNegativeValue: "يجب أن تكون القيمة المدخلة رقمًا موجبًا",
      taxesFieldRequired: "حقل الضرائب مطلوب",
      saveSuccessfully: "تم الحفظ بنجاح",
      sumInstallmentsNotEqualDistributable:
        "مجموع الأقساط لا يساوى القابل للتوزيع",
      pleaseChooseDevice: "الرجاء اختيار جهاز",
      canNotEdit: "لا يمكنك التعديل حيث انه تم الصرف",
      noDataToShow: "لا يوجد بيانات لعرضها",
      selectValidAccount: "الرجاء اختيار حساب صحيح",
      noStudentsWithThisIds: "لا يوجد طلاب بارقام هويه مماثله للقيمه المدخله",
      studentIdNumberNotInclude: "لا يوجد طالب برقم هويه {{idNumber}}",
    },
    AG_GRID_LOCALE: {
      // Set Filter
      selectAll: "(اختيار الكل)",
      selectAllSearchResults: "(حدد كل نتائج البحث)",
      searchOoo: "بحث ... ",
      blanks: "(الفراغات)",
      noMatches: "No matches",

      // Number Filter & Text Filter
      filterOoo: "Filter...",
      equals: "يساوي",
      notEqual: "لا يساوي",
      blank: "فارغ",
      notBlank: "ليس فارغ",
      empty: "Choose One",

      // Number Filter
      lessThan: "Less than",
      greaterThan: "Greater than",
      lessThanOrEqual: "Less than or equal",
      greaterThanOrEqual: "Greater than or equal",
      inRange: "In range",
      inRangeStart: "from",
      inRangeEnd: "to",

      // Text Filter
      contains: "Contains",
      notContains: "Not contains",
      startsWith: "Starts with",
      endsWith: "Ends with",

      // Date Filter
      dateFormatOoo: "yyyy-mm-dd",

      // Filter Conditions
      andCondition: "AND",
      orCondition: "OR",

      // Filter Buttons
      applyFilter: "Apply",
      resetFilter: "Reset",
      clearFilter: "Clear",
      cancelFilter: "Cancel",

      // Filter Titles
      textFilter: "Text Filter",
      numberFilter: "Number Filter",
      dateFilter: "Date Filter",
      setFilter: "Set Filter",

      // Side Bar
      columns: "Columns",
      filters: "Filters",

      // columns tool panel
      pivotMode: "Pivot Mode",
      groups: "Row Groups",
      rowGroupColumnsEmptyMessage: "اسحب هنا لتعيين مجموعات الصفوف",
      values: "Values",
      valueColumnsEmptyMessage: "Drag here to aggregate",
      pivots: "Column Labels",
      pivotColumnsEmptyMessage: "Drag here to set column labels",

      // Header of the Default Group Column
      group: "Group",

      // Row Drag
      rowDragRows: "rows",

      // Other
      loadingOoo: " جار التحميل ...",
      noRowsToShow: "لايوجد نتائج لعرضها",
      enabled: "Enabled",

      // Menu
      pinColumn: "Pin Column",
      pinLeft: "Pin Left",
      pinRight: "Pin Right",
      noPin: "No Pin",
      valueAggregation: "Value Aggregation",
      autosizeThiscolumn: "Autosize This Column",
      autosizeAllColumns: "Autosize All Columns",
      groupBy: "Group by",
      ungroupBy: "Un-Group by",
      addToValues: "Add ${variable} to values",
      removeFromValues: "Remove ${variable} from values",
      addToLabels: "Add ${variable} to labels",
      removeFromLabels: "Remove ${variable} from labels",
      resetColumns: "Reset Columns",
      expandAll: "Expand All",
      collapseAll: "Close All",
      copy: "Copy",
      ctrlC: "Ctrl+C",
      copyWithHeaders: "Copy With Headers",
      copyWithHeaderGroups: "Copy With Header Groups",
      paste: "Paste",
      ctrlV: "Ctrl+V",
      export: "Export",
      csvExport: "CSV Export",
      excelExport: "Excel Export",

      // Enterprise Menu Aggregation and Status Bar
      sum: "Sum",
      min: "Min",
      max: "Max",
      none: "None",
      count: "Count",
      avg: "Average",
      filteredRows: "Filtered",
      selectedRows: "Selected",
      totalRows: "Total Rows",
      totalAndFilteredRows: "Rows",
      more: "More",
      to: "to",
      of: "of",
      page: "Page",
      nextPage: "Next Page",
      lastPage: "Last Page",
      firstPage: "First Page",
      previousPage: "Previous Page",

      // Pivoting
      pivotColumnGroupTotals: "Total",

      // Enterprise Menu (Charts)
      pivotChartAndPivotMode: "Pivot Chart & Pivot Mode",
      pivotChart: "Pivot Chart",
      chartRange: "Chart Range",

      columnChart: "Column",
      groupedColumn: "Grouped",
      stackedColumn: "Stacked",
      normalizedColumn: "100% Stacked",

      barChart: "Bar",
      groupedBar: "Grouped",
      stackedBar: "Stacked",
      normalizedBar: "100% Stacked",

      pieChart: "Pie",
      pie: "Pie",
      doughnut: "Doughnut",

      line: "Line",

      xyChart: "X Y (Scatter)",
      scatter: "Scatter",
      bubble: "Bubble",

      areaChart: "Area",
      area: "Area",
      stackedArea: "Stacked",
      normalizedArea: "100% Stacked",

      histogramChart: "Histogram",

      combinationChart: "Combination",
      columnLineCombo: "Column & Line",
      AreaColumnCombo: "Area & Column",

      // Charts
      pivotChartTitle: "Pivot Chart",
      rangeChartTitle: "Range Chart",
      settings: "Settings",
      data: "Data",
      format: "Format",
      categories: "Categories",
      defaultCategory: "(None)",
      series: "Series",
      xyValues: "X Y Values",
      paired: "Paired Mode",
      axis: "Axis",
      navigator: "Navigator",
      color: "Color",
      thickness: "Thickness",
      xType: "X Type",
      automatic: "Automatic",
      category: "Category",
      number: "Number",
      time: "Time",
      xRotation: "X Rotation",
      yRotation: "Y Rotation",
      ticks: "Ticks",
      width: "Width",
      height: "Height",
      length: "Length",
      padding: "Padding",
      spacing: "Spacing",
      chart: "Chart",
      title: "Title",
      titlePlaceholder: "Chart title - double click to edit",
      background: "Background",
      font: "Font",
      top: "Top",
      right: "Right",
      bottom: "Bottom",
      left: "Left",
      labels: "Labels",
      size: "Size",
      minSize: "Minimum Size",
      maxSize: "Maximum Size",
      legend: "Legend",
      position: "Position",
      markerSize: "Marker Size",
      markerStroke: "Marker Stroke",
      markerPadding: "Marker Padding",
      itemSpacing: "Item Spacing",
      itemPaddingX: "Item Padding X",
      itemPaddingY: "Item Padding Y",
      layoutHorizontalSpacing: "Horizontal Spacing",
      layoutVerticalSpacing: "Vertical Spacing",
      strokeWidth: "Stroke Width",
      offset: "Offset",
      offsets: "Offsets",
      tooltips: "Tooltips",
      callout: "Callout",
      markers: "Markers",
      shadow: "Shadow",
      blur: "Blur",
      xOffset: "X Offset",
      yOffset: "Y Offset",
      lineWidth: "Line Width",
      normal: "Normal",
      bold: "Bold",
      italic: "Italic",
      boldItalic: "Bold Italic",
      predefined: "Predefined",
      fillOpacity: "Fill Opacity",
      strokeOpacity: "Line Opacity",
      histogramBinCount: "Bin count",
      columnGroup: "Column",
      barGroup: "Bar",
      pieGroup: "Pie",
      lineGroup: "Line",
      scatterGroup: "X Y (Scatter)",
      areaGroup: "Area",
      histogramGroup: "Histogram",
      combinationGroup: "Combination",
      groupedColumnTooltip: "Grouped",
      stackedColumnTooltip: "Stacked",
      normalizedColumnTooltip: "100% Stacked",
      groupedBarTooltip: "Grouped",
      stackedBarTooltip: "Stacked",
      normalizedBarTooltip: "100% Stacked",
      pieTooltip: "Pie",
      doughnutTooltip: "Doughnut",
      lineTooltip: "Line",
      groupedAreaTooltip: "Area",
      stackedAreaTooltip: "Stacked",
      normalizedAreaTooltip: "100% Stacked",
      scatterTooltip: "Scatter",
      bubbleTooltip: "Bubble",
      histogramTooltip: "Histogram",
      columnLineComboTooltip: "Column & Line",
      areaColumnComboTooltip: "Area & Column",
      customComboTooltip: "Custom Combination",
      noDataToChart: "No data available to be charted.",
      pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
      chartSettingsToolbarTooltip: "Menu",
      chartLinkToolbarTooltip: "Linked to Grid",
      chartUnlinkToolbarTooltip: "Unlinked from Grid",
      chartDownloadToolbarTooltip: "Download Chart",
      seriesChartType: "Series Chart Type",
      seriesType: "Series Type",
      secondaryAxis: "Secondary Axis",

      // ARIA
      ariaHidden: "hidden",
      ariaVisible: "visible",
      ariaChecked: "checked",
      ariaUnchecked: "unchecked",
      ariaIndeterminate: "indeterminate",
      ariaDefaultListName: "List",
      ariaColumnSelectAll: "Toggle Select All Columns",
      ariaInputEditor: "Input Editor",
      ariaDateFilterInput: "Date Filter Input",
      ariaFilterList: "Filter List",
      ariaFilterInput: "Filter Input",
      ariaFilterColumnsInput: "Filter Columns Input",
      ariaFilterValue: "Filter Value",
      ariaFilterFromValue: "Filter from value",
      ariaFilterToValue: "Filter to value",
      ariaFilteringOperator: "Filtering Operator",
      ariaColumn: "Column",
      ariaColumnList: "Column List",
      ariaColumnGroup: "Column Group",
      ariaRowSelect: "Press SPACE to select this row",
      ariaRowDeselect: "Press SPACE to deselect this row",
      ariaRowToggleSelection: "Press Space to toggle row selection",
      ariaRowSelectAll: "Press Space to toggle all rows selection",
      ariaToggleVisibility: "Press SPACE to toggle visibility",
      ariaSearch: "Search",
      ariaSearchFilterValues: "Search filter values",

      ariaRowGroupDropZonePanelLabel: "Row Groups",
      ariaValuesDropZonePanelLabel: "Values",
      ariaPivotDropZonePanelLabel: "Column Labels",
      ariaDropZoneColumnComponentDescription: "Press DELETE to remove",
      ariaDropZoneColumnValueItemDescription:
        "Press ENTER to change the aggregation type",

      // ARIA Labels for Dialogs
      ariaLabelColumnMenu: "Column Menu",
      ariaLabelCellEditor: "Cell Editor",
      ariaLabelDialog: "Dialog",
      ariaLabelSelectField: "Select Field",
      ariaLabelTooltip: "Tooltip",
      ariaLabelContextMenu: "Context Menu",
      ariaLabelSubMenu: "SubMenu",
      ariaLabelAggregationFunction: "Aggregation Function",

      // Number Format (Status Bar, Pagination Panel)
      thousandSeparator: ",",
      decimalSeparator: ".",
    },
  },
};
