// USA
export const locale = {
  lang: "en",
  data: {
    loading: "Loading",
    MENU: {
      language: "English",
      ar_language: "Arabic",
      en_language: "English",
      noActiveFiscalYears: "There are no active fiscal years",
      noActiveCompanies: "There are no active companies",
      noActiveCostCenters: "There are no active cost centers",
      noActiveRoles: "There are no active roles"
    },
    general: {
      addParents : 'Add Parent',
      editParents : 'Edit Parent',
      copySetting : "Copy settings",
      show : "show",
      print: "print",
      accessDenied : "You don't have permission to open this page",
      companyName : "Company",
      title: "Title",
      guardian: "Parent",
      error: "Error",
      uploadFile: "Upload file",
      insertFatherData: "Choose Student",
      firstState: "Balances of the first duration",
      restAmount: "Remaining amount",
      group_domain: "Group domain",
      identity_number: "ID number",
      entery_number: "Entry number",
      name_ar: "Arabic name",
      name_en: "English name",
      nick_name_en: "English screen name",
      nick_name_ar: "Arabic screen name",
      event: "Procedures",
      channel_name: "Channel name",
      bank_name: "Bank name",
      bank: "Bank",
      link_status: "Contact status",
      connected: "Connected",
      not_connected: "Not connected",
      status: "Status",
      classRoom: "Classroom",
      updated_at: "Last update",
      bank_fees_percentage: "Fee rate",
      bank_fees_percentage_account: "Fee rate calculator",
      bank_fees_tax_percentage: "Bank fee tax rate",
      bank_fees_tax_percentage_account: "Tax type",
      bank_account: "Bank account",
      bank_account_guide: "Bank charge account",
      parents: "Parents",
      btn_export: "Importing from parents",
      action: "Procedures",
      message: "Status changed successfully",
      active: "Active",
      inactive: "Inactive",
      file_name: "File name",
      download: "Download",
      addNewStudent: "Add student",
      addNewParent: "Add parent",
      moreOption: "Other options",
      processing: "Operations",
      numAcademy: "Academic number",
      idNumber: "ID number",
      fingerPrint: "Fingerprint",
      passport: "Passport",
      dateOfBirth: "Date of birth",
      placeOfBirth: "Place of birth",
      joining_date: "Joining date",
      year_of_acceptance: "Year of acceptance",
      fatherIDnumber: "Father' ID number",
      motherIDnumber: "Mother' ID number",
      former_teacher: "Previous school",
      motherFullName: "Mother' full name",
      name_student: "Students names",
      numOfParents: "Number of parents",
      enter_code: "Enter the code shown in the image",
      studentCount: "Number of students",
      students: "Students",
      student_update: "Update student information",
      import_excel: "Import from excel file",
      upload_image: "Import student photos",
      add_student: "Student addition form",
      addStudent: "Add student",
      editStudent: "Edit student",
      import_users: "Import from Nour file",
      import_user: "Direct import from Nour",
      educational_level: "Educational level",
      view_student: "View graduate students",
      payment_mothed: "Payment method",
      discount_percentage: "Discount percentage",
      list_of_student: "List of applicants",
      setting_students: "Student numbers",
      parent_of_student: "Students who are not attached to parents",
      search_of_school: "Search all schools",
      discount_value: "Discount value",
      class_room: "Classroom",
      daily_restrictions: "Daily entries",
      installments: "Installments",
      taxes_included: "Taxes included",
      specialdiscounts: "Special discounts",
      restrictionsType: "Entry type",
      discount: "Discount",
      amount: "Amount",
      fees: "Fees",
      installment_amount: "Installment amount",
      due_date: "Installment date",
      installment_name: "Installment name",
      can_be_distributed: "Distributable",
      addUser: "Add user",
      updateUser: "Edit user",
      Areas: "Areas",
      Add_Area: "Add area",
      edit_Area: "Edit area",
      Area_Name: "Area name",
      Branches: "Branches",
      Add_Branch: "Add branch",
      Branch_Name: "Branch name",
      Cost_Center: "Cost centers",
      Add_Cost_Center: "Add cost center",
      edit_Cost_Center: "Edit cost center",
      Cost_Center_Name: "Cost center name",
      taxes: "Tax",
      taxes_title: "Taxes",
      Edit_Branch: "Modify branch",
      add: "Add",
      deleteAll: "Delete all",
      edit: "Edit",
      image: "Photo",
      phone: "Phone number",
      Welcome_back: "Welcome back ",
      Login_to_your_account: "Login to your account",
      OR: "Or",
      language: "Language",
      fiscal_year: "Fiscal year",
      Account_settings: "General accounts",
      costcenterbranchescount: "Number of branches",
      costcenterscount: "Number of cost centers",
      costCenter: "Cost Center",
      PLEASE_WAIT: "Please wait",
      Users: "Users",
      View_All: "View all",
      Set_All_As_Read: "Set all as read",
      Unread_Message: "Unread message",
      Select_Language: "Please select a language",
      logout: "Logout",
      search: "Search",
      required: "Required",
      area: "Area",
      tax: "Taxes",
      journal_entries: "Daily entries",
      percentage_sign: "%",
      select_tax: "Please select tax",
      error_massage: "Please enter the amount and tax rate",
      personal_details: "Personal data",
      name: "Name",
      email: "E-mail",
      company: "Company",
      role: "Role",
      roles_permissions: "Roles and authorities",
      alerts: "Alerts",
      confirm: "Verify",
      telegram: "Telegram",
      account_activated: "Account activated",
      cancel_edit: "Cancel",
      enable_edit: "Edit",
      telegram_message: "Click on the following link to verify your account and enter the verification number",
      confirmation_link: "Verification link",
      enable_alerts: "Account login alert",
      enable_alerts_login: "Account login alert",
      addRole: "Add group",
      credit: "Creditor",
      debit: "Debtor",
      account: "Account",
      statement: "Statement",
      not_found: "There are no data",
      date: "Date",
      currency: "Currency",
      entry_source: "Entry source",
      transfer_price: "Exchange rate",
      entry_type: "Entry type",
      original: "Origin",
      attachments: "Attachments",
      total: "Total",
      difference: "Difference",
      save: "Save",
      new: "New",
      preview: "Preview",
      cost_center: "Cost center",
      branch: "Branch",
      no_attachments: "There are no attachments for this entry.",
      attachment: "Attachment",
      personalImage: "Personal photo",
      dashboard: "Dashboard",
      recent_updates: "Recent updates",
      salaries_and_wages: "Salaries and Wages",
      rentals: "Rentals",
      government_expenses: "Government expenses",
      other_expenses: "Other expenses",
      technical_support: "Technical Support",
      update_tax: "Tax update",
      total_unpaid_contracts: "Total unpaid contracts",
      overdue: "Late Payment",
      total_debts: "Parents' total debts",
      specialize_columns: "Customize columns",
      sort: "Order",
      father: "Father",
      mother: "Mother",
      relatives: "Relatives",
      children: "Children",
      living: "Residence",
      archive_file: " Archives",
      tag: "Analysis",
      no_original: "No entery",
      mother_name: "Mother's name",
      father_name: "Father's name",
      relative_name: "Relative's name",
      childern: "Childerns",
      upload: "Add attachment",
      receipt_vouchers: "Receipt vouchers",
      payment_vouchers: "Payment vouchers",
      receipt_new_vouchers: "Payment vouchers",
      payment_new_vouchers: "Receipt vouchers",
      previous_balance: "Previous balance",
      tax_included: "Taxes included",
      no_taxes: "Not subject to tax",
      tax_not_included: "Tax not included",
      entry: "Entry",
      manual_entry: "Manual entry",
      entry_number: "Entry number #",
      entryNumber: "Entry number",
      from: "From",
      to: "To",
      soon: "Soon",
      update_semester: "Semester adjustment",
      add_semester: "Add Semester",
      opening_balance: "Opening balances",
      dd_account: "Add account",
      update_account: "Update account",
      account_name: "Account name",
      account_code: "Account code",
      main_account: "Main account",
      warning: "Warning",
      none: "Without",
      budget: "Budget",
      notes: "Notes",
      showLedger: "Preview",
      showLedgerDetails: "Ledger review",
      startData: "Date from",
      endData: "Date to",
      Balanceforeachmove: "Balance for each move",
      movementType: "Move type",
      balance: "Balance",
      branch_or_facility: "Branch or facility:",
      contract_start_char_branch: "The beginning letter of the branch or institution",
      contracts_start_char_contract: "Contract start letter",
      contracts_start_char_parent_receipt: "Parents' Initial Disbursement letter",
      contracts_contract_number_count: "Number of contract numbers",
      serial_number_contracts: "Contract serial numbers",
      serial_number_receipt: "Serial numbers of receipt vouchers",
      serial_number_parent_payment: "Serial numbers for disbursement of parents",
      serial_number_parent_collection: "Serial numbers for collection from parents",
      serial_number_payment: "Serial numbers of the payment vouchers",
      serial_number_invoices: "Invoice serial numbers",
      receipt_start_char_contract: "Receipt voucher beginning letter",
      receipt_number_count: "Numbers of receipt vouchers",
      parent_collection_number_count: "Number of collection numbers",
      payment_start_char_branch: "The beginning letter of the branch or institution",
      payment_start_char_contract: "Payment voucher beginning letter",
      invoice_start_char_contract: "Invoice start letter",
      invoice_start_char_parent_collection: "Collection start letter",
      payment_number_count: "Numbers of payment vouchers",
      invoice_number_count: "Numbers of invoice numbers",
      invoice_number_count_parent_payment: "Numbers of parents' payment numbers",
      startWithNumber: "Starting from number",
      home: "Home",
      student: "Student",
      date_start: "Beginning of year",
      date_end: "End of year",
      addContract: "Add contract",
      next: "Next",
      google_authentication: "Google authenticator",
      google_message: "To increase protection, it is recommended to use this service",
      text_authenticator: "SMS Verification",
      email_verification: "Email Verification",
      devices_management: "Devices Management",
      devices_message: "You can log out of other connected devices",
      management: "Administration",
      password: "Password",
      password_message: "You can change the system login password",
      change: "Change",
      activity_message: "Last login",
      activity_record: "Activity log",
      update: "Update",
      connectWithTree: "Connect",
      collections_parents: "Collection from parents",
      semasterStart: "Semester start",
      semasterEnd: "Semester end",
      semaster: "Semester",
      reference: "Reference number",
      parent_receivable: "Appointment as parents' receivables",
      parent_student: "Do you want to view brothers invoices also?",
      only_student: "No, this student only",
      parents_ledger: "Short statment account",
      parents_report: "Parent account statement",
      DetailedFinancialReport: " Detailed Financial Report",

      no_balance_message: "Please enter opening balances first",
      add_balance: "Enter",
      addFile: "Add file",
      editFile: "Edit file",
      files: "Files",
      system_settings: "System setting",
      male: "Male",
      female: "Female",
      current: "Current",
      notCurrent: "Set as current",
      pagination: {
        message: "Show {{from}} to {{to}} out of {{total}} his entry"
      },
      restStudents: "Rest of the sons",
      nobiilsForStudent: "There are no invoices for this student",
      noPaidBillsForStudent: "There are no invoices for this student",
      close: "Close",
      previousBalance: "Previous balance",
      totalRest: "Sum of remaining",
      nickName: "Screen name",
      gender: "Gender",
      custodyAccount : 'Custody account',
      'payment-receipts' : "Payment fathers",
      receipts_parents: "Payment fathers",
      actions : 'Actions',
      addHouseImg : "house image",
      location : "coordinate",
      uploadd : "upload",
      clear: "clear",
    },
    parent_pages: {
      identification_number: "ID number",
      its_source: "ID source",
      its_history: "ID Date",
      appointment_of_guardian: "Appointment as guardian",
      deceased: "Deceased",
      firstname_ar: "Arabic First name",
      firstname_en: "English First name",
      the_father_ar: "Arabic Father name",
      the_father_en: "English Father name",
      Grandpa_ar: "Arabic Grandpa name",
      Grandpa_en: "English Grandpa name",
      family_ar: "Arabic Family name",
      family_en: "English Family name",
      of_the_affiliates: "Of the Affiliates",
      Nationality: "Nationality",
      Country: "Country",
      countryPlace: "Residence",
      City: "City",
      employer_ar: "Work place in Arabic",
      employer_en: "Work place in English",
      Job_title_ar: "Job title in Arabic",
      Job_title_en: "Job title in English",
      Title_ar: "Arabic Title",
      Title_en: "English Title",
      Postal_code: "Postal code",
      Home_phone: "Home phone",
      work_phone: "Work phone",
      cell_phone: "Cell phone",
      stop_dealing: "Stop dealing",
      Black_list: "Blacklist",
      general_note: "General Note",
      relative_relation: "Kinship",
      contract_number: "Contract numbe",
      date_of_registration: "Date of registration",
      Student_status: "Student status from registration",
      type: "Type",
      section: "Section",
      Enrollment_class: "Enrollment class",
      class: "Class",
      Semester: "Semester",
      school_situation: "School situation",
      Specialization: "Specialization",
      Student_condition: "Student' condition",
      contracts: "Contracts",
      Tuition_expenses: "Tuition expenses",
      Subscribe: "Subscribe to the bus",
      coordinates: "coordinates",
      genter: "Gender",
      AddNewChild: "Add a new son",
      editExel: "Upload excel file",
      successCopy: "Copied successfully",
      identityNum: "ID number",
      firstName: "First name",
      lastName: "Last name",
      fatherName: "Father name",
      userName: "Username",
      password: "Password",
      phone: "Mobile number",
      email: "E-mail",
      birthdate: "Date of birth",
      birthCountry: "Place of birth",
      nationality: "Nationality",
      exelFile: "Excel file",
      back: "Back",
      reset: "Reset",
      parentFile: "Parent",
      fingerPrint: "Fingerprint"
    },
    contracts: {
      addContract: "Add contract",
      editContract: "Edit contract",
      showContract: "Display contract ",
      last_activity: "Last activity",
      new_contract: "Add a contract form",
      view_contract: "Contract Forms",
      contracts: "Contracts",
      bills_enteries: "Invoices entries",
      bills_update_enteries: "Update invoices entries",
      bills_update_enteries_confirm: "Are you sure to update your invoices entries?",
      accept: "Admission",
      company_name: "Company name",
      standardized: "Standardized display form",
      contract_party: "Contract party",
      classroom_title: "Tuition fees approved for schools by the Ministry of Education",
      show: "show",
      hidden: "Hide",
      contract_date: "This contract is entered into on",
      day: "Corresponds",
      name_contract: "Contract title",
      desc_contract: "Contract description",
      preamble: "Introduction",
      sort: "Order",
      item: "Clause",
      new_item: "Add a new clause",
      tax_number: "Tax Number",
      second_party: "Second Party",
      its_meanings: "Preview",
      paragraph: "Generate contract",
      both: "between them both",
      represented: "This contract is represented by Mr",
      adjective: "Adjective",
      departement: "Section",
      shortcuts: "Abbreviations",
      bills: "Invoices",
      billsName: "invoice number",
      headerTitle: "Standardized display form",
      dynamicTitle: "Clause",
      conclusion: "Conclusion",
      signature_two: "The second signature",
      signature_one: "The first signature",
      contractNumber: "Contract number",
      studentName: "Student name",
      fatherName: "Father name",
      semaster: "Grade",
      class: "Class",
      contractStatus: "Contract status",
      service: "Service",
      contractPrice: "Contract amount",
      item_of_number: "Number of clause",
      tax_invoce: "Tax invoice",
      'simple-tax_invoce': "Simple tax invoice",
      bill_to: "Invoice to",
      bill_from: "Invoice From",
      name: "Name",
      address: "Address",
      vat_no: "tax number",
      cr_no: "Commercial Registration No",
      invoice_no: "Invoice number",
      invoice_date: "Invoice date",
      invoice_create: "Created by",
      service_pro: "Product or service details",
      service_pro_en: "The nature of the goods or services",
      unit_price: "Unit price",
      quantity: "Quantity",
      amount: "Discount amount",
      taxable_amount: " Taxable amount",
      tax_rate: "Tax rate",
      tax_sar: "Tax SAR",
      total: "Total",
      sub_total: "Subtotal",
      discount: "Discount",
      taotal_vat: "tax amount",
      addContractDate: "Contract date",
      studentFullName: "Student's full name",
      contractName: "Contract name",
      cateogriesoffees: "Fee categories",
      amountPaid: "Paid amount",
      amountRest: "Remaining amount",
      totalContracts: "Total contracts",
      contarctDate: "Contract date",
      student: "First party student",
      company: "Second party company",
      chooseContarct: "Select contract",
      invoice_number: "Invoice number",
      invoice_status: "Invoice status",
      invoice_amount: "Invoice amount",
      payment_status: "Payment status",
      payment_status_amont: "Paid amount",
      payment_status_rest: "Remaining amount",
      invoices: "Invoices",
      add_invoice: "Add invoice",
      update_all_invoices: "Update invoices",
      update_all_invoices_confirm: "Are you sure to update the invoices?",
      bill_invoices: "Contract invoices",
      download_invoice: "Download invoice",
      download_contract: "Download contract",
      due_date: "Due date",
      class_room: "Classroom",
      specialOffer: "Special discount",
      generalOffer: "General discount",
      paid: "Paid",
      Unpaid: "Unpaid",
      pendingPaid: "Canceled",
      ParialPaid: "Partially paid",
      noGeneralDiscount: "There are no general discounts",
      noSpecialDiscount: "There are no special discounts",
      companyName: "Company",
      ContractstudentName: "Student",
      guardian: "Parent",
    },
    prossing: {
      userSheets: "User sheets",
      sheetName: "Sheet name",
      sheetType: "Sheet type",
      sheetData: "Sheet data",
      declaration: "Declaration",
      login_check: "Login check",
      other_services: "Other services"
    },
    AUTH: {
      GENERAL: {
        LOGIN_BY: "Login By",
        FACEBOOK: "Facebook",
        GOOGLE: "Google",
        logo: "ViewClass",
        welcomtointelegentLearning: "Welcome to smart learning",
        ourVesion: "Our Vision",
        ourVesiondetail: "To provide a role kinship in e-learning that is characterized by simplicity, interaction and integration that attracts students to education",
        sure: "Are you sure?",
      },
      LOGIN: {
        BUTTON: "LOGIN",
        USER: "USER",
        ADMIN: "ADMIN",
      },
      TWA: {
        HEADER: "Secure your account",
        SUBTITLE: "With 2-Step Verification",
        "STEP-ONE": "Download the authenticator google app from the link",
        "STEP-ONE-TITLE": "Step one :",
        "STEP-TWO-TITLE": "Step two :",
        "STEP-THREE-TITLE": "Step three :",
        "CLICK-HERE": "(click here)",
        "STEP-TWO": "Open the app",
        "STEP-THREE": "Captch the following code through the Google Authenticator app",
        SKIP: "Skip",
        enterCode: "Please enter verification code",
      },
      INPUT: {
        PASSWORD: "Password",
        USERNAME: "Username",
      },
    },
    common: {
      confirmDelete: "Confirm the deletion",
      confirmCahngeStatus: "Confirm status change",
      deleteMessage: "Do you agree to delete this item?",
      deleteItemsMessage: "Do you agree to delete?",
      changeStatusMessage: "Do you agree to change the status?",
      yes: "Yes",
      no: "No",
      VERIFY: "Verify",
      declarationMessage: "Are you sure to cancel the link? Note: The link between your email and google drive will be cancelled",
      noSemesterFound: "There are no semesters",
    },
    setting: {
      profile: "Profile",
      users: "Users",
      stocks: "Opening balances",
      banks: "Bank settings",
      powers: "Permissions management",
      "fiscal-year": "Fiscal year",
      selectCountry: "Country",
      declaration: "Declaration",
      selectZone: "Region",
      selectDates: "Choose the format",
      selectLang: "Choose the language",
      selectRole: "Choose the job",
      motherCompanies: "Choose the institution",
      openingbalances: "Opening balances",
      Permissionmanagement: "Permissions management",
      chartofaccounts: "Accounts guide",
      AppointmentGuardians: "Parents' debt analysis",
      DesignationGuardians: "Appointment of parents' receivables",
      AppointmentGuardiansSet: "Parents' receivables",
      setCovenant: "Appointment of custody",
      serialnumbers: "Serial numbers",
      manualbonds: "Manual vouchers",
      paymentmethods: "Payment methods",
      typesofpremiums: "Types of installments",
      typesoffees: "Types of fees",
      cateogriesoffees: "Fee categories",
      studymajors: "Study majors",
      relativerelation: "Kinship",
      generaldiscounts: "General discounts",
      specialdiscounts: "Special discounts",
      casestudies: "Educational cases",
      paymentterminal: "Withdrawal machines ",
      Semester: "Semester control",
      save: "Save",
      saveLocathin: "Save location",
      saveAll: "Save all",
      cancel: "Cancel",
      basic_info: "Main information",
      show_account_card: "Show account card",
      add_sub_account: "Add sub account",
      ledger: "Ledger",
      ledgerRead: "Read immovable entries",
      previousBalance: "Previous balance",
      finalBalance: "Final balance",
      total: "Total",
      currency: "Currency",
      account_card: "Account card",
      sub_account: "Add sub account",
      level_two: "Second level",
      level_three: "Third level",
      settings: "Settings",
      journal_entry: "Entry vouchers",
      delete: "Delete",
      security: "Security",
      entereducationManagerInfo: "Please enter the educational system administrator data",
      usersPage: {
        active: "Active",
        disactive: "non active",
        username: "Username",
        email: "E-mail",
        actions: "Actions",
        role: "Role",
        userDetails: "User details",
        confirmationPassword: "Confirm Password",
        password: "Password",
        name: "Name",
        nameAr: "Arabic name",
        nameEn: "English name",
        chooseDate: "Choose a date",
        changePassword: "Change Password",
        errorPhone: "The number is not compatible with the country",
        passwordMinLength: "It must contain at least eight characters",
        passwordOneUpper: "It must contain at least one capital letter",
        passwordOneLower: "It must contain at least one lowercase letter",
        passwordoneNumber: "It must contain at least one number",
        passwordoneSymbol: "It must contain at least one symbol",
        mustBeTheSame: "The password must be the same",
        userImage: "Personal photo",
        currentPassword: "Current password",
        paymentMthodsPermissions: "Payment methods authority",
        paymentMthods: "Payment methods",
        accountGuidePermissions: "Custody authority",
        accountGuide: "Custody",
      },
      fiscalYearPage: {
        updateFiscalYear: "Edit fiscal year",
        addFiscalYear: "Add fiscal year",
        name: "Name",
        start_at: "Beginning of the year",
        start_m_at: "Gregorian",
        start_hijry_at: "Hijri",
        end_at: "End of year",
        details: "Account details",
        active: "Active",
        disactive: "Inactive",
        isDefault: "Set as default",
        Default: "Default",
        journalSequence: "Sequence",
        monthly: "Monthly",
        yearly: "Yearly",
      },
      currencies: {
        currencies: "Currencies",
        addCurrency: "Add currency",
        editCurrency: "Edit currency",
        sequence: "Sequence",
        currencyname: "Currency name",
        currencysymbol: "Currency symbol",
        currencysymbolAr: "Arabic currency symbol",
        currencysymbolEn: "English currency symbol",
        isDefault: "Set as default",
        Default: "Default",
        active: "Active",
        disactive: "Inactive",
        Exchangerate: "Exchange rate",
      },
      relativeRelation: {
        relative_relation: "Kinship",
        updateRelation: "Edit kinship ",
        addRelation: "Add kinship",
      },
      taxes: {
        add_tax: "Add taxes",
        update_tax: "Edit taxes",
        tax_name_ar: "Tax name (Arabic)",
        tax_name_en: "Tax name (English)",
        tax_percentage: "Tax percentage",
        linked_account: "Linked account",
        debit: "Creditor",
        credit: "Debtor",
        percentage: "Percentage",
        tax_free_nationalities: "Nationalities exempt from tax",
        free_nationalities: "Exempt nationalities",
        nationality: "Nationality",
        account_details: "Account details",
        tax_number: "Tax number",
        restrictions: "Daily entries",
        statement: "Statement",
        account: "Account",
      },
      typesOfFees: {
        account_guid: "Linked account",
        allow_global_discount: "Subject to general discount",
        allow_tax_exemption: "Excluded from tax exemption",
        cost_center: "Cost center",
        addFees: "Add fee type",
        updateFees: "Update fee type",
        start_at: "Starts from",
        to: "To"
      },
      organization: {
        Number: "Organization number",
        Logo: "Organization logo",
        Name: "Organization name",
        remove_logo: "Remove logo",
        play: "Operate",
        stop: "Stop",
        details: {
          details: "Organization Details",
          street: "Street",
          city: "City",
          mobile: "Mobile",
          fax: "Fax",
          email: "E-mail",
          website: "Website",
        },
        Tax_Number: "Tax number",
        special_number: "Special number",
        System_administrator: "System administrator",
        basis_of_the_report: "Basis of appreciation",
        Monetary_basis: "Cash basis",
        accrual_basis: "Accrual basis",
        Time_zone: "Time zone",
        Date_Format: "Date format",
        journal_method: "Relay entries based on",
        combinedentryamount: "Combined amount",
        linelevelrestriction: "Font level restriction"
      },
      permissions: {
        permissions: "Authorities",
        jobs : 'Jobs',
        "financial-operations": "Financial operations",
        settings: "Settings",
        "bank-reconcilations": "Bank reconciliations",
        "collection-from-parents": "Collection from parents",
        "contracts-management": "Contracts management",
        custodies: "inventories",
        "jornal-entry": "Entry vouchers",
        parents: "Parents",
        "receipt-vouchers": "Receipt vouchers",
        "payment-vouchers": "Payment vouchers",
        "general-ledger": "General ledger",
        areas: "Areas",
        branches: "Branches",
        "case-studies": "Case studies",
        "cateogries-of-fees": "fee categories",
        "chart-of-accounts": "Chart of accounts",
        "cost-center": "Cost center",
        "fiscal-year": "Fiscal year",
        "general-discounts": "General discounts",
        "manual-bonds": "Manual entries",
        "opening-balances": "Opening balances",
        "payment-methods": "Payment methods",
        "payment-terminal-(pos)": "Payment terminal - (Points of Sale)",
        profile: "Personal profile ",
        "relative-relation": "Kinship",
        "serial-numbers": "Serial numbers",
        "special-discounts": "Special discounts",
        "study-majors": "Study majors",
        taxes: "Taxes",
        users: "Users",
        "validities-management": "Permissions management",
        financial_operations : "financial operations",
        collections : "collections",
        location : "location",
        attachments_add : "attachments add",
        attachments_update : "attachments update",
        attachments_index : "attachments index",
        attachments_delete : "attachments delete",
        add: "Add",
        edit: "Edit",
        delete: "Delete",
        view: "View",
      },
      studentsYears: {
        addStudeyYear: "Add an academic year",
        address: "Title",
        addressEnglish: "English title",
        semester: "Semester",
        semester1: "First semester",
        semester2: "Second semester",
        semester3: "Third semester",
        startDateM: "Gregorian start date",
        endDateM: "Gregorian end date",
        startDateHijri: "Hijri start date",
        endDateHijri: "Hijri end date",
        transfareStudents: "Transfer students for a new academic year",
        school: "School",
        year: "Year",
        studeySemester: "Semester",
        studeyClass: "Classroom",
        class: "Class",
        addNewLine: "Add line",
        deliver: "Deliver",
        lessonsSchdule: "Lesson distribution schedule",
        lesson: "Subject",
        studyWeeks: "School weeks",
        addStudeyWeeks: "Add a school week",
        editStudyWeeks: "Update a school week",
        deleteStudyWeeks: "Delete a school week",
        studetWeeksLessons: "Lesson distribution schedule",
        studeyYears: "School years",
        startAt: "Starts from",
        endAt: "Ends in",
        title: "Title",
        season: "Semester",
        delete: "Delete",
        addRow: "Add row",
        addLesson: "Add lesson",
        Classseason: "Classroom",
      },
      attendanceSetting: {
        host: "Host",
        port: "Port",
        database: "Database",
        attend_from: "Attendance from",
        attend_to: "Attendance to",
        late: "Delay time",
        header: "Please enter fingerprint settings",
      }
    },
    students: {
      Withholding_grades: "Withholding grades",
      Withholding_view: "View grades",
      license: "license",
      cancel: "License cancellation",
      back: "Back",
      not_attached_parents: "Students who are not attached to parents",
      download: "Download execl",
      filter: "Filter",
      spe_row: "Customize columns",
      import: "Import to",
      copy: "Click on the line for copying the text",
      nor_system: "Importing users directly from Nour system",
      choose_excel_file: "Please choose the excel file that was exported from the Noor system",
      nor_excel_file: "Please select the Nour file in Excel format",
      send: "Send",
      method_import: "Explaining how to import",
      profile_picture: "Import profile picture",
      note: "Notes",
      upload_rar: "Upload file.rar",
      intro: "Introduction",
      acceptYear: "Acceptance year",
      grandFatherName: "Grandfather name",
      birthPlace: "Place of birth",
      passbortNumber: "Passport number",
      acadimicNum: "Academic number",
      previousSchool: "Previous school",
      fingerPrint: "Fingerprint",
      firstNameEnglish: "English first name",
      fatherNameEn: "English father's name",
      grandFatherEn: "English grandfather's name",
      lastNameEn: "English last name",
    },
    bills: {
      no: "Number",
      date: "Date",
      desc: "Statement",
      father_name: "Parent's name",
      maturity_value: "Maturity value ",
      tax_category: "Tax category",
      tax: "Tax",
      total: "Total",
      rest: "Net",
      tax_before: "Total accruals before tax",
      total_tax: "Total tax ",
      total_de: "Total due",
      fees: "Fees",
      change_bill_time: "Issuance of the invoice",
      contractISdeactive: "Inactive contract ",
    },
    custodies: {
      custodies: "Custodies",
      custodiesPay: "Payment of custodes",
      custodiesClose: "Liquidation of custodes",
      journalReceipt: "Liquidation entries",
      custodiesApprove: "Liquidation approval",
      custodiesRequest: "Custodes request",
      custodiesSatate: "Custodes status",
      custodiesSatateNew: "Unpaid",
      custodiesSatatePaid: "Paid off",
      custodyReqNumber: "Request number",
      custodyNumber: "Custody number",
      custPayFrom: "Pay from",
      custPayTo: "Pay to",
      custAmount: "Custody amount",
      custDescription: "Statement",
      custNew: "Not paid",
      custClose: "Liquidation",
      custPending: "Not liquidized",
      custNumber: "Request number",
      custCloseNumber: "Liquidation number",
      custDetail: "Details",
      custPay: "Paying",
      custApproveBtn: "Approve",
      custRefuseBtn: "Refuse",
      custApproved: "Approved",
      custRefused: "Refused",
      custNumberArr: "Custody number",
      filteringNNumber: "Liquidation number",
      filtringCompleate: "Liquidated",
      theRest: "Remaining",
      restAmount: "Remaining amount",
      requestAmount: " Requested amount",
      paidAmount: " Paid amount",
      qualifierAmount: "Liquidated amount",
      custodyClose: "Entries of custodes Liquidation",
      custodyCloseRefresh: "Update entries of custodes",
      custodyPaidRefresh: "Update entries of payment",
      custodyCloseBtn: "Liquidation entries",
      refuseReason: "Rejection reason ",
    },
    collectionForms : {
      fees_types: "fees types",
      'collections-recived' : "ٌRecived",
      'receipts-recived' : "Retriever",
      'collections-amount' : "المبلغ المستلم",
      'receipts-amount' : "المبلغ المسترد",
      amount : 'Amount',
      paid : 'Paid',
      reset : "Rest",
      parent : 'Father',
      actions : 'Actions'
    },
    trialBalance : {
      trialbalance : "Balance Review",
      balance : "Balance",
      trialDetails : "Detailed",
      devices :'أجهزه البصمه',
      deviceName : "اسم الجهاز",
      connect : 'الاتصال',
      serialNumber : 'الرقم المسلسل',
      Ip : 'Ip',
      Port : 'Port',
      modelName : 'اسم الموديل',
      deviceTime : 'توقيت الجهاز',
      deviceBuilding : 'المبني',
      deviceFloor : 'الدور',
      deviceUserCount : 'اجمالي المستخدمين',
      controles : 'التحكم',
      connectToWebsite : 'الربط بالموقع',
      connectDevice : 'Connect',
      addDevice : 'اضافه جهاز',
      editDevice : 'تعديل جهاز',
      removeDevice : "حذف جهاز",
      testConnectionStatus : "فحص حاله الاتصال",
      disConnect : "قصع الاتصال",
      timeSetting : "ضبط الوقت",
      builddings : "المباني",
      addBuilding : "Add Buildding",
      editBuilding : "تعديل مبني",
      showBuilding : "عرض مبني",
      buidingName : "اسم المبني",
      floorCount : "عدد الادوار",
      addFloorToBuildnig : "تخصيص الادوار",
      connectToFingerDevice : "Connect to device",
      group : "مجمع"
    },
    messages: {
      fillRequired: "Please enter all data",
      pleaseEnterValue: "Please enter the amount",
      pleaseEnterPercentage: "Please enter the percentage",
      donnotrebeateStudent: "A student cannot be repeated more than once",
      enterParentsGuardians: "Please enter parents' receivables first",
      Balancedoesnotmatch: "Balance does not match it has been modified, please match",
      cannotSelectMoreThanClassRoom: "The same classroom cannot be selected more than once",
      theValueIsNegativeValue: "The value entered must be a positive number",
      taxesFieldRequired : 'Tax is required',
      saveSuccessfully : "Save successfully",
      sumInstallmentsNotEqualDistributable : 'The sum of the installments does not equal the distributable',
      pleaseChooseDevice : "j"
    },
    AG_GRID_LOCALE_EN : {
      // Set Filter
      selectAll: '(Select All)',
      selectAllSearchResults: '(Select All Search Results)',
      searchOoo: 'Search...',
      blanks: '(Blanks)',
      noMatches: 'No matches',
  
      // Number Filter & Text Filter
      filterOoo: 'Filter...',
      equals: 'Equals',
      notEqual: 'Not equal',
      blank: 'Blank',
      notBlank: 'Not blank',
      empty: 'Choose One',
  
      // Number Filter
      lessThan: 'Less than',
      greaterThan: 'Greater than',
      lessThanOrEqual: 'Less than or equal',
      greaterThanOrEqual: 'Greater than or equal',
      inRange: 'In range',
      inRangeStart: 'from',
      inRangeEnd: 'to',
  
      // Text Filter
      contains: 'Contains',
      notContains: 'Not contains',
      startsWith: 'Starts with',
      endsWith: 'Ends with',
  
      // Date Filter
      dateFormatOoo: 'yyyy-mm-dd',
  
      // Filter Conditions
      andCondition: 'AND',
      orCondition: 'OR',
  
      // Filter Buttons
      applyFilter: 'Apply',
      resetFilter: 'Reset',
      clearFilter: 'Clear',
      cancelFilter: 'Cancel',
  
      // Filter Titles
      textFilter: 'Text Filter',
      numberFilter: 'Number Filter',
      dateFilter: 'Date Filter',
      setFilter: 'Set Filter',
  
      // Side Bar
      columns: 'Columns',
      filters: 'Filters',
  
      // columns tool panel
      pivotMode: 'Pivot Mode',
      groups: 'Row Groups',
      rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
      values: 'Values',
      valueColumnsEmptyMessage: 'Drag here to aggregate',
      pivots: 'Column Labels',
      pivotColumnsEmptyMessage: 'Drag here to set column labels',
  
      // Header of the Default Group Column
      group: 'Group',
  
      // Row Drag
      rowDragRows:'rows',
  
      // Other
      loadingOoo: 'Loading...',
      noRowsToShow: 'No Rows To Show',
      enabled: 'Enabled',
  
      // Menu
      pinColumn: 'Pin Column',
      pinLeft: 'Pin Left',
      pinRight: 'Pin Right',
      noPin: 'No Pin',
      valueAggregation: 'Value Aggregation',
      autosizeThiscolumn: 'Autosize This Column',
      autosizeAllColumns: 'Autosize All Columns',
      groupBy: 'Group by',
      ungroupBy: 'Un-Group by',
      addToValues: 'Add ${variable} to values',
      removeFromValues: 'Remove ${variable} from values',
      addToLabels: 'Add ${variable} to labels',
      removeFromLabels: 'Remove ${variable} from labels',
      resetColumns: 'Reset Columns',
      expandAll: 'Expand All',
      collapseAll: 'Close All',
      copy: 'Copy',
      ctrlC: 'Ctrl+C',
      copyWithHeaders: 'Copy With Headers',
      copyWithHeaderGroups: 'Copy With Header Groups',
      paste: 'Paste',
      ctrlV: 'Ctrl+V',
      export: 'Export',
      csvExport: 'CSV Export',
      excelExport: 'Excel Export',
  
      // Enterprise Menu Aggregation and Status Bar
      sum: 'Sum',
      min: 'Min',
      max: 'Max',
      none: 'None',
      count: 'Count',
      avg: 'Average',
      filteredRows: 'Filtered',
      selectedRows: 'Selected',
      totalRows: 'Total Rows',
      totalAndFilteredRows: 'Rows',
      more: 'More',
      to: 'to',
      of: 'of',
      page: 'Page',
      nextPage: 'Next Page',
      lastPage: 'Last Page',
      firstPage: 'First Page',
      previousPage: 'Previous Page',
  
      // Pivoting
      pivotColumnGroupTotals: 'Total',
  
      // Enterprise Menu (Charts)
      pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
      pivotChart: 'Pivot Chart',
      chartRange: 'Chart Range',
  
      columnChart: 'Column',
      groupedColumn: 'Grouped',
      stackedColumn: 'Stacked',
      normalizedColumn: '100% Stacked',
  
      barChart: 'Bar',
      groupedBar: 'Grouped',
      stackedBar: 'Stacked',
      normalizedBar: '100% Stacked',
  
      pieChart: 'Pie',
      pie: 'Pie',
      doughnut: 'Doughnut',
  
      line: 'Line',
  
      xyChart: 'X Y (Scatter)',
      scatter: 'Scatter',
      bubble: 'Bubble',
  
      areaChart: 'Area',
      area: 'Area',
      stackedArea: 'Stacked',
      normalizedArea: '100% Stacked',
  
      histogramChart: 'Histogram',
  
      combinationChart: 'Combination',
      columnLineCombo: 'Column & Line',
      AreaColumnCombo: 'Area & Column',
  
      // Charts
      pivotChartTitle: 'Pivot Chart',
      rangeChartTitle: 'Range Chart',
      settings: 'Settings',
      data: 'Data',
      format: 'Format',
      categories: 'Categories',
      defaultCategory: '(None)',
      series: 'Series',
      xyValues: 'X Y Values',
      paired: 'Paired Mode',
      axis: 'Axis',
      navigator: 'Navigator',
      color: 'Color',
      thickness: 'Thickness',
      xType: 'X Type',
      automatic: 'Automatic',
      category: 'Category',
      number: 'Number',
      time: 'Time',
      xRotation: 'X Rotation',
      yRotation: 'Y Rotation',
      ticks: 'Ticks',
      width: 'Width',
      height: 'Height',
      length: 'Length',
      padding: 'Padding',
      spacing: 'Spacing',
      chart: 'Chart',
      title: 'Title',
      titlePlaceholder: 'Chart title - double click to edit',
      background: 'Background',
      font: 'Font',
      top: 'Top',
      right: 'Right',
      bottom: 'Bottom',
      left: 'Left',
      labels: 'Labels',
      size: 'Size',
      minSize: 'Minimum Size',
      maxSize: 'Maximum Size',
      legend: 'Legend',
      position: 'Position',
      markerSize: 'Marker Size',
      markerStroke: 'Marker Stroke',
      markerPadding: 'Marker Padding',
      itemSpacing: 'Item Spacing',
      itemPaddingX: 'Item Padding X',
      itemPaddingY: 'Item Padding Y',
      layoutHorizontalSpacing: 'Horizontal Spacing',
      layoutVerticalSpacing: 'Vertical Spacing',
      strokeWidth: 'Stroke Width',
      offset: 'Offset',
      offsets: 'Offsets',
      tooltips: 'Tooltips',
      callout: 'Callout',
      markers: 'Markers',
      shadow: 'Shadow',
      blur: 'Blur',
      xOffset: 'X Offset',
      yOffset: 'Y Offset',
      lineWidth: 'Line Width',
      normal: 'Normal',
      bold: 'Bold',
      italic: 'Italic',
      boldItalic: 'Bold Italic',
      predefined: 'Predefined',
      fillOpacity: 'Fill Opacity',
      strokeOpacity: 'Line Opacity',
      histogramBinCount: 'Bin count',
      columnGroup: 'Column',
      barGroup: 'Bar',
      pieGroup: 'Pie',
      lineGroup: 'Line',
      scatterGroup: 'X Y (Scatter)',
      areaGroup: 'Area',
      histogramGroup: 'Histogram',
      combinationGroup: 'Combination',
      groupedColumnTooltip: 'Grouped',
      stackedColumnTooltip: 'Stacked',
      normalizedColumnTooltip: '100% Stacked',
      groupedBarTooltip: 'Grouped',
      stackedBarTooltip: 'Stacked',
      normalizedBarTooltip: '100% Stacked',
      pieTooltip: 'Pie',
      doughnutTooltip: 'Doughnut',
      lineTooltip: 'Line',
      groupedAreaTooltip: 'Area',
      stackedAreaTooltip: 'Stacked',
      normalizedAreaTooltip: '100% Stacked',
      scatterTooltip: 'Scatter',
      bubbleTooltip: 'Bubble',
      histogramTooltip: 'Histogram',
      columnLineComboTooltip: 'Column & Line',
      areaColumnComboTooltip: 'Area & Column',
      customComboTooltip: 'Custom Combination',
      noDataToChart: 'No data available to be charted.',
      pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
      chartSettingsToolbarTooltip: 'Menu',
      chartLinkToolbarTooltip: 'Linked to Grid',
      chartUnlinkToolbarTooltip: 'Unlinked from Grid',
      chartDownloadToolbarTooltip: 'Download Chart',
      seriesChartType: 'Series Chart Type',
      seriesType: 'Series Type',
      secondaryAxis: 'Secondary Axis',
  
      // ARIA
      ariaHidden: 'hidden',
      ariaVisible: 'visible',
      ariaChecked: 'checked',
      ariaUnchecked: 'unchecked',
      ariaIndeterminate:'indeterminate',
      ariaDefaultListName: 'List',
      ariaColumnSelectAll: 'Toggle Select All Columns',
      ariaInputEditor: 'Input Editor',
      ariaDateFilterInput: 'Date Filter Input',
      ariaFilterList: 'Filter List',
      ariaFilterInput: 'Filter Input',
      ariaFilterColumnsInput: 'Filter Columns Input',
      ariaFilterValue: 'Filter Value',
      ariaFilterFromValue: 'Filter from value',
      ariaFilterToValue: 'Filter to value',
      ariaFilteringOperator: 'Filtering Operator',
      ariaColumn: 'Column',
      ariaColumnList: 'Column List',
      ariaColumnGroup: 'Column Group',
      ariaRowSelect: 'Press SPACE to select this row',
      ariaRowDeselect: 'Press SPACE to deselect this row',
      ariaRowToggleSelection: 'Press Space to toggle row selection',
      ariaRowSelectAll: 'Press Space to toggle all rows selection',
      ariaToggleVisibility: 'Press SPACE to toggle visibility',
      ariaSearch: 'Search',
      ariaSearchFilterValues: 'Search filter values',
  
      ariaRowGroupDropZonePanelLabel: 'Row Groups',
      ariaValuesDropZonePanelLabel: 'Values',
      ariaPivotDropZonePanelLabel: 'Column Labels',
      ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
      ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
  
      // ARIA Labels for Dialogs
      ariaLabelColumnMenu: 'Column Menu',
      ariaLabelCellEditor: 'Cell Editor',
      ariaLabelDialog: 'Dialog',
      ariaLabelSelectField: 'Select Field',
      ariaLabelTooltip: 'Tooltip',
      ariaLabelContextMenu: 'Context Menu',
      ariaLabelSubMenu: 'SubMenu',
      ariaLabelAggregationFunction: 'Aggregation Function',
  
      // Number Format (Status Bar, Pagination Panel)
      thousandSeparator: ',',
      decimalSeparator: '.'
  
  }
  },
};