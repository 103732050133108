import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isSuper = localStorage.getItem('role') ? localStorage.getItem('role') === 'super' : false;
    const isUser = localStorage.getItem('role') ? localStorage.getItem('role') === 'system'||localStorage.getItem('role') === 'user' : false;
    const token = this.authService.getToken();
    if (token) {
      // logged in so return true
      if(isSuper){
        return true;
      } 
      if(isUser){
        this.router.navigate(["/main-page"]);
      }
    }
    this.router.navigate(["/auth/login"]);
    return false;
  }

}
