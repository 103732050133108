import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { NavChange } from '../model/global';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  navData: BehaviorSubject<any> = new BehaviorSubject(null);
  navData$ = this.navData.asObservable();

  fiscalYears: BehaviorSubject<any> = new BehaviorSubject(null);
  fiscalYears$ = this.fiscalYears.asObservable();

  selectedCompanyId: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedCompanyId$ = this.selectedCompanyId.asObservable();

  selectedCompany: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedCompany$ = this.selectedCompany.asObservable();

  selectedCompanyNumber: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedCompanyNumber$ = this.selectedCompanyNumber.asObservable();

  selectedRoleId: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedRoleId$ = this.selectedRoleId.asObservable();

  selectedConstCenterId: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedConstCenterId$ = this.selectedConstCenterId.asObservable();

  selectedFiscalYearId: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedFiscalYearId$ = this.selectedFiscalYearId.asObservable();
  
  IsClosedFiscalYear: BehaviorSubject<any> = new BehaviorSubject(null);
  IsClosedFiscalYear$ = this.selectedFiscalYearId.asObservable();

  costCenterChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  costCenterChanged$ = this.selectedFiscalYearId.asObservable();

  userImagePath: BehaviorSubject<any> = new BehaviorSubject(null);
  userImagePath$ = this.userImagePath.asObservable();
  // Top nav change
  navChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  navChanged$: Observable<NavChange> = this.navChanged.asObservable();

  isMain: BehaviorSubject<any> = new BehaviorSubject(null);
  isMain$: Observable<NavChange> = this.isMain.asObservable();

  // General setting menu
  generalLedgerMenu: BehaviorSubject<any> = new BehaviorSubject([]);
  generalLedgerMenu$ = this.generalLedgerMenu.asObservable();
  // Get finger print menu
  FingerPrintMenu: BehaviorSubject<any> = new BehaviorSubject([]);
  FingerPrintMenu$ = this.FingerPrintMenu.asObservable();
  // Get contact settings menu
  ContactSettings: BehaviorSubject<any> = new BehaviorSubject([]);
  ContactSettings$ = this.ContactSettings.asObservable();
  // Get mail menu
  Mail: BehaviorSubject<any> = new BehaviorSubject([]);
  Mail$ = this.Mail.asObservable();
  // getModule
  Module: BehaviorSubject<any> = new BehaviorSubject([]);
  Module$ = this.Module.asObservable();


  serverErrors: BehaviorSubject<any> = new BehaviorSubject({});
  serverErrors$ = this.serverErrors.asObservable();

  scrollToTop: BehaviorSubject<any> = new BehaviorSubject(false);
  scrollToTop$ = this.scrollToTop.asObservable();

  toggleHeaderMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  toggleMenu = this.toggleHeaderMenu.asObservable();

  userPermissions: BehaviorSubject<any[]> = new BehaviorSubject([]);
  userPermissions$ = this.userPermissions.asObservable();

 
  constructor(
    private http: HttpClient,
    private permissionsService: NgxPermissionsService,
  ) {
  }

  /**
   * To convert arabic date to english date
   * @param date (arabic date)
   * @returns (English date)
   */
  convertToEnglishDate(date) {
    return date.replace(/[\u0660-\u0669]/g,
      d => d.charCodeAt() - 1632);
  }

  removeNullValues(obj) {
    Object.keys(obj).forEach(key => {
      if (!obj[key]) {
        delete obj[key];
      }
    });
    return obj;
  }
//   {
//     "html":"<!DOCTYPE html><html lang=\"en\"><head><meta charset=\"UTF-8\"> <title>هلااااااااااا</title></head><body><h1> اهلا يا عم  </h1></body></html>",
//     "file_name":"file",
//     "orientation":"Landscape or Portrait"
// }
 downloadPDF(data){
  let headers = new HttpHeaders();
  headers = headers.set('Accept', 'application/pdf');
  return this.http
  .patch(`${environment.accountant_apiUrl}users/global/download-pdf`,data,      { headers: headers, responseType: 'blob' }
  );
 }
  // get user country form ip address
  getIPAddress() {
    return this.http.get("https://iplist.cc/api");
  }

  initializeUserPermission(){
    if(localStorage.getItem('userPermission')){
      const perm = JSON.parse(`${localStorage.getItem('userPermission')}`);
      this.permissionsService.loadPermissions(perm);
    }
  }
}
